import { Spin } from "antd";
import React, { memo, useState } from "react";
import { Button } from "react-bootstrap";
import { FaPaperclip } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { uploadFiles } from "../../redux/admin/action";
import NavigoUpload from "../NavigoUpload";

function Index({ title, name, onUpload, value, onRemove }) {
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);

    onUpload?.({ [name]: data });
  };

  const dispatch = useDispatch();
  const onFileChangeHandle = (e) => {
    const { files } = e.target;

    setLoader(true);
    dispatch(uploadFiles(files, stopLoader));
  };

  return (
    <Spin spinning={loader}>
      <div className="d-flex align-items-center">
        <p
          style={ {
            width: 250,
          } }
        >
          {title}
        </p>
        {value?.length ? (
          <Button
            style={ {
              display: "flex",
              alignItems: "center",
            } }
            className="flex items-center"
            onClick={() => onRemove?.(name)}
            variant="outline-danger py-0"
          >
            <FaPaperclip />
            <span className="mx-1">
Remove file&apos;s
            </span>
          </Button>
        ) : (
          <NavigoUpload
            multiple
            value={value}
            name={name}
            onChange={onFileChangeHandle}
            onRemove={onRemove}
          />
        )}
      </div>
    </Spin>
  );
}
export default memo(Index);
