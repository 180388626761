import { CheckSquareOutlined,CloseSquareOutlined } from "@ant-design/icons";
import { Edit } from "@mui/icons-material";
import { Button, Input, Table, Tag } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import AsCancel from "../../modals/CancelOrder";
import AsComplete from "../../modals/CompleteOrder";
import OrderItem from "../../modals/OrderItem";
import { getRides } from "../../redux/admin/action";

const { Search } = Input;

function Index({ editHandler, setCount }) {
  const dispatch = useDispatch();
  const [
    modalShow, setModalShow
  ] = useState(false);
  const [items] = useState(null);
  const [
    modalCompShow, setmodalCompShow
  ] = useState(false);
  const [
    modalCancShow, setmodalCancShow
  ] = useState(false);
  const [
    order, setOrder
  ] = useState(null);
  const [
    searchedText, setSearchedText
  ] = useState("");
  const { rides } = useSelector((state) => state.admin);
  const [
    finalRides, setFinalRides
  ] = useState();

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 120,
      render: (status) => {
        if (status.toLowerCase() === "completed") {
          return (
            <Tag
              color="green"
              className="rounded-pill capitalize"
              style={ { textTransform: "capitalize !important" } }
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status.toLowerCase() === "cancelled") {
          return (
            <Tag color="red"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status.toLowerCase() === "pending") {
          return (
            <Tag color="yellow"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status.toLowerCase() === "driver assigned") {
          return (
            <Tag color="gold"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else {
          return <p>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </p>;
        }
      },
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    {
      title: "Rider",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 220,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.origin?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.destination?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.orderNumber)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id.toString().split("-")[0])
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (name) => {
        return `${name}`;
      },
    },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (destination) => destination?.address,
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        // ukCurrancy.format(e? e : 0)
        <>
          {" "}
          &pound;
          {" "}
          {record.estimatedCost || record.cost
            ? record.estimatedCost
              ? record.estimatedCost
              : record.cost
            : 0}
        </>
      ),
    },
    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <div className="d-flex flex-col align-items-center justify-content-center">
            <Edit onClick={() => editHandler(record)} />
            {(record.status === "pending" ||
              record.status === "driver assigned") && (
              <>
                <Button
                  type="text"
                  className="m-1 fw-bold"
                  title="mark as completed"
                  onClick={() => {
                    setOrder(record);

                    setmodalCompShow(true);
                  }}
                >
                  <CheckSquareOutlined
                    style={ { color: "green", fontSize: 20, margin: "2px" } }
                  />
                </Button>
                <Button
                  className="m-1 fw-bold"
                  type="text"
                  title="mark as cancelled"
                  onClick={() => {
                    setOrder(record);

                    setmodalCancShow(true);
                  }}
                >
                  <CloseSquareOutlined
                    style={ { color: "red", fontSize: 20, margin: "2px" } }
                  />
                </Button>
                {" "}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const [
    loader, setLoader
  ] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(getRides(stopLoader));
  }, [dispatch]);

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    if (rides) {
      const filteredRides = rides?.filter((ride) => {
        return ride.date === moment().format("DD MMM YYYY");
      });
      const finilizeRides = filteredRides?.filter((ride) => {
        return ride.status === "pending" || ride.status === "driver assigned";
      });
      setCount(finilizeRides?.length);

      setFinalRides(finilizeRides);
    }
  }, [
    rides, setCount
  ]);

  return (
    <>
      <Container fluid
        className=" w-100 pb-5">
        <Row className="gap-5">
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3 gap-3">
            <Search
              placeholder="Search rides..."
              allowClear
              size="large"
              onSearch={(value) => setSearchedText(value)}
              onChange={(e) => setSearchedText(e.target.value)}
              style={ {
                width: 300,
                marginBottom: "10px",
                float: "right",
              } }
              className="searchBar"
            />
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={finalRides}
              pagination={false}
              loading={loader}
              scroll={ { x: 1500, y: 500 } }
            />
          </Col>
        </Row>
      </Container>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {modalCompShow && (
        <AsComplete
          order={order}
          show={modalCompShow}
          onHide={() => setmodalCompShow(false)}
        />
      )}
      {modalCancShow && (
        <AsCancel
          order={order}
          show={modalCancShow}
          onHide={() => setmodalCancShow(false)}
        />
      )}
    </>
  );
}

export default memo(Index);
