/* eslint-disable no-case-declarations */
import * as constants from "./constants";

let initState = {
  liveOrders: [],
  unBoundRiders: [],
  orderHistory: [],
  admins: [],
  riders: [],
  restaurants: [],
  users: [],
  roles: [],
  vouchers: [],
  profileRestaurant: null,
  profileRider: null,
  profileUser: null,
  profileAdmin: null,
  payments: [],
  schedules: [],
  leaves: [],
  shifts: [],
  categories: [],
  vehicleTypes: [],
  vehicles: [],
  pricings: [],
  cities: [],
  bases: [],
  tarrif: [],
  rides: [],
  holidaySurcharge: [],
  careers: [],
  city: null,
  blogs: [],
};

function orderReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_ALL_VOUCHERS:
      return {
        ...state,
        vouchers: payload,
      };
    case constants.ADD_VOUCHER:
      const newVoucher = state.vouchers.concat(payload);
      return {
        ...state,
        vouchers: newVoucher,
      };
    case constants.GET_DRIVER_VEHICLES:
      return {
        ...state,
        vehicles: payload,
      };
    case constants.GET_CITY:
      return {
        ...state,
        city: { [payload.slug]: payload },
      };
    case constants.UPDATE_PROFILE_CITY:
      return {
        ...state,
        city: {
          [payload.slug]: {
            ...state.city?.[payload.slug],
            ...payload,
          },
        },
      };
    case constants.ADD_DRIVER_VEHICLE:
      const addedVehicle = state.vehicles.concat(payload);
      return {
        ...state,
        vehicles: addedVehicle,
      };

    case constants.UPDATE_DRIVER_VEHICLE:
      const updatedVehicle = state.vehicles.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,

            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        vehicles: updatedVehicle,
      };
    case constants.DELETE_DRIVER_VEHICLE:
      const deletedVehicle = state.vehicles.filter((e) => e.id !== payload.id);
      return {
        ...state,
        vehicles: deletedVehicle,
      };
    case constants.GET_SCHEDULES:
      return {
        ...state,
        schedules: payload,
      };
    case constants.GET_PRICINGS:
      return {
        ...state,
        pricings: payload,
      };
    case constants.GET_BASES:
      return {
        ...state,
        bases: payload,
      };
    case constants.ADD_BASE:
      const addedBase = state.bases.concat(payload);
      return {
        ...state,
        bases: addedBase,
      };
    case constants.UPDATE_BASE:
      const updatedBase = state.bases.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        bases: updatedBase,
      };
    case constants.REMOVE_BASE:
      const removeBase = state.bases.filter((e) => e.id !== payload.id);
      return {
        ...state,
        bases: removeBase,
      };
    case constants.ADD_CITY:
      const addedCity = state.cities.concat(payload);
      return {
        ...state,
        cities: addedCity,
      };
    case constants.UPDATE_CITY:
      const updatedCities = state.cities.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        cities: updatedCities,
      };
    case constants.REMOVE_CITY:
      const removedCities = state.cities.filter((e) => e.id !== payload.id);
      return {
        ...state,
        cities: removedCities,
      };
    case constants.GET_CITIES:
      return {
        ...state,
        cities: payload,
      };
    case constants.UPDATE_PRICINGS:
      const updatedPricing = state.pricings.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });

      return {
        ...state,
        pricings: updatedPricing,
      };

    case constants.DELETE_PRICINGS:
      const deletePricing = state.pricings.filter((e) => e.id !== payload.id);
      return {
        ...state,
        pricings: deletePricing,
      };
    case constants.ADD_PRICINGS:
      return {
        ...state,
        pricings: payload,
      };
    case constants.ADD_VEHICLE_TYPE:
      const vehicleTypes = state.vehicleTypes.concat(payload);
      return {
        ...state,
        vehicleTypes,
      };
    case constants.UPDATE_VEHICLE_TYPE:
      const updatedTypes = state.vehicleTypes.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        vehicleTypes: updatedTypes,
      };
    case constants.DELETE_VEHICLE_TYPE:
      const updated = state.vehicleTypes.filter((e) => e.id !== payload.id);
      return {
        ...state,
        vehicleTypes: updated,
      };
    case constants.GET_VEHICLE_TYPES:
      return {
        ...state,
        vehicleTypes: payload,
      };
    case constants.GET_SHIFTS:
      return {
        ...state,
        shifts: payload,
      };
    case constants.GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case constants.UPDATE_CATEGORY:
      const updateCategories = state.categories.map((category) => {
        if (category.id === payload.id) {
          return {
            ...category,
            ...payload,
          };
        }
        return category;
      });
      return {
        ...state,
        categories: updateCategories,
      };
    case constants.ADD_CATEGORY:
      const newCategories = state.categories.concat(payload);

      return {
        ...state,
        categories: newCategories,
      };
    case constants.GET_LEAVES:
      return {
        ...state,
        leaves: payload,
      };
    case constants.ADD_SCHEDULE:
      let newSchedule = [...state.schedules, payload];
      return {
        ...state,
        schedules: newSchedule,
      };
    case constants.ADD_SHIFT:
      let newShifts = [...state.shifts, payload];
      return {
        ...state,
        shifts: newShifts,
      };
    case constants.ADD_LEAVE:
      let newLeaves = [...state.leaves, payload];
      return {
        ...state,
        leaves: newLeaves,
      };
    case constants.UPDATE_SCHEDULE:
      let updatedSchedules = state.schedules.map((schedule) => {
        if (schedule.id === payload.id) {
          return {
            ...schedule,
            ...payload,
          };
        }
        return schedule;
      });

      return {
        ...state,
        schedules: updatedSchedules,
      };

    case constants.UPDATE_SHIFT:
      let updatedShift = state.shifts.map((shift) => {
        if (shift.id === payload.id) {
          return {
            ...shift,
            ...payload,
          };
        }
        return shift;
      });

      return {
        ...state,
        shifts: updatedShift,
      };
    case constants.UPDATE_LEAVE:
      let updatedLeaves = state.leaves.map((leave) => {
        if (leave.id === payload.id) {
          return {
            ...leave,
            ...payload,
          };
        }
        return leave;
      });

      return {
        ...state,
        leaves: updatedLeaves,
      };
    case constants.GET_PAYMENTS:
      return {
        ...state,
        payments: payload,
      };
    case constants.REMOVE_PAYMENT:
      const newPayments = state.payments.filter((pay) => pay.id !== payload.id);
      return {
        ...state,
        payments: newPayments,
      };
    case constants.REMOVE_SHIFT:
      const deletedShifts = state.shifts.filter((pay) => pay.id !== payload.id);
      return {
        ...state,
        shifts: deletedShifts,
      };
    case constants.DELETE_LEAVE:
      const deleteLeaves = state.leaves.filter(
        (leave) => leave.id !== payload.id
      );
      return {
        ...state,
        leaves: deleteLeaves,
      };
    case constants.UPDATE_PAYMENTS:
      const updatedpayments = state.payments.map((e) => {
        if (e.id === payload.id) {
          return payload;
        }
        return e;
      });

      return {
        ...state,
        payments: updatedpayments,
      };
    case constants.ADD_PAYMENT:
      const newpayments = state.payments.map((e) => e);
      newpayments.push(payload);
      return {
        ...state,
        payments: newpayments,
      };
    case constants.UPDATE_VOUCHERS:
      const updatedVoucher = state.vouchers.map((voucher) => {
        if (voucher.id === payload.id) {
          return {
            ...voucher,
            ...payload,
          };
        }
        return voucher;
      });
      return {
        ...state,
        vouchers: updatedVoucher,
      };
    case constants.GET_ADMIN_LIVE_ORDERS:
      return {
        ...state,
        liveOrders: payload,
      };
    case constants.REMOVE_VOUCHER:
      const newVouchers = state.vouchers.filter(
        (voucher) => voucher.id !== payload.id
      );
      return {
        ...state,
        vouchers: newVouchers,
      };

    case constants.UPDATE_PROFILE_RESTAURANT:
      return {
        ...state,
        profileRestaurant: {
          ...state.profileRestaurant,
          ...payload,
        },
      };
    case constants.UPDATE_PROFILE_RIDER:
      return {
        ...state,
        profileRider: {
          ...state.profileRider,
          ...payload,
        },
      };
    case constants.UPDATE_RIDE:
      const updatedRide = state.rides.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,

            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        rides: updatedRide,
      };
    case constants.COMPLETE_RIDE:
      const completeRide = state.rides.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,

            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        rides: completeRide,
      };
    case constants.CANCEL_RIDE:
      const cancelledRide = state.rides.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,

            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        rides: cancelledRide,
      };
    case constants.UPDATE_PROFILE_ADMIN:
      return {
        ...state,
        profileAdmin: {
          ...state.profileAdmin,
          ...payload,
        },
      };
    case constants.UPDATE_PROFILE_USER:
      return {
        ...state,
        profileUser: {
          ...state.profileUser,
          ...payload,
        },
      };
    case constants.REMOVE_ORDER:
      return {
        ...state,
        liveOrders: state.liveOrders.filter((e) => e?.id !== payload),
      };
    case constants.FETCH_PROFILE_RESTAURANT:
      return {
        ...state,
        profileRestaurant: payload,
      };
    case constants.FETCH_PROFILE_ADMIN:
      return {
        ...state,
        profileAdmin: payload,
      };
    case constants.FETCH_PROFILE_RIDER:
      return {
        ...state,
        profileRider: payload,
      };
    case constants.FETCH_PROFILE_USER:
      return {
        ...state,
        profileUser: payload,
      };
    case constants.GET_UNBOUND_RIDERS:
      return {
        ...state,
        unBoundRiders: payload,
      };

    case constants.REMOVE_RIDER:
      return {
        ...state,
        unBoundRiders: state.unBoundRiders.filter((e) => e.id !== payload),
      };
    case constants.GET_ORDERS_HISTORY:
      return {
        ...state,
        orderHistory: payload,
      };
    case constants.GET_ADMINS:
      return {
        ...state,
        admins: payload,
      };
    case constants.GET_ADMIN_ROLE:
      return {
        ...state,
        roles: payload,
      };
    case constants.ADD_ADMIN_ROLE:
      let newRoles = state.roles.map((e) => e);
      newRoles.push(payload);
      return {
        ...state,
        roles: newRoles,
      };
    case constants.REMOVE_ADMIN_ROLE:
      let removeRoles = state.roles.filter((e) => e.id !== payload.id);

      return {
        ...state,
        roles: removeRoles,
      };
    case constants.UPDATE_ADMIN_ROLE:
      let updatedRoles = state.roles.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });

      return {
        ...state,
        roles: updatedRoles,
      };
    case constants.GET_RIDERS:
      return {
        ...state,
        riders: payload,
      };

    case constants.GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case constants.GET_RESTAURANTS:
      return {
        ...state,
        restaurants: payload,
      };
    case constants.UPDATE_ADMIN:
      const array = state.admins.map((admin) => {
        if (admin?.id === payload?.id) {
          return {
            ...admin,
            ...payload,
          };
        }
        return admin;
      });

      return {
        ...state,
        admins: array,
      };

    case constants.UPDATE_RIDER:
      const riderArray = state.riders.map((rider) => {
        if (rider?.id === payload?.id) {
          return {
            ...rider,
            ...payload,
          };
        }
        return rider;
      });

      return {
        ...state,
        riders: riderArray,
      };
    case constants.UPDATE_USER:
      const userArray = state.users.map((user) => {
        if (user?.id === payload?.id) {
          return {
            ...user,
            ...payload,
          };
        }
        return user;
      });

      return {
        ...state,
        users: userArray,
      };

    case constants.UPDATE_RESTAURANT:
      const restArray = state.restaurants.map((rest) => {
        if (rest?.id === payload?.id) {
          return {
            ...rest,
            ...payload,
          };
        }
        return rest;
      });

      return {
        ...state,
        restaurants: restArray,
      };
    case constants.ADD_ADMIN:
      const newArray = state.admins.map((e) => e);
      newArray.push(payload);

      return {
        ...state,
        admins: newArray,
      };
    case constants.ADD_USER:
      const usersArray = state.users.map((e) => e);
      usersArray.push(payload);

      return {
        ...state,
        users: usersArray,
      };

    case constants.DELETE_ADMIN:
      const newAdmins = state.admins.filter((e) => e.id !== payload.id);

      return {
        ...state,
        admins: newAdmins,
      };
    case constants.DELETE_USER:
      const newUser = state.users.filter((e) => e.id !== payload.id);

      return {
        ...state,
        users: newUser,
      };
    case constants.DELETE_RIDER:
      const newRider = state.riders.filter((e) => e.id !== payload.id);

      return {
        ...state,
        riders: newRider,
      };
    case constants.DELETE_RESTAURANT:
      const newResturants = state.restaurants.filter(
        (e) => e.id !== payload.id
      );

      return {
        ...state,
        restaurants: newResturants,
      };
    case constants.ADD_TARRIF:
      const addedTarrif = state.tarrif.concat(payload);
      return {
        ...state,
        tarrif: addedTarrif,
      };
    case constants.GET_TARRIF:
      return {
        ...state,
        tarrif: payload,
      };
    case constants.UPDATE_TARRIF:
      const updatedTarrif = state.tarrif.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,

            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        tarrif: updatedTarrif,
      };
    case constants.DELETE_TARRIF:
      const deletedTarrif = state.tarrif.filter((e) => e.id !== payload.id);
      return {
        ...state,
        tarrif: deletedTarrif,
      };
    case constants.ADD_CAREER:
      const addedCareer = state.careers.concat(payload);
      return {
        ...state,
        careers: addedCareer,
      };
    case constants.GET_CAREERS:
      return {
        ...state,
        careers: payload,
      };
    case constants.UPDATE_CAREER:
      const updatedCareer = state.careers.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        careers: updatedCareer,
      };
    case constants.DELETE_CAREER:
      const deletedCareer = state.careers.filter((e) => e.id !== payload.id);
      return {
        ...state,
        careers: deletedCareer,
      };
    case constants.GET_HOLIDAY_SURCHARGE:
      return {
        ...state,
        holidaySurcharge: payload,
      };
    case constants.ADD_RIDE:
      const addedRide = state.rides.concat(payload);
      return {
        ...state,
        rides: addedRide,
      };
    case constants.GET_RIDE:
      return {
        ...state,
        rides: payload,
      };
    case constants.DELETE_RIDE:
      const deletedRide = state.rides.filter((e) => e.id !== payload.id);
      return {
        ...state,
        rides: deletedRide,
      };
    case constants.ADD_BLOG:
      const addedBlog = state.blogs.concat(payload);
      return {
        ...state,
        blogs: addedBlog,
      };
    case constants.GET_BLOGS:
      return {
        ...state,
        blogs: payload,
      };
    case constants.DELETE_BLOG:
      const deletedBlog = state.blogs.filter((e) => e.id !== payload.id);
      return {
        ...state,
        blogs: deletedBlog,
      };
    case constants.UPDATE_BLOG:
      const updatedBlog = state.blogs.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            ...payload,
          };
        }
        return e;
      });
      return {
        ...state,
        careers: updatedBlog,
      };

    default:
      return state;
  }
}

export default orderReducer;
