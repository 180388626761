import { Font,StyleSheet } from "@react-pdf/renderer";

import notoSerif from "../../../assets/fonts/Noto_Serif/NotoSerif-Bold.ttf";

Font.register({
  src: notoSerif,
  family: "Noto Serif",
  fontWeight: "700",
});

export default StyleSheet.create({
  page: {
    borderBottom: 1,
    borderBottomColor: "black",
    borderBottomWidth: 2,
    fontSize: 10,
  },
  pageTopHeadingView: {
    backgroundColor: "#f7b614",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  pageTopHeadingViewHeading: {
    fontFamily: "Noto Serif",
    letterSpacing: 3,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "900",
    fontSize: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  topDescripView: {
    padding: 15,

    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  restaurantHeading: {
    fontSize: 16,
    fontWeight: "700",
  },
  restaurantView: {
    flexGrow: 1,
  },
  siteView: {
    width: "80%",
    fontSize: 10,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  siteDesView: {
    flexDirection: "row",
  },
  siteLogo: {
    width: 130,
    height: 40,
    backgroundColor: "#282932",
  },
  statmentTotalView: {
    padding: 2,
    borderRadius: 10,
    alignItems: "center",

    flexDirection: "row",
  },
  statmentTotalViewlabel: {
    fontSize: 10,
    backgroundColor: "#f7b614",
    fontWeight: "bold",

    borderRadius: 8,
    padding: 5,
  },
  statmentTotalViewText: {
    fontSize: 10,
    padding: 5,
    margin: 5,
    textAlign: "center",
    borderWidth: 1,
    borderRadius: 8,
    width: 80,
  },
  paymentSummaryText: {
    fontSize: 10,
    backgroundColor: "#f7b614",
    fontWeight: "bold",

    borderRadius: 8,
    padding: 5,
    display: "flex",
    width: 95,
  },

  itemSummmaryText: {
    fontSize: 10,
    backgroundColor: "#f7b614",
    fontWeight: "bold",
    margin: 10,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    width: 80,
  },
  statmentBox: {
    padding: 10,
  },
  statmentTable: {
    margin: 10,
    fontSize: 6,
  },
  TableLableRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  TableLable: {
    textAlign: "center",
    flexGrow: 1,
    backgroundColor: "#f7b614",
    padding: 5,
    display: "flex",
    width: "12%",
    justifyContent: "center",
    alignItems: "center",
  },
  TableDataText: {
    textAlign: "center",
    flexGrow: 1,
    width: "12%",

    padding: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
