import { Alert, Snackbar } from "@mui/material";
import { Formik } from "formik";
import React, { memo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { updateUserPayment } from "../../../redux/admin/action";
import userPaymentValidations from "../../../validations/userPaymentValidations";

function Index() {
  const [
    updated, setUpdated
  ] = useState(false);

  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();
  const { profileUser } = useSelector((state) => state.admin);
  const updateProfile = (e) => {
    setLoader(true);

    dispatch(updateUserPayment(e, stoploader));
  };
  const stoploader = (error) => {
    setLoader(false);
    if (error) {
      swal(error);
      return;
    }
    setUpdated(true);
  };
  const closeSnackBar = () => {
    setUpdated(false);
  };
  return (
    <Col lg={6}
      className="p-4">
      <h5 style={ { fontWeight: "bold" } }>
Change Payment Details
      </h5>
      <Snackbar open={updated}
        autoHideDuration={2000}
        onClose={closeSnackBar}>
        <Alert
          variant="filled"
          onClose={closeSnackBar}
          severity="success"
          sx={ { width: "100%" } }
        >
          Profile Updated!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={ {
          accountHolderName: profileUser?.accountHolderName || "",
          bankName: profileUser?.bankName || "",
          accountNumber: profileUser?.accountNumber || "",
          sortCode: profileUser?.sortCode || "",
        } }
        validationSchema={userPaymentValidations}
        onSubmit={updateProfile}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className=" profile-from d-flex gap-2 justify-content-center flex-column align-items-center"
          >
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Account Holder Name"
                aria-label="account holder name"
                className="h-12"
                aria-describedby="basic-addon1"
                value={values.accountHolderName}
                onChange={handleChange}
                name="accountHolderName"
              />
              {touched.accountHolderName && errors.accountHolderName && (
                <span className="w-100 text-danger ">
                  {errors.accountHolderName}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Bank Name"
                aria-label="bank name"
                className="h-12"
                aria-describedby="basic-addon1"
                value={values.bankName}
                onChange={handleChange}
                name="bankName"
              />
              {touched.bankName && errors.bankName && (
                <span className="w-100 text-danger ">
                  {errors.bankName}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Account Number"
                aria-label="account number"
                className="h-12"
                aria-describedby="basic-addon1"
                value={values.accountNumber}
                onChange={handleChange}
                name="accountNumber"
              />
              {touched.accountNumber && errors.accountNumber && (
                <span className="w-100 text-danger ">
                  {errors.accountNumber}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Sort Code 00-00-00"
                aria-label="sort code"
                className="h-12"
                aria-describedby="basic-addon1"
                value={values.sortCode}
                onChange={handleChange}
                name="sortCode"
              />
            </FormGroup>
            <Button
              type="submit"
              className="rounded fw-bolder text-white rounded-2 mt-2"
              variant="warning"
              disabled={loader}
            >
              {loader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="mr-2"
                    variant="dark"
                    size="sm"
                  />
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default memo(Index);
