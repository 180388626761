import { Formik } from "formik";
import React, { memo, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { forgetPassword } from "../../redux/auth/action";
import forgetPasswordValidation from "../../validations/forgetPassword";

function Index() {
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const [
    error, setError
  ] = useState([]);
  const forgetPasswordHandle = ({ email }) => {
    setError([]);
    setLoader(true);
    dispatch(forgetPassword(email, stopLoader));
  };
  const stopLoader = (isSend, code) => {
    if (isSend) {
      setError("emailSend");
    } else {
      setError(code);
    }
    setLoader(false);
  };

  return (
    <Container
      fluid
      className=" h-screen bg-gray-400 flex justify-center items-center"
    >
      <Row className=" m-auto w-full">
        <Col lg={5}
          className="m-auto bg-white shadow-sm rounded-lg p-4 ">
          <h1 style={ { fontSize: "1.3em", fontWeight: "600" } }>
            Reset your password
          </h1>
          <p>
To reset your password, we need to send you an email
          </p>
          <Formik
            initialValues={ {
              email: "",
            } }
            validationSchema={forgetPasswordValidation}
            onSubmit={forgetPasswordHandle}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                {error.includes("emailSend") && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>
Dear user!
                    </strong>
                    {`Password reset e-mail sent to ‘${values.email}’`}
                    <button
                      type="button"
                      onClick={() => setError([])}
                      className="btn-close "
                      data-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                )}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>
Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.v && touched.email && errors.email && (
                    <span className="w-100 text-danger">
                      {errors.email}
                    </span>
                  )}
                  {error.includes("auth/user-not-found") && (
                    <span className="w-100 text-danger">
Email not found
                    </span>
                  )}
                </Form.Group>
                <Button
                  variant="warning"
                  className="w-100 fw-bolder text-white mt-3"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      className="mr-2"
                      variant="dark"
                      size="sm"
                    />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
          <Row className="p-2 flex items-end justify-end">
            <Col lg={1}>
              <Link
                to="/login"
                className=" hover:text-yellow-400   text-yellow-500 "
              >
                Login
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
