import "./index.css";

import { Layout, Menu } from "antd";
import React, { Fragment, memo } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Link, NavLink, useLocation } from "react-router-dom";

import LogoImg from "../../assets/images/logo.png";
import dashboardNavigation from "../../navigation/navigationData";
import { limitNavigation } from "../../utils/viewPages";

const { Sider } = Layout;
function Index({ collapsed }) {
  const location = useLocation();

  const finalLinks = limitNavigation(dashboardNavigation);

  const menuLinks = finalLinks.map((value) => {
    const Icon = value?.icon || Fragment;
    if (value?.Children) {
      return (
        <Menu.SubMenu key={value?.url}
          icon={<Icon />}
          title={value.text}>

          {value.Children.map((item) => {
            const Icon = item?.icon || Fragment;
            return (
              <Menu.Item icon={<Icon />}
                key={item?.url}>
                <NavLink to={item?.url}>
                  {item?.text}
                </NavLink>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={value?.url}
        icon={<Icon />}>
        <NavLink to={value?.url}>
          {value?.text}
        </NavLink>
      </Menu.Item>
    );
  });

  return (
    <Sider
      className="maindiv shadow  sidar-fixed z-10"
      trigger={null}
      collapsible
      theme="dark"
      collapsed={collapsed}
    >
      <div className="maindiv py-3 px-2 w-full d-flex align-items-center justify-content-center">
        <Link to="/">
          <img
            src={LogoImg}
            alt="logo"
            style={{ width: "80%", height: "60%" }}
          />
        </Link>
      </div>
      <PerfectScrollBar
        className="pb-32"
        options={{
          wheelSpeed: 2,
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={[location?.pathname]}
          defaultSelectedKeys={[location?.pathname]}
          className="maindiv"
        >
          {menuLinks}
        </Menu>
      </PerfectScrollBar>
    </Sider>
  );
}

export default memo(Index);
