import Document from "../../views/riderProfile/document";
import Finances from "../../views/riderProfile/Finances";
import Notes from "../../views/riderProfile/notes";
import Orders from "../../views/riderProfile/Orders";
import Payment from "../../views/riderProfile/payment";
import Profile from "../../views/riderProfile/Profile";
import Schedule from "../../views/riderProfile/schedule";

const routes = [
  {
    exact: true,
    path: "/",
    name: "profile",
    component: Profile,
  },
  {
    exact: true,
    path: "/orders",
    name: "orders",
    component: Orders,
  },

  {
    exact: true,
    path: "/payment-history/*",
    name: "payment",
    component: Payment,
    children: [{
      exact: true,
      path: "payment",
      name: "payment",
      component: Payment,
    },],
  },
  {
    exact: true,
    path: "/schedule",
    name: "schedule",
    component: Schedule,
  },
  {
    exact: true,
    path: "/finance",
    name: "finance",
    component: Finances,
  },
  {
    exact: true,
    path: "/notes",
    name: "notes",
    component: Notes,
  },
  {
    exact: true,
    path: "/documents",
    name: "documents",
    component: Document,
  },
  {
    path: "*",
    name: "redirect",
  },
];
export default routes;
