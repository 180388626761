import { Row, Spin } from "antd";
import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";

import RiderPaymentModal from "../../../modals/riderPaymentModal";
import { removeRiderPayment } from "../../../redux/rider/action";

function Index({ payment }) {
  const dispatch = useDispatch();

  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const removepayment = () => {
    if (window.confirm("want to remove payment method?")) {
      setLoader(true);
      dispatch(removeRiderPayment(payment.id, stopLoader));
    }
  };
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col lg={4}
      className="my-3">
      <Spin spinning={loader}>
        <Row className="border-2 shadow-md rounded-lg">
          <Col
            lg={12}
            className="bg-gray-600 rounded-t-lg w-full  p-2 flex justify-between items-center "
          >
            <h1 className=" text-base fw-bold text-white">
              {payment.name}
            </h1>
            <div className="flex items-center  justify-between w-10">
              <FaEdit
                onClick={handleShow}
                className="text-white cursor-pointer"
              />
              <FaTrashAlt
                onClick={removepayment}
                className="text-white cursor-pointer"
              />
            </div>
          </Col>
          <Col lg={12}
            className="p-2">
            <p>
              Bank name: 
              {" "}
              <span>
                {payment.bankName}
              </span>
            </p>
            <p>
Sort code:
              {payment.code}
            </p>
            <p>
Account number:
              {payment.number}
            </p>
          </Col>
        </Row>
      </Spin>
      {show && (
        <RiderPaymentModal payment={payment}
          show={show}
          onHide={handleClose} />
      )}
    </Col>
  );
}
export default memo(Index);
