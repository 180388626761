import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCityBySlug } from "../../redux/admin/action";
import CityRoutes from "../../routers/cityRoutes";

function Index() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const city = useSelector((state) => state.admin?.city?.[slug]);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (slug) {
      setLoader(true);
      dispatch(getCityBySlug(slug, stopLoader));
    }
  }, [
    slug, dispatch
  ]);

  if (loader) {
    <div>
loading...
    </div>;
  }
  if (city) {
    return <CityRoutes />;
  }
  return null;
}
export default memo(Index);
