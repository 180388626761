/* eslint-disable no-case-declarations */
import * as constants from "./constants";

let initState = {
  orders: [],

  invoices: [],
};

function authReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
  case constants.GET_INVOICES:
    return {
      ...state,
      invoices: payload,
    };

  case constants.GET_ORDERS:
    return {
      ...state,
      orders: payload,
    };
  case constants.UPDATE_ORDER:
    let newOrders = state?.orders?.map((e) => {
      if (e.id === payload.id) {
        return {
          ...e,
          ...payload,
        };
      }
      return e;
    });
    return {
      ...state,
      orders: newOrders,
    };

  default:
    return state;
  }
}

export default authReducer;
