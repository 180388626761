import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

function Index({ item, onChange, onDelete, shifts }) {
  const { row, columns } = item;
  const [
    initialRow, setInitialRow
  ] = useState({});

  useEffect(() => {
    if (row) {
      let newInitial = { id: row.id };
      columns?.forEach((e) => {
        newInitial[e.key] = row[e.key];
      });

      setInitialRow(newInitial);
    }
  }, [
    row, columns
  ]);
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInitial = {
      ...initialRow,
      [name]: value,
    };

    setInitialRow(newInitial);
    onChange?.(newInitial);
  };

  return (
    <tr>
      {columns?.map((e) => {
        if (e.key === "shift") {
          return (
            <td key={e.key}>
              <Form.Select
                name="shift"
                className="border"
                type="text"
                value={initialRow?.shift}
                onChange={(c) => {
                  const { name, value } = c.target;
                  let newInitial = {
                    ...initialRow,
                    [name]: value,
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
              >
                <option value="">
Choose shift
                </option>
                {shifts?.map((shift) => {
                  return <option key={shift.id}
                    value={shift.id}>
                    {shift?.name}
                  </option>;
                })}
              </Form.Select>
            </td>
          );
        }
        if (e.key === "action") {
          return (
            <td key={e.key}>
              <button type="button"
                onClick={() => onDelete(initialRow.id)}>
                <FaTrashAlt />
              </button>
            </td>
          );
        }
        return (
          <td key={e.key}>
            <input
              placeholder={e?.label}
              step={0.1}
              type="number"
              value={initialRow[e?.key]}
              name={e?.key}
              onChange={onChangeHandler}
            />
          </td>
        );
      })}
    </tr>
  );
}

export default memo(Index);
