import { TimePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

function Index({ item, onChange, onDelete }) {
  const { row, columns } = item;
  const [
    initialRow, setInitialRow
  ] = useState({});

  useEffect(() => {
    if (row) {
      let newInitial = { id: row.id };
      columns?.forEach((e) => {
        newInitial[e.key] = row[e.key];
      });

      setInitialRow(newInitial);
    }
  }, [
    row, columns
  ]);
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInitial = {
      ...initialRow,
      [name]: value,
    };

    setInitialRow(newInitial);
    onChange?.(newInitial);
  };

  return (
    <tr>
      {columns?.map((e) => {
        if (e.key === "name") {
          return (
            <td key={e.key}>
              <Form.Control
                name="name"
                placeholder="Enter shift name"
                className="border"
                type="text"
                value={initialRow?.name}
                onChange={(c) => {
                  const { name, value } = c.target;
                  let newInitial = {
                    ...initialRow,
                    [name]: value,
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
              />
            </td>
          );
        }
        if (e.key === "startTime") {
          return (
            <td key={e.key}>
              <TimePicker
                format="HH:mm"
                value={moment(initialRow?.startTime, "HH:mm")}
                onChange={(value) => {
                  let newInitial = {
                    ...initialRow,
                    startTime: moment(value).format("HH:mm"),
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
              />
            </td>
          );
        }
        if (e.key === "endTime") {
          return (
            <td key={e.key}>
              <TimePicker
                format="HH:mm"
                value={moment(initialRow?.endTime, "HH:mm")}
                onChange={(value) => {
                  let newInitial = {
                    ...initialRow,
                    endTime: moment(value).format("HH:mm"),
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
              />
            </td>
          );
        }
        if (e.key === "action") {
          return (
            <td key={e.key}>
              <button type="button"
                onClick={() => onDelete(initialRow.id)}>
                <FaTrashAlt />
              </button>
            </td>
          );
        }
        return (
          <td key={e?.key}>
            <input
              placeholder={e?.label}
              step={0.1}
              type="number"
              value={initialRow[e?.key]}
              name={e?.key}
              onChange={onChangeHandler}
            />
          </td>
        );
      })}
    </tr>
  );
}

export default memo(Index);
