import "./index.css";

import { SearchOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getInvoicesByRiderId } from "../../../redux/auth/action";

function Index() {
  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",

      render: (e) =>
        `${moment(e).format("DD-MMM-YY")}/
${moment(e).format("hh:mm A")}`,
    },
    {
      title: "Invoice #",
      align: "center",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: "left",
    },
    {
      title: "From",
      align: "center",
      dataIndex: "from",
      key: "from",
      fixed: "left",

      render: (e) =>
        `${moment(e).format("DD-MMM-YY")}/
${moment(e).format("hh:mm A")}`,
    },

    {
      title: "To",
      align: "center",
      dataIndex: "to",
      key: "to",
      fixed: "left",

      render: (e) =>
        `${moment(e).format("DD-MMM-YY")}/
${moment(e).format("hh:mm A")}`,
    },
    {
      title: "Download",
      align: "center",
      dataIndex: "url",
      key: "url",

      render: (value) => {
        return (
          <a
            className="btn  btn-sm btn-warning fw-bold text-white "
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
        );
      },
    },
  ];

  const { invoices } = useSelector((state) => state.auth);

  const [
    filterInvoices, setFilterInvoices
  ] = useState([]);

  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getInvoicesByRiderId(stopLoader));
  }, [dispatch]);

  const searchtable = (e) => {
    let filterbyorder = invoices.filter((order) =>
      order.invoiceNumber.includes(e.target.value)
    );

    setFilterInvoices(filterbyorder);
  };
  const finalInvoices = filterInvoices?.length ? filterInvoices : invoices;
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Finance
          </h1>
        </Col>
        <Col lg={12}
          className="flex justify-end ">
          <Input
            onChange={searchtable}
            className="m-1"
            style={ { width: "20%" } }
            placeholder="invoice number"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Table
          bordered
          className="table-curved riderTable"
          columns={columns}
          dataSource={finalInvoices}
          pagination={false}
          loading={loader}
          scroll={invoices?.length > 7 && { y: 400 }}
        />
      </Row>
    </Container>
  );
}

export default memo(Index);
