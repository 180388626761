import React ,{ memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getUserById } from "../../redux/admin/action";
import UserRoutes from "../../routers/userRoutes";

function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const { profileUser } = useSelector((state) => state.admin);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      dispatch(getUserById(id, stopLoader));
    }
  }, [
    id, dispatch
  ]);

  if (loader) {
    <div>
loading...
    </div>;
  }
  if (profileUser) {
    return <UserRoutes />;
  }
  return null;
}
export default memo(Index);
