import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

import { ukCurrancy } from "../../../utils/convertions";
import styles from "./styles";

function Ordertr({ order, showDed }) {
  const deductions =
    order?.deduction?.from === "rider" ? order?.deduction?.fee : 0;
  const riderFee = parseFloat(order.riderdetail.riderfee || 0);
  const ridertip = parseFloat(order.ridertip || 0);

  const diliveryFee = order.deliveryfree;
  return (
    <View style={styles.TableLableRow}>
      <Text style={styles.TableDataText}>
        {moment(Number(order?.createdAt)).format("DD-MMM-YY/hh:mm A")}
      </Text>
      <Text style={styles.TableDataText}>
        {order?.orderNumber}
      </Text>
      <Text style={styles.TableDataText}>
        {ukCurrancy.format(diliveryFee)}
      </Text>
      <Text style={styles.TableDataText}>
        {ukCurrancy.format(order?.ridertip)}
      </Text>
      <Text style={styles.TableDataText}>
        {order?.deduction?.from === "rider" ? (
          <>
            {ukCurrancy.format(deductions)}
          </>
        ) : (
          "0.00"
        )}
      </Text>
      {showDed && (
        <Text style={styles.TableDataText}>
          {order?.deduction?.from === "rider"
            ? order?.deduction?.reason
            : "No deductions"}
        </Text>
      )}
      <Text style={styles.TableDataText}>
        {ukCurrancy.format(ridertip + diliveryFee - deductions + riderFee)}
      </Text>
    </View>
  );
}

export default Ordertr;
