import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Enter name"),
  delay: yup.string(),
  delayMessage: yup.string(),
  VAT: yup
    .number("Enter correct value added tax")
    .required("Enter VAT (Value added tax)")
    .min(0, "Enter correct value added tax"),
  platformFee:yup.object().shape({
    value: yup
      .number("Enter correct platform fee")
      .required("Enter platform fee")
      .min(0, "Enter correct platform fee"),
  }),
  driverFee: yup.object().shape({
    value: yup
      .number("Enter correct driver fee")
      .required("Enter driver fee")
      .min(0, "Enter correct driver fee"),
  })
});

export default schema;
