import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";

import SupportAccordian from "../../../components/userSupport/SupportAccordian";
import SupportForm from "../../../components/userSupport/SupportForm";

function Index() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Support
          </h1>
        </Col>
        <Col lg={12}
          className="bg-white rounded-lg shadow-md p-5">
          <Row className="d-flex justify-content-center align-items-center flex-column">
            <SupportAccordian />
            <SupportForm />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
