import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { memo, useCallback, useEffect, useMemo,useState  } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import NavigoTable from "../../../components/NavigoTable";
import {
  addNewCityTariff,
  getCitySubCollection,
  getTariffsByCityId,
  getVehicleTypes,
  removeTariffById,
} from "../../../redux/admin/action";
import { sortTariffsByDistance } from "../../../utils/sortTariffs";
import DistanceRow from "./distanaceRow";

function Index() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const city = useSelector((state) => state.admin.city?.[slug]);
  const [
    distancesRates, setDistancesRates
  ] = useState([]);
  const { vehicleTypes } = useSelector((state) => state.admin);
  const [
    columns, setColumns
  ] = useState([]);
  const finalColumns = useMemo(
    () => [
      { label: "Distance", key: "distance" },
      ...columns,
      { label: "Actions", key: "action" },
    ],
    [columns]
  );

  const addNewDistanceHandler = useCallback(() => {
    let payload = {};
    finalColumns.forEach((e) => {
      payload[e.key] = 0;
    });
    Object.assign(payload, {
      distance: {
        min: 0,
        max: 0,
      },
    });
    dispatch(
      addNewCityTariff(payload, city.id, (tariff) => {
        setDistancesRates((prev) => sortTariffsByDistance([
          ...prev, tariff
        ]));
      })
    );
  }, [
    finalColumns, dispatch, city
  ]);

  const onDistanceFocusHandler = useCallback(async () => {
    let sortedResults = [];
    const results = await sortTariffsByDistance(distancesRates);
    results?.forEach((value) => {
      sortedResults.push(value);
    });
    setDistancesRates(sortedResults);
  }, [distancesRates]);
  const onDeleteHandler = useCallback(
    (id) => {
      dispatch(
        removeTariffById(city?.id, id, () => {
          let removedArray = distancesRates.filter((e) => e.id !== id);

          setDistancesRates(sortTariffsByDistance(removedArray));
        })
      );
    },
    [
      city, dispatch, distancesRates
    ]
  );
  useEffect(() => {
    setColumns(
      vehicleTypes.map((e) => {
        return {
          label: e.name,
          key: e.id,
        };
      })
    );
  }, [vehicleTypes]);

  useEffect(() => {
    if (city) {
      dispatch(getVehicleTypes(() => {}));
      dispatch(
        getTariffsByCityId(city?.id, async (data) => {
          if (data?.length) {
            setDistancesRates(await sortTariffsByDistance(data));
          }
        })
      );
    }
  }, [
    city, dispatch
  ]);
  const [
    loader, setLoader
  ] = useState(false);
  const saveTariffHandler = useCallback(() => {
    setLoader(true);
    dispatch(
      getCitySubCollection(distancesRates, city?.id, "Tariffs", (data) => {
        setDistancesRates(data);
        setLoader(false);
      })
    );
  }, [
    city, dispatch, distancesRates
  ]);
  return (
    <Container className=" w-100 ">
      <Row className="gap-2">
        <Col lg={12}>
          <h2 className="fw-bold">
Tariffs
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="primary"
            className="  fw-bold  "
            onClick={addNewDistanceHandler}
          >
            <PlusOutlined className="m-auto" />
            Add new tariff
          </Button>
        </Col>
        <Col lg={12}
          className="shadow rounded-3 p-0 ">
          <NavigoTable
            columns={finalColumns}
            data={distancesRates}
            renderItem={(item) => {
              return (
                <DistanceRow
                  key={item?.index}
                  onDelete={onDeleteHandler}
                  onDistanceFocus={onDistanceFocusHandler}
                  item={item}
                  onChange={(e) => {
                    const newDistance = distancesRates.map((a) => {
                      if (a.id === e.id) {
                        return {
                          ...a,
                          ...e,
                        };
                      }
                      return a;
                    });
                    setDistancesRates(newDistance);
                  }}
                />
              );
            }}
          />
        </Col>
        <Col lg={12}>
          <Button
            loading={loader}
            onClick={saveTariffHandler}
            type="primary"
            className="  fw-bold  "
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
