import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Enter name"),

  location: yup.object().shape({
    latValue: yup.number().required("Please pin your location"),
    lngValue: yup.number().required("Please pin your location"),
  }),
});

export default schema;
