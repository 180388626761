import { Button, Input, Modal, TimePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  AddAdminShift,
  removeAdminShift,
  updateAdminShift,
} from "../../redux/admin/action";

const { RangePicker } = TimePicker;
function Index({ show, onHide, selectedDate, selectedShift }) {
  const [
    errors, setErrors
  ] = useState({});
  const { shifts, profileAdmin } = useSelector((state) => state.admin);
  let [
    shift, setShift
  ] = useState({
    time: [
      moment(), null
    ],
    name: "",
  });
  useEffect(() => {
    if (selectedShift?.start) {
      setShift((prevState) => ({
        ...prevState,
        time: [
          moment(selectedShift.start, "HH:mm"),
          moment(selectedShift.end, "HH:mm"),
        ],
      }));
      return;
    }
    if (selectedShift) {
      setShift({
        time: selectedShift.time.map((time) => moment(time, "HH:mm")),
        name: selectedShift.name,
      });
    }
  }, [selectedShift]);
  const [
    saving, setSaving
  ] = useState(false);
  const dispatch = useDispatch();
  const [
    flag, setFlag
  ] = useState(false);
  const dayOffShift = (value) => {
    setErrors({});

    if (shift?.time?.filter((e) => e).length !== 2) {
      setErrors({
        time: "Time is required",
      });
      return;
    }
    setFlag(true);

    if (selectedShift?.start) {
      dispatch(
        AddAdminShift(
          {
            ...shift,
            offDay: !value,
            date: moment(selectedDate).format("x"),
            time: shift.time.map((e) => moment(e).format("HH:mm")),
            dayId: selectedShift.id,
          },
          stopAdder
        )
      );
      return;
    }
    dispatch(
      updateAdminShift(
        {
          ...shift,
          offDay: !value,
          date: moment(selectedDate).format("x"),
          time: shift.time.map((e) => moment(e).format("HH:mm")),
        },
        selectedShift.id,
        () => {
          setFlag(false);
          onHide();
        }
      )
    );
  };
  const addSchedule = async (e) => {
    e.preventDefault();
    setErrors({});
    if (shift.name === "") {
      setErrors({
        name: "Name is required",
      });
      return;
    }

    if (shift?.time?.filter((e) => e).length !== 2) {
      setErrors({
        time: "Time is required",
      });
      return;
    }
    const filterShifts = shifts.filter((e) =>
      moment(Number(e?.date)).isSame(moment(selectedDate), "day")
    );
    const todaySchedules =
      profileAdmin?.scheduleTime?.[
        moment(selectedDate).format("dddd").toLowerCase()
      ];
    let shiftsLength;
    if (
      filterShifts.find((e) => {
        if (todaySchedules?.map((e) => e.id)?.includes(e.id)) {
          return e;
        }
        return null;
      })
    ) {
      shiftsLength = filterShifts?.length - 1;
    } else {
      shiftsLength = filterShifts?.length;
    }
    if (shiftsLength + todaySchedules?.length === 2 && !selectedShift) {
      setErrors({
        time: "Already have two shifts in this day",
      });
      return;
    }
    setSaving(true);
    if (selectedShift) {
      if (selectedShift?.start) {
        dispatch(
          AddAdminShift(
            {
              ...shift,
              offDay: false,
              date: moment(selectedDate).format("x"),
              time: shift.time.map((e) => moment(e).format("HH:mm")),
              dayId: selectedShift.id,
            },
            stopAdder
          )
        );
        return;
      }

      dispatch(
        updateAdminShift(
          {
            ...shift,

            time: shift.time.map((e) => moment(e).format("HH:mm")),
          },
          selectedShift?.id,
          stopAdder
        )
      );
      return;
    }

    dispatch(
      AddAdminShift(
        {
          ...shift,
          date: moment(selectedDate).format("x"),
          time: shift.time.map((e) => moment(e).format("HH:mm")),
          offDay: false,
        },

        stopAdder
      )
    );
  };
  const [
    removing, setRemoving
  ] = useState(false);
  const removeShift = () => {
    if (window.confirm("Are you sure you want to delete this shift?")) {
      setRemoving(true);

      dispatch(removeAdminShift(selectedShift.id, stopRemover));
    }
  };
  const stopRemover = () => {
    setRemoving(false);
    onHide();
  };
  const stopAdder = () => {
    setSaving(false);
    onHide();
  };

  return (
    <Modal
      title={selectedShift
        ? `Update Shift (${
            selectedShift?.name || moment(selectedDate).format("dddd")
          }) For ${moment(selectedDate).format("DD MMM YYYY")} `
        : `Add Shift for ${moment(selectedDate).format("DD MMM YYYY")}`}
      centered
      onCancel={onHide}
      visible={show}
      footer={null}
    >
      <Form className="  w-100"
        onSubmit={addSchedule}>
        <Form.Group className="flex items-center"
          controlId="formBasicemail">
          <Form.Label>
Name:
            {" "}
          </Form.Label>
          <div className="m-3 ">
            <Input
              name="name"
              size="middle"
              value={shift?.name}
              placeholder="Name"
              onChange={(value) => {
                setShift({ ...shift, name: value.target.value });
              }}
            />
            {errors.name && (
              <p className="text-red-500 text-xs">
                {errors.name}
              </p>
            )}
          </div>
        </Form.Group>
        <Form.Group className="flex items-center"
          controlId="formBasicemail">
          <Form.Label>
Time:
            {" "}
          </Form.Label>
          <div className="m-3 ">
            <RangePicker
              name="time"
              size="middle"
              format="HH:mm"
              value={shift?.time}
              onChange={(value) => {
                setShift({
                  ...shift,
                  time: value,
                });
              }}
            />
            {errors.time && (
              <p className="text-red-500 text-xs">
                {errors.time}
              </p>
            )}
          </div>
        </Form.Group>
        <div className="flex justify-end gap-2 flex-wrap">
          {selectedShift?.id && !selectedShift?.start && (
            <Button
              onClick={removeShift}
              type="danger"
              loading={removing}
              disabled={removing}
              className="fw-bold "
            >
              Remove
            </Button>
          )}
          {selectedShift && !selectedShift?.lockInTime && (
            <Button
              onClick={() => dayOffShift(selectedShift?.offDay)}
              loading={flag}
              disabled={flag}
              className="fw-bold  "
            >
              {selectedShift?.offDay ? "Working" : "Not Working"}
            </Button>
          )}
          <Button
            type="primary"
            loading={saving}
            disabled={saving}
            htmlType="submit"
            className="fw-bold text-white"
          >
            {saving
              ? `${selectedShift ? "Updating..." : "Adding..."}`
              : `${selectedShift ? "Update" : "Add"}`}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default memo(Index);
