import { Button, Empty, Select, Spin } from "antd";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import DrivingLicenceModal from "../../../modals/DrivingLicenceModal";
import IdentityRecordModal from "../../../modals/IdentityRecordModal";
import VehicalDocumentModal from "../../../modals/VehicalDocumentModal";
import { getRiderDetailInfo } from "../../../redux/admin/action";
import Documents from "./document";

const { Option } = Select;

function Modal(props) {
  const { type } = props;
  switch (type?.id) {
  case "identity":
    return <IdentityRecordModal {...props} />;
  case "driving_License":
    return <DrivingLicenceModal {...props} />;
  case "vehicle_Document":
    return <VehicalDocumentModal {...props} />;

  default:
    return <Fragment />;
  }
}
function Index() {
  const dispatch = useDispatch();
  const [
    info, setInfo
  ] = useState([]);

  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      // setInfo(data);
      data?.map((e) => {
        if (e?.additionalDocuments) {
          let infor = e.additionalDocuments;
          let id = e.id;
          let finalInfo = { ...infor, id };
          setInfo((arr) => [
            ...arr, finalInfo
          ]);
        } else {
          setInfo((arr) => [
            ...arr, e
          ]);
        }
        return null;
      });
    }
  };
  const updateHandler = (data) => {
    let updateData = info.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setInfo(updateData);
  };
  const addDocumentHandler = (data) => {
    let newDocs = info.concat([data]);
    setInfo(newDocs);
  };
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);

  const [
    docType, setDocType
  ] = useState(null);
  useEffect(() => {
    setLoader(true);
    dispatch(getRiderDetailInfo(stopLoader));
  }, [dispatch]);

  const deleteDocument = (data) => {
    const newDocument = info.filter((e) => e.id !== data.id);
    setInfo(newDocument);
  };

  const addDocument = () => {
    if (!docType) {
      swal("Please select document type");
      return;
    }
    if (
      info?.find(
        (e) =>
          e?.name === docType ||
          info?.find((e) => e?.additionalDocuments?.name === docType)
      )
    ) {
      swal("Document already added");
      return;
    }
    setShow(true);
  };
  return (
    <Container fluid>
      <Spin spinning={loader}>
        <Row>
          <Col lg={12}>
            <h1 className="fw-bold">
Documents
            </h1>
          </Col>
          <Col lg={12}
            className="flex justify-end flex-wrap gap-2">
            <Select
              showSearch
              value={docType}
              className="text-capitalize"
              onChange={(e) => setDocType(e)}
              style={ { width: 200, marginRight: 4 } }
              placeholder="Select document type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())}
            >
              {[
                { label: "Identity", value: "identity" },
                { label: "Driving licence", value: "driving_License" },
                { label: "Vehicle document", value: "vehicle_Document" },
              ].map((e, index) => (
                <Option className=" capitalize"
                  key={index}
                  value={e.value}>
                  {e.label}
                </Option>
              ))}
            </Select>
            <Button type="primary"
              onClick={addDocument}
              className="fw-bold">
              Add Document
            </Button>
          </Col>
          {info?.length > 0 ? (
            <div>
              {/* {info?.additionalDocuments ? info?.add} */}
              {info.map((inf, index) => (
                <Documents
                  onDelete={deleteDocument}
                  onUpdate={updateHandler}
                  key={index}
                  // info={inf?.additionalDocuments ? inf?.additionalDocuments : inf}
                  info={inf}
                />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Row>
        {show && (
          <Modal
            as="NEW"
            onAdd={addDocumentHandler}
            type={ { id: docType } }
            show={show}
            onHide={handleClose}
          />
        )}
      </Spin>
    </Container>
  );
}
export default memo(Index);
