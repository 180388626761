import moment from "moment";
import React, { memo } from "react";

function Index({ time }) {
  if (!time) {
    return <p>
N/A
    </p>;
  }
  return (
    <p>
      {moment(Number(time)).format("DD MMM YYYY HH:mm")}
      {" "}
(
      {moment(time).from(moment())}
)
    </p>
  );
}

export default memo(Index);
