import "./index.css";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import React, { memo, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import LogoImg from "../../assets/images/logo.png";
import { login } from "../../redux/auth/action";
import loginValidation from "../../validations/login";

function Index() {
  const [error, setError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = (data) => {
    setError([]);
    setLoader(true);
    // console.log("data", data);
    dispatch(login({ ...data, rememberMe }, stopLoader));
  };
  const stopLoader = (isLogin, code) => {
    // console.log("is login", isLogin);
    if (isLogin) {
      navigate("/view");
    } else {
      // console.log("code", code);
      setError(code);
    }
    setLoader(false);
  };
  const [rememberMe, setRememberMe] = useState(false);

  return (
    <>
      <Container
        fluid
        className="flex justify-center bg-light items-center py-5"
      >
        <Row className="w-full">
          <Col
            lg={12}
            className="text-center mb-3 rider-login-main d-flex justify-center"
          >
            <img src={LogoImg} alt="logo" />
          </Col>
          <Col lg={12} className="text-center mb-3 rider-login-main">
            <h2 className="fs-4">Welcome admin!</h2>
          </Col>
          <Col
            xs="11"
            md="10"
            lg="5"
            xl="5"
            className="loginFormWrapper  shadow-lg rounded-lg text-center m-auto bg-white p-5"
          >
            <h1 className="riheading">Sign in</h1>
            <Formik
              initialValues={{
                email: "",
                pass: "",
              }}
              validationSchema={loginValidation}
              onSubmit={userLogin}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,

                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit} className="loginForm">
                  <Form.Group as={Row} controlId="formHorizontalemail">
                    <FilledInput
                      id="filled-adornment-email"
                      type="text"
                      placeholder="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <span
                        className="w-100 text-danger text-start"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.email}
                      </span>
                    )}
                    {error?.includes("auth/user-not-found") && (
                      <span
                        className="w-100 text-danger text-start"
                        style={{ fontSize: "12px" }}
                      >
                        Enter email not register yet
                      </span>
                    )}
                    {error?.includes("auth/user-disabled") && (
                      <span
                        className="w-100 text-danger text-start"
                        style={{ fontSize: "12px" }}
                      >
                        You can`t login contact to support team
                      </span>
                    )}
                  </Form.Group>
                  <br></br>
                  <Form.Group as={Row} controlId="formHorizontalPassword">
                    <FilledInput
                      id="filled-adornment-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="pass"
                      value={values.pass}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.pass && touched.pass && (
                      <span
                        className="w-100 text-danger text-start"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.pass}
                      </span>
                    )}
                    {error?.includes("auth/wrong-password") && (
                      <span
                        className="w-100 text-danger text-start"
                        style={{ fontSize: "12px" }}
                      >
                        Enter password is wrong
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mt-2"
                    controlId="formHorizontalCheck"
                  >
                    <Col sm="8" md="8" lg="6" style={{ textAlign: "left" }}>
                      <Form.Check
                        label="Remember me"
                        checked={rememberMe}
                        name="rememberMe"
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 0, offset: 0 }}>
                      <Button
                        className="fw-bolder text-white my-3 btn-lg riderlogin"
                        type="submit"
                        disabled={loader}
                        style={{ background: "#ffb814", border: "none" }}
                      >
                        {loader ? (
                          <div className="d-flex font-bold justify-content-center align-items-center">
                            <Spinner
                              animation="border"
                              className="mr-2"
                              variant="dark"
                              size="sm"
                            />
                            Login...
                          </div>
                        ) : (
                          "Sign in"
                        )}
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </Formik>
            <Col lg={3}>
              <Link to="/forget-password" className="link-secondary ">
                Forget password
              </Link>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(Index);
