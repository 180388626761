import { Tabs } from "antd";
import React, { memo } from "react";
import { FaBicycle, FaShopware } from "react-icons/fa";

import Company from "./company";
import Rider from "./rider";

const { TabPane } = Tabs;
function Index() {
  return (
    <Tabs defaultActiveKey="1"
      centered>
      <TabPane
        tab={<span className="flex items-center fw-bold">
          <FaBicycle className="mr-2" />
            Drivers
        </span>}
        key="1"
      >
        <Rider />
      </TabPane>
      <TabPane
        tab={<span className="flex items-center fw-bold">
          <FaShopware className="mr-2" />
            Riders
        </span>}
        key="2"
      >
        <Company />
      </TabPane>
      <TabPane
        tab={<span className="flex items-center fw-bold">
          <FaShopware className="mr-2" />
            Company
        </span>}
        key="3"
      >
        <Company />
      </TabPane>
    </Tabs>
  );
}
export default memo(Index);
