import "./index.css";

import { Table } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import OrderItem from "../../../modals/OrderItem";
import { getRiderOrders } from "../../../redux/rider/action";

function Index() {
  const { orders } = useSelector((state) => state.rider);

  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const [
    modalShow, setModalShow
  ] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(getRiderOrders(stopLoader));
  }, [dispatch]);
  const [
    items, setItems
  ] = useState([]);
  const columns = [
    {
      title: "Driver name",
      align: "center",
      dataIndex: "restaurant",
      key: "restaurant",

      width: 120,
      render: (value) => (
        <p>
          {value?.restInformation?.name} 
          {" "}
          <br />
          {value?.restInformation?.location?.address}
        </p>
      ),
    },
    {
      title: "Order",
      align: "center",
      dataIndex: "items",
      key: "items",
      width: 120,

      render: (items) => (
        <Button
          variant="warning"
          onClick={() => {
            setItems(items);
            setModalShow(true);
          }}
          className="btn-sm fw-bolder text-white"
        >
          View
        </Button>
      ),
    },
    {
      title: "Client",
      align: "center",
      dataIndex: "clientInfo",
      key: "clientInfo",
      width: 120,

      render: (value) => (
        <p>
          {value?.name} 
          {" "}
          <br />
          {value?.location?.address}
        </p>
      ),
    },

    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      className: " capitalize",
      width: 120,

      key: "status",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Rides history
          </h1>
        </Col>
        <Table
          bordered
          loading={loader}
          className="table-curved riderTable"
          columns={columns}
          dataSource={orders}
          pagination={false}
          scroll={orders.length > 7 && { y: 400 }}
        />
      </Row>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
