import React from "react";
import { Navigate } from "react-router-dom";

import Document from "../../views/adminProfile/document";
import Leave from "../../views/adminProfile/leave";
import Notes from "../../views/adminProfile/notes";
import Payment from "../../views/adminProfile/payment";
import Profile from "../../views/adminProfile/Profile";
import Schedule from "../../views/adminProfile/schedule";
import Shifts from "../../views/adminProfile/shifts";

const routes = [
  {
    exact: true,
    path: "/",
    name: "profile",
    component: Profile,
  },
  {
    exact: true,
    path: "/shifts",
    name: "shifts",
    component: Shifts,
  },
  {
    exact: true,
    path: "/payment",
    name: "payment",
    component: Payment,
  },
  {
    exact: true,
    path: "/schedule",
    name: "schedule",
    component: Schedule,
  },
  {
    exact: true,
    path: "/absence",
    name: "absence",
    component: Leave,
  },
  {
    exact: true,
    path: "/notes",
    name: "notes",
    component: Notes,
  },
  {
    exact: true,
    path: "/documents",
    name: "documents",
    component: Document,
  },
  {
    path: "*",
    name: "redirect",
    component: () => <Navigate to={-1}
      replace={true} />,
  },
];
export default routes;
