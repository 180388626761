import { combineReducers } from "redux";

import admin from "./admin/reducer";
import auth from "./auth/reducer";
import rider from "./rider/reducer";
import user from "./user/reducer";

const createReducer = combineReducers({
  auth,
  admin,
  rider,
  user,
});

export default createReducer;
