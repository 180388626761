export const sortTariffsByDistance = (tariffs = []) => {
  return new Promise((resolve, reject) => {
    try {
      let sortedTariffs = tariffs.sort(
        (a, b) => Number(a?.distance?.max) - Number(b?.distance?.max)
      );
      for (let index = 0; index < sortedTariffs.length; index++) {
        const curr = sortedTariffs.findIndex(
          (obj) => obj?.id === sortedTariffs[index]?.id
        );
        const next = sortedTariffs.findIndex(
          (obj) => obj?.id === sortedTariffs[index + 1]?.id
        );

        const currDistance = sortedTariffs[curr]?.distance;
        const nextDistance = sortedTariffs[next]?.distance;
        if (sortedTariffs[next]) {
          sortedTariffs[next]["distance"] = {
            ...nextDistance,
            min: currDistance?.max,
          };
        }
      }
      if (sortedTariffs?.length) {
        sortedTariffs[0].distance = {
          ...sortedTariffs[0]?.["distance"],
          min: 0,
        };
      }
      resolve(sortedTariffs);
    } catch (error) {
      reject(error);
    }
  });
};
