import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <p>
        Address image:
        {" "}
        <span>
          <a target="_blank"
            rel="noreferrer"
            href={info?.addressImg}>
            view
          </a>
        </span>
      </p>
      <p>
        Rider image:
        {" "}
        <span>
          <a target="_blank"
            rel="noreferrer"
            href={info?.riderImg}>
            view
          </a>
        </span>
      </p>
    </div>
  );
}

export default memo(Index);
