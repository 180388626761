import { Modal, TimePicker } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { memo, useState } from "react";
import { Button,Col, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { updateRiderFirestore } from "../../redux/admin/action";

const { RangePicker } = TimePicker;
function Index({ show, onHide }) {
  const { profileRider } = useSelector((state) => state.admin);

  const [
    loader, setLoader
  ] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();
  const saveTimming = (values) => {
    if (values.day.length) {
      const scheduleTime = {};
      values.day.forEach((e) => {
        scheduleTime[e] = values[e];
      });

      setLoader(true);
      dispatch(
        updateRiderFirestore({ scheduleTime }, profileRider.id, stopLoader)
      );
    } else {
      swal("Dear!", "Select atleast on day!", "info");
    }
  };

  return (
    <Modal
      title={"Add Schedule"}
      centered
      onCancel={onHide}
      visible={show}
      footer={null}
    >
      <Formik
        initialValues={ {
          day: profileRider?.scheduleTime
            ? Object.keys(profileRider?.scheduleTime).map((e) => e)
            : [],
          monday: profileRider?.scheduleTime?.monday || [
            "9:00", "21:00"
          ],
          tuesday: profileRider?.scheduleTime?.tuesday || [
            "9:00", "21:00"
          ],
          wednesday: profileRider?.scheduleTime?.wednesday || [
            "9:00", "21:00"
          ],
          thursday: profileRider?.scheduleTime?.thursday || [
            "9:00", "21:00"
          ],
          friday: profileRider?.scheduleTime?.friday || [
            "9:00", "21:00"
          ],
          saturday: profileRider?.scheduleTime?.saturday || [
            "9:00", "21:00"
          ],
          sunday: profileRider?.scheduleTime?.sunday || [
            "9:00", "21:00"
          ],
        } }
        //   validationSchema={restValidation.RestForm}
        onSubmit={saveTimming}
      >
        {({
          values,

          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicService">
              {Object.keys(values).map((value, index) => {
                if (value === "day") {
                  return null;
                }

                return (
                  <div key={index}>
                    <div className="d-flex align-items-center">
                      <Field
                        className="m-2 "
                        type="checkbox"
                        name="day"
                        value={value}
                      />
                      <label className="capitalize">
                        {value}
:
                      </label>
                    </div>
                    {values.day.includes(value) && (
                      <RangePicker
                        format={"HH:mm"}
                        showNow
                        onChange={(e) => {
                          const finalValue = e.map((a) => {
                            return moment(a).format("HH:mm");
                          });
                          setFieldValue(value, finalValue);
                        }}
                        value={values[value].map((e) => moment(e, "HH:mm"))}
                      />
                    )}
                  </div>
                );
              })}
            </Form.Group>
            <Col lg={12}
              className="text-center mt-4">
              <Button
                type="submit"
                className="rounded rounded-2 fw-bolder text-white"
                variant="warning"
                disabled={loader}
              >
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner
                      animation="border"
                      className="mr-2"
                      variant="dark"
                      size="sm"
                    />
                    Saving...
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default memo(Index);
