import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <div className="flex">
        <p>
          Insurance Certificate:
          {" "}
          <span className="mx-1">
            {info?.insuranceCertificate?.insuranceCertificate?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
          | Expiry Date:
          {" "}
          {info?.insuranceCertificate?.insuranceCertificateExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Insurance Supporting:
          {" "}
          <span className="mx-1">
            {info?.insuranceSupporting?.insuranceSupporting?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
          | Expiry Date: 
          {" "}
          {info?.insuranceSupporting?.insuranceSupportingExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Personal Insurance Number:
          {" "}
          <span className="mx-1">
            {info?.personalInsuranceNumber?.personalInsuranceNumber?.map(
              (val) => {
                return (
                  <>
                    <a target="_blank"
                      rel="noreferrer"
                      href={val}>
                      View
                      <span>
,
                        {" "}
                      </span>
                    </a>
                  </>
                );
              }
            )}
          </span>
        </p>
        <p>
          | Expiry Date:
          {" "}
          {info?.personalInsuranceNumber?.personalInsuranceNumberExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          log Book:
          {" "}
          <span className="mx-1">
            {info?.logBook?.logBook?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.logBook?.logBookExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Mot Test Certificate :
          {" "}
          <span className="mx-1">
            {info?.motTestCertificate?.motTestCertificate?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
          | Expiry Date: 
          {" "}
          {info?.motTestCertificate?.motTestCertificateExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Phv Licence:
          {" "}
          <span className="mx-1">
            {info?.phvLicence?.phvLicence?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.phvLicence?.phvLicenceExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Public Liability Insurance:
          {" "}
          <span className="mx-1">
            {info?.publicLiabilityInsurance?.publicLiabilityInsurance?.map(
              (val) => {
                return (
                  <>
                    <a target="_blank"
                      rel="noreferrer"
                      href={val}>
                      View
                      <span>
,
                        {" "}
                      </span>
                    </a>
                  </>
                );
              }
            )}
          </span>
        </p>
        <p>
          | Expiry Date:
          {" "}
          {info?.publicLiabilityInsurance?.publicLiabilityInsuranceExpiry}
        </p>
      </div>
    </div>
  );
}

export default memo(Index);
