import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ImageDrop from "../../components/Imagedrop";
import { addAdminDetail, updateAdminDetail } from "../../redux/admin/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    errors, seterrors
  ] = useState([]);
  const [
    riderdocumentsdata, setriderdocumentsdata
  ] = useState({
    type: "",
    passportexpirydate: "",
  });
  const dispatch = useDispatch();
  const [
    file, setfile
  ] = useState({
    documnetImg: "",
  });
  const inputChange = (e) => {
    const { value, name } = e.target;
    setriderdocumentsdata((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const saveaditinfo = (e) => {
    e.preventDefault();
    let error = [];
    if (riderdocumentsdata.type === "") {
      error.push({ typeempty: true });
    }
    if (
      riderdocumentsdata.type !== "Uk Birth Certificate" &&
      riderdocumentsdata.passportexpirydate === ""
    ) {
      error.push({ passportexpirydateempty: true });
    }
    if (file.documnetImg === "") {
      error.push({ documnetImgempty: true });
    }
    if (error.length < 1) {
      setsubmitloading(true);
      if (as === "NEW") {
        dispatch(
          addAdminDetail(
            "documents",
            {
              type: riderdocumentsdata.type,
              passportexpirydate: riderdocumentsdata.passportexpirydate,
              documnetImg: file.documnetImg,
            },
            stopLoader
          )
        );
        return;
      }
      dispatch(
        updateAdminDetail(
          "documents",
          {
            type: riderdocumentsdata.type,
            passportexpirydate: riderdocumentsdata.passportexpirydate,
            documnetImg: file.documnetImg,
          },
          stopLoader
        )
      );
    } else {
      seterrors(error);
    }
  };

  useEffect(() => {
    if (info) {
      setriderdocumentsdata({
        type: info.type,
        passportexpirydate: info.passportexpirydate,
      });

      setfile({
        documnetImg: info.documnetImg,
      });
    }
  }, [info]);

  const stopLoader = (data) => {
    setsubmitloading(false);
    if (data) {
      if (as === "NEW") {
        onAdd(data);
      } else {
        onUpdate(data);
      }
      onHide?.();
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="form additional-form w-100"
          method="POST"
          onSubmit={(e) => {
            saveaditinfo(e);
          }}
        >
          <h1>
            Documents Type 
            {" "}
            <span style={ { color: "red" } }>
*
            </span>
          </h1>
          {errors &&
            errors.map(({ typeempty }, index) => {
              if (typeempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "15px" } }
                  >
                    *required
                  </span>
                );
              }
              return null;
            })}
          <div className="inputGroup">
            <input
              id="radio1"
              name="type"
              checked={riderdocumentsdata.type === "Uk Passport" ? true : false}
              type="radio"
              value="Uk Passport"
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio1">
UK Passport
            </label>
          </div>
          <div className="inputGroup">
            <input
              id="radio2"
              name="type"
              type="radio"
              checked={riderdocumentsdata.type === "EU Passport" ? true : false}
              value="EU Passport"
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio2">
EU Passport
            </label>
          </div>
          <div className="inputGroup">
            <input
              id="radio3"
              name="type"
              type="radio"
              checked={riderdocumentsdata.type === "EU ID Card" ? true : false}
              value="EU ID Card"
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio3">
EU ID Card
            </label>
          </div>
          <div className="inputGroup">
            <input
              id="radio4"
              name="type"
              type="radio"
              value="Biometric Residence Permit (BRP)"
              checked={riderdocumentsdata.type === "Biometric Residence Permit (BRP)"
                ? true
                : false}
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio4">
Biometric Residence Permit (BRP)
            </label>
          </div>
          <div className="inputGroup">
            <input
              id="radio5"
              name="type"
              type="radio"
              checked={riderdocumentsdata.type === "Valid passport with visa inside"
                ? true
                : false}
              value="Valid passport with visa inside"
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio5">
Valid passport with visa inside
            </label>
          </div>
          <div className="inputGroup">
            <input
              id="radio6"
              name="type"
              type="radio"
              checked={riderdocumentsdata.type === "Uk Birth Certificate"
                ? true
                : false}
              value="Uk Birth Certificate"
              onChange={(e) => {
                inputChange(e);
              }}
            />
            <label htmlFor="radio6">
Uk Birth Certificate
            </label>
          </div>
          <Col lg={12}
            className=" ">
            <Row className="d-flex justify-content-center flex-column">
              <ImageDrop
                file={file.documnetImg}
                onSuccess={(documnetImg) => {
                  setfile({ documnetImg });
                }}
                names="documnetImg"
                title={"Upload (Passport, ID, Birth Certificate)"}
                subtitle={null}
                col={12}
              />
              {errors &&
                errors.map(({ documnetImgempty }, index) => {
                  if (documnetImgempty) {
                    return (
                      <span
                        key={index}
                        className="w-100 text-danger"
                        style={ { fontSize: "15px" } }
                      >
                        *required
                      </span>
                    );
                  }
                  return null;
                })}
            </Row>
          </Col>
          <Col
            lg={12}
            className="d-flex justify-content-center align-items-center mt-5 flex-column "
          >
            <div className="vehical-reg w-100">
              <h1>
                Please enter passport expiry date
                {" "}
                <span style={ { color: "red" } }>
*
                </span>
              </h1>
              <Form.Control
                type="date"
                className="w-100 mt-3"
                value={riderdocumentsdata.passportexpirydate}
                name="passportexpirydate"
                onChange={(e) => {
                  inputChange(e);
                }}
              />
            </div>
            {errors &&
              errors.map(({ passportexpirydateempty }, index) => {
                if (passportexpirydateempty) {
                  return (
                    <span
                      key={index}
                      className="w-100 text-danger"
                      style={ { fontSize: "15px" } }
                    >
                      *required
                    </span>
                  );
                }
                return null;
              })}
          </Col>
          <Col lg={12}>
            <Button
              variant="warning"
              size="sm"
              className="custombtn m-2 btn-sm fw-bolder text-white"
              disabled={submitloading ? true : false}
              type="submit"
            >
              {submitloading ? "Saving.." : "Save"}
            </Button>
          </Col>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
