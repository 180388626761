/* eslint-disable no-async-promise-executor */
import {
  firebase_bucket,
  INVOICE_COLLECTION,
  ORDER_COLLECTION,
  RIDER_COLLECTION,
} from "../../config";
import { INSTANCE } from "../../config/axiosInstance";
import { firestore, storage } from "../../config/firebase";

export default class RiderApi {
  async  getUserDetailById  (id)  {
    const riderRef = firestore().collection(RIDER_COLLECTION).doc(id);
    const riderDoc = await riderRef.get();

    if (riderDoc.exists) {
      const doc = await riderDoc.ref.collection("addtionalDocuments").get();
      return doc.empty ? [] : doc.docs.map((e) => e);
    }
    return null;
  }
  async addRiderDetail  (id, by, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("addtionalDocuments")
      .doc(by);

    const res = await riderRef.set(data);
    await riderRef.set({ additionalDocuments: data });

    return res ? false : { ...data, id: by };
  }

  async addRiderPayment  (id, paymentId, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(paymentId);

    const res = await riderRef.set(data);

    return res ? false : { ...data, id: paymentId };
  }
  async updateRiderPayment  (id, paymentId, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(paymentId);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: paymentId };
  }
  async removeRiderDetail  (id, by, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("addtionalDocuments")
      .doc(by);

    const res = await riderRef.delete();

    return res ? false : { ...data, id: by };
  }
  async removeRiderPayment  (id, payId)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(payId);

    const res = await riderRef.delete();

    return res ? false : { id: payId };
  }
  async removeRiderSchedule  (id, schId)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("schedules")
      .doc(schId);

    const res = await riderRef.delete();

    return res ? false : { id: schId };
  }
  async updateRiderSchedule  (id, schId, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("schedules")
      .doc(schId);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: schId };
  }
  async updateRiderDetail  (id, by, data)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("addtionalDocuments")
      .doc(by);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: by };
  }
  async getUserInformation  (id, by)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("addtionalDocuments")
      .doc(by);

    const res = await riderRef.get();

    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async getRiderOrders  (id)  {
    const riderRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("riderdetail.id", "==", id);
    const res = await riderRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async getRiderVehicles  (id)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("vehicles");
    const res = await riderRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async  updateDriverVehicle  (data, id, docId)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("vehicles")
      .doc(docId);
    const res = await riderRef.update(data);

    return res ? false : { ...data, id: docId };
  }
  async deleteDriverVehicle  (id, docId)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("vehicles")
      .doc(docId);
    const res = await riderRef.delete();

    return res ? false : { id: docId };
  }
  async saveInvoice  (data, id)  {
    const invoiceRef = firestore()
      .collection(INVOICE_COLLECTION)
      .where("from", "==", data.from)
      .where("to", "==", data.to);
    const invoices = await invoiceRef.get();
    if (invoices.empty) {
      const riderRef = firestore().collection(INVOICE_COLLECTION).doc(id);
      const res = await riderRef.set(data);

      return res ? false : true;
    } else {
      return false;
    }
  }
  async getEmergencyData  (id)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("addtionalDocuments")
      .doc("emergency");
    const doc = await riderRef.get();

    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }
  async AddRiderSchedule  (data, userId, id)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(userId)
      .collection("schedules")
      .doc(id);
    const doc = await riderRef.set(data);

    return doc ? false : { ...data, id };
  }
  async getRiderPayments  (id)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("payments");

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async getRiderSchedules  (id)  {
    const riderRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("schedules");

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async riderSupport  (data)  {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/support/rider",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async uploadImage  (acceptedFiles)  {
    return await new Promise(async (r, eror) => {
      var array = [];
      for (var i = 0; i < Array.from(acceptedFiles).length; i++) {
        try {
          let bucketName = firebase_bucket;
          var filename = Array.from(acceptedFiles)[i].name.split(".");
          var ext = filename[filename.length - 1];
          let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
          let upload = await storageRef.put(Array.from(acceptedFiles)[i]);
          const downloadURL = await upload.ref.getDownloadURL();
          array.push(downloadURL);
        } catch (error) {
          eror(error);
        }
      }
      r(array);
    });
  }
  async addNoteForRider  (data, id, noteId)  {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.set(data);

    return res ? false : { ...data, id: noteId };
  }
  async getRiderNotes  (id)  {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async getRiderDetails  ()  {
    const userRef = firestore().collection(RIDER_COLLECTION);
    const res = await userRef.get();
    let riders = [];
    for (let i = 0; i < res.size; i++) {
      const newRef = await firestore()
        .collection(RIDER_COLLECTION)
        .doc(res.docs[i].id)
        .collection("addtionalDocuments")
        .get();
      if (!newRef.empty) {
        const singleRide = await firestore()
          .collection(RIDER_COLLECTION)
          .doc(res.docs[i].id)
          .get();
        let driver = singleRide.data();
        let documents = [];
        newRef.docs.map((e) => {
          documents.push(e.data());
          return null;
        });
        riders.push({
          driver,
          documents,
        });
      }
    }
    return riders;
  }

  async uploadBlob  (blob)  {
    return await new Promise(async (r, eror)  =>{
      try {
        let bucketName = firebase_bucket;
        var filename = "adhakjd.pdf";
        var ext = filename[filename.length - 1];
        let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
        let upload = await storageRef.put(blob);
        const downloadURL = await upload.ref.getDownloadURL();
        r(downloadURL);
      } catch (error) {
        eror(error);
      }
    });
  }
}
