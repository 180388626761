import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Enter name"),
  description: yup.string().required("Enter description"),
  imageUrl: yup.string().required("Upload image"),
  passengers: yup.number().min(1, "Enter atleast 1 passenger's"),
  luggage: yup.number().min(0, "Enter correct luggage"),
  handLuggage: yup.number().min(0, "Enter correct hand luggage"),
  wheelChairs: yup.number().min(0, "Enter correct wheel chairs"),
  boosterSeats: yup.number().min(0, "Enter correct booster seats"),
  childSeats: yup.number().min(0, "Enter correct child seats"),
  infantSeats: yup.number().min(0, "Enter correct infant seats"),
});

export default schema;
