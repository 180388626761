import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DOMPurify from "dompurify";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button, DatePicker } from "antd";
import { getBlogById, updateBlog } from "../../redux/admin/action";

function EditBlog({ id, show, onHide }) {
  const [add, setAdd] = useState(false);
  const [blog, setBlog] = useState({
    title: "",
    shortDesc: "",
    featured: false,
    desc: EditorState.createEmpty(),
  });
  const [errors, setErrors] = useState([]);
  const changeInput = (e) => {
    const { name, value } = e.target;
    setBlog((p) => ({ ...p, [name]: value }));
  };
  const stopAdding = () => {
    setAdd(false);
    onHide();
  };
  const updateHandler = (e) => {
    e.preventDefault();
    let error = [];
    if (blog.title === "") {
      error.push("titleEmpty");
    }
    if (blog.desc === "") {
      error.push("descEmpty");
    }

    if (error.length) {
      setErrors(error);
    } else {
      setErrors([]);
      // setAdd(true);
      const html = `<div>${draftToHtml(
        convertToRaw(blog?.desc?.getCurrentContent())
      )}</div>`;
      // setAdd(true);
      dispatch(
        updateBlog(
          id,
          {
            title: blog.title,
            shortDesc: blog.shortDesc,
            featured: blog.featured,
            desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
            updateDate: moment().format("DD MMM YYYY"),
          },
          stopAdding
        )
      );
    }
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);

    const contentBlock = htmlToDraft(data.desc);

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    setBlog((p) => {
      return {
        ...p,
        ...data,
        desc: editorState,
      };
    });
  };
  useEffect(() => {
    if (id) {
      dispatch(getBlogById(id, stopLoader));
    }
  }, [id, dispatch]);
  if (loader) {
    return <div>loading....</div>;
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Blog</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {add && (
          <Alert
            variant="success"
            style={{ height: "3em" }}
            onClose={() => setAdd(false)}
            dismissible
          >
            <p>Blog updated!</p>
          </Alert>
        )}
        <Form onSubmit={updateHandler}>
          <Form.Group controlId="formBasicTitle">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="title"
              value={blog.title}
              onChange={changeInput}
            />
            {errors.includes("titleEmpty") && (
              <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
                Enter title
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicTitle">
              <Form.Label>Short description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                // type="text"
                placeholder="Short description"
                name="shortDesc"
                onChange={changeInput}
                value={blog.shortDesc}
              />
            </Form.Group>
          <Form.Group className="mt-3" controlId="formBasicDescription">
            <Form.Label>Blog:</Form.Label>
            <Editor
              editorState={blog.desc}
              name="desc"
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => {
                setBlog((p) => ({ ...p, desc: e }));
              }}
            />
            {errors.includes("descEmpty") && (
              <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
                Enter blog description
              </span>
            )}
          </Form.Group>
          <Form.Check 
            type="checkbox"
            id="checkbox"
            label="Featured Post"
            defaultChecked={blog.featured}
            onChange={(e) => {
              console.log("checked", e.target.checked)
              setBlog({...blog, featured: e.target.checked})
            }}
          />
          <Button
            size="large"
            className="mt-3 text-white"
            htmlType="submit"
            style={{
              background: "#FFB814",
            }}
          >
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditBlog;
