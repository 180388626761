import { Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { updateAdmin } from "../../redux/admin/action";

// import {
//   addNewTarrif,
//   getTarrif,
//   updateTarrif,
// } from "../../redux/admin/action";
// import addTarrif from "../../validations/addTarrif";

function Index({ show, onHide, adminData }) {
  const dispatch = useDispatch();
  // const { cities } = useSelector((state) => state.admin);

  // for saving tarrif
  // const addTarrifHandler = (values, helper) => {
  //   dispatch(
  //     addNewTarrif(values, () => {
  //       helper.setSubmitting(false);
  //       onHide();
  //     })
  //   );
  // };
  const updateAdminHandler = (values, helper) => {
    dispatch(
      updateAdmin(values, adminData?.id, () => {
        helper.setSubmitting(false);
        onHide();
      })
    );
  };
  const [loader] = useState(false);

  const submitHandle = (values, helper) => {
    updateAdminHandler(values, helper);
  };
  const [fetching, setFetching] = useState(false);

  // useEffect(() => {
  //   setFetching(true);
  //   dispatch(getTarrif(() => setFetching(false)));
  //   // dispatch(getVehicleTypes(() => setFetching(false)));
  // }, [dispatch]);
  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className=" fs-6">
          Edit Admin
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: adminData?.name || "",
            email: adminData?.email || "",
          }}
          // validationSchema={addTarrif}
          onSubmit={submitHandle}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form
              className=" mx-auto d-flex flex-column gap-2 "
              onSubmit={handleSubmit}
            >
              <div className="d-flex gap-3 ">
                <Form.Group className="w-full" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.name && touched.name && (
                    <span
                      className="w-100 text-danger text-start"
                      style={{ fontSize: "12px" }}
                    >
                      {errors.name}
                    </span>
                  )} */}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-full" controlId="formBasicName">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.name && touched.email && (
                    <span
                      className="w-100 text-danger text-start"
                      style={{ fontSize: "12px" }}
                    >
                      {errors.name}
                    </span>
                  )} */}
                </Form.Group>
              </div>

              <div className="d-flex">
                <Button
                  disabled={isSubmitting || loader}
                  variant="warning"
                  className="mt-3 fw-bolder text-white border-0"
                  type="submit"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
