import React, { createContext, useReducer } from "react";

import * as constants from "./constants";

const initialState = {
  riderLocation: null,
  clientLocation: null,
  mainDistance: null,
  restLocation: null,
  riderToRestaurantDistance: null,
  riderToUserDistance: null,
  riderDetail: null,
};

const MapContext = createContext(initialState);
const { Provider } = MapContext;

function MapProvider({ children }) {
  const [
    state, dispatch
  ] = useReducer((state, action) => {
    switch (action.type) {
    case constants.GET_RESTAURANT_LOCATION:
      return {
        ...state,
        restLocation: action.payload,
      };
    case constants.GET_RIDER_LOCATION:
      return {
        ...state,
        riderLocation: action.payload,
      };
    case constants.GET_RIDER_DETAIL:
      return {
        ...state,
        riderDetail: action.payload,
      };
    case constants.GET_USER_LOCATION:
      return {
        ...state,
        clientLocation: action.payload,
      };
    case constants.GET_MAIN_DISTANCE:
      return {
        ...state,
        mainDistance: action.payload,
      };
    case constants.GET_RIDER_TO_REST_DISTANCE:
      return {
        ...state,
        riderToRestaurantDistance: action.payload,
      };
    case constants.GET_RIDER_TO_USER_DISTANCE:
      return {
        ...state,
        riderToUserDistance: action.payload,
      };
    case constants.RESET_STATE:
      return {
        state: initialState,
      };
    default:
      return state;
    }
  }, initialState);
  const value = {
    state,
    dispatch,
  };

  return <Provider value={value}>
    {children}
  </Provider>;
}

export { MapContext, MapProvider };
