import "./index.css";

import { Button, Empty } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import RiderPaymentModal from "../../../modals/riderPaymentModal";
import { getRiderPayments } from "../../../redux/rider/action";
import PaymentRow from "./paymentRow";

function Index() {
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { profileRider } = useSelector((state) => state.admin);
  const { payments } = useSelector((state) => state.rider);

  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  useEffect(() => {
    setLoader(true);

    dispatch(getRiderPayments(stopLoader));
  }, [
    dispatch, profileRider
  ]);
  const stopLoader = () => {
    setLoader(false);
  };
  if (loader) {
    <div>
loading...
    </div>;
  }
  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">
Payment method
          </h1>
        </Col>
        <Col lg={12}
          className="flex justify-end">
          <Button type="primary"
            className="fw-bold"
            onClick={handleShow}>
            Add Payment
          </Button>
        </Col>
        <Col lg={12}>
          {payments?.length > 0 ? (
            <Row className=" gap-3  bg-white rounded-lg shadow-md p-0 ">
              <Col className="rider_mainColor rounded-t-lg"
                lg={12}>
                <h1 className="text-lg fw-bold text-white p-2">
Payments
                </h1>
              </Col>
              {payments?.map((payment, index) => (
                <PaymentRow key={index}
                  payment={payment} />
              ))}
            </Row>
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
      {show && <RiderPaymentModal show={show}
        onHide={handleClose} />}
    </Container>
  );
}

export default memo(Index);
