import React, { memo, useCallback } from "react";
import { FaTrashAlt } from "react-icons/fa";

function Index({ item, onChange, onDistanceFocus, onDelete }) {
  const { row, columns } = item;

  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      let newInitial = {
        ...row,
        [name]: value,
      };

      onChange?.(newInitial);
    },
    [
      onChange, row
    ]
  );

  return (
    <tr >
      {columns?.map((e) => {
        if (e.key === "distance") {
          return (
            <td key={e?.key}>
              <span>
                {row?.distance?.min}
              </span>
              <span className="mx-2">
-
              </span>
              <input
                onBlur={onDistanceFocus}
                name="max"
                type="number"
                value={row?.distance?.max}
                onChange={(c) => {
                  const { name, value } = c.target;
                  let newInitial = {
                    ...row,
                    distance: {
                      ...row?.distance,
                      [name]: value,
                    },
                  };
                  onChange(newInitial);
                }}
              />
            </td>
          );
        }
        if (e.key === "action") {
          return (
            <td key={e?.key}>
              <button type="button"
                onClick={() => onDelete(row.id)}>
                <FaTrashAlt />
              </button>
            </td>
          );
        }
        return (
          <td key={e?.key}>
            <input
              placeholder={e?.label}
              step={0.1}
              type="number"
              value={row[e?.key]}
              name={e?.key}
              onChange={onChangeHandler}
            />
          </td>
        );
      })}
    </tr>
  );
}

export default memo(Index);
