import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import dotenv from "dotenv";
import firebase from "firebase/compat/app";
import * as geofirex from "geofirex";
import { getStorage } from "firebase/storage";

dotenv.config();
const firebaseConfig = {
  apiKey: "AIzaSyBeb-2vr9YCHET-GQ1lvjIyH86Rvzs8YR0",
  authDomain: "wannabeedigital-37178.firebaseapp.com",
  databaseURL: "https://wannabeedigital-37178-default-rtdb.firebaseio.com",
  projectId: "wannabeedigital-37178",
  storageBucket: "wannabeedigital-37178.appspot.com",
  messagingSenderId: "68789334095",
  appId: "1:68789334095:web:8247c921811c330a373a3a",
  measurementId: "G-D0Z8BCJEKG",
};
const app = firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore;
const database = firebase.database;
const auth = firebase.auth;
const storage = getStorage(app);
const geo = geofirex.init(firebase);
export default firebase;

export { app, auth, database, firebase, firestore, geo, storage };
