import React, { memo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { completeRide } from "../../redux/admin/action";

function Index({ order, show, onHide }) {
  const [
    reason, setreason
  ] = useState("");
  const [
    errors, seterrors
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();

  const stopLoader = () => {
    setLoader(false);
  };
  const completedOrder = (e) => {
    e.preventDefault();

    let error = [];
    if (reason === "") {
      error.push("reasonEmpty");
    }

    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      if (window.confirm("Do you want to complete this ride??")) {
        dispatch(completeRide({...order, reason, status: "completed"},order.id, stopLoader));
        onHide()
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Mark As Complete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={completedOrder}>
          <Form.Group className="mt-3"
            controlId="formBasicemail">
            <Form.Label>
Reason:
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => {
                setreason(e.target.value);
              }}
            />
            {errors.includes("reasonEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Enter reason
              </span>
            )}
          </Form.Group>
          <Button
            variant="warning"
            className="fw-bolder text-white mt-3"
            disabled={loader}
            type="submit"
          >
            {loader ? "Processing..." : "Complete"}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          className="btn btn-warning fw-bolder text-white"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
