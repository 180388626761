import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <div className="flex">
        <p>
          DVLA Electronic Code :
          {" "}
          <span className="mx-1">
            {info?.DVLAElectronicCode?.DVLAElectronicCode?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
          | Expiry Date: 
          {" "}
          {info?.DVLAElectronicCode?.DVLAElectronicCodeExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          DVLA Plastic:
          {" "}
          <span className="mx-1">
            {info?.DVLAPlastic?.DVLAPlastic?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.DVLAPlastic?.DVLAPlasticExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Private Hire:
          {" "}
          <span className="mx-1">
            {info?.privateHire?.privateHire?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.privateHire?.privateHireExpiry}
        </p>
      </div>
    </div>
  );
}

export default memo(Index);
