import * as yup from "yup";

const schema = yup.object().shape({
  origin: yup.object().shape({
    address: yup.string().required("Enter address or pin from map"),
  }),
  destination: yup.object().shape({
    address: yup.string().required("Enter address or pin from map"),
  }),

  date: yup.string().required("Enter date"),
  time: yup.string().required("Enter time"),
  vehicleType: yup.string().required("Select vehicle type"),

  passenger: yup.object().shape({
    name: yup.string().required("Enter name"),
    phoneNumber: yup.string().required("Enter phone number"),
    email: yup.string().email("Enter valid Email"),
  }),
  driver: yup.array(),
  discount: yup.string(),
  noteForDriver: yup.string(),
  selectedBase: yup.string().required("Select base office"),

  noOfPass: yup.number().required("Enter no of passengers"),
  cost: yup.number(),
  noOfBags: yup.number(),
  noOfLuggage: yup.number(),
});

export default schema;
