import moment from "moment";
import queryString from "query-string";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";

import {
  getOrderbyRestaurant,
  getOrdersbyRider,
} from "../../redux/admin/action";
import Page404 from "../page404";
import Riders from "./riders";

function Index() {
  const { search } = useLocation();

  const dispatch = useDispatch();
  const { userId } = useParams();
  const [
    orders, setOrders
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const { to, from, as } = queryString.parse(search);

  const stopLoader = (data) => {
    setLoader(false);
    setOrders(data);
  };

  useEffect(() => {
    if (userId && from && to && as) {
      if (as === "restaurant") {
        setLoader(true);
        dispatch(
          getOrderbyRestaurant(
            userId,
            moment(from).startOf("d").format("x"),
            moment(to).endOf("d").format("x"),
            stopLoader
          )
        );
      } else {
        setLoader(true);
        dispatch(
          getOrdersbyRider(
            userId,
            moment(from).startOf("d").format("x"),
            moment(to).endOf("d").format("x"),
            stopLoader
          )
        );
      }
    }
  }, [
    userId, dispatch, from, to, as
  ]);
  if (loader) {
    return <div>
loading....
    </div>;
  }

  if (orders?.length && as === "rider") {
    return <Riders to={to}
      from={from}
      orders={orders} />;
  }
  return <Page404 />;
}
export default memo(Index);
