export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";

export const SET_TOKKEN = "SET_TOKKEN";
export const SITE_SETTING = "SITE_SETTING";

export const GET_INVOICES = "GET_INVOICES";
export const UPDATE_SITE_SETTINGS = "UPDATE_SITE_SETTINGS";
export const GET_TODAY_SHIFTS = "GET_TODAY_SHIFTS";
export const UPDATE_TODAY_SHIFT = "UPDATE_TODAY_SHIFT";
export const ADD_TODAY_SHIFT = "ADD_TODAY_SHIFT";
