import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

import LogoImg from "../../../assets/images/logo.png";
import { ukCurrancy } from "../../../utils/convertions";
import Ordertr from "./Ordertr";
import styles from "./styles";

export default function Index({ orders, invoiceNumber }) {
  const completedOrders = orders?.filter((e) => e.paid === true);
  const date = moment(new Date()).format("DD-MMM-YYYY");

  const total = completedOrders?.reduce((a, b) => {
    const riderFee = b.riderdetail.riderfee || 0;
    const ridertip = parseFloat(b.ridertip || 0);

    const deductions = b?.deduction?.from === "rider" ? b?.deduction?.fee : 0;
    const diliveryFee = b.deliveryfree || 0;
    const tot =
      parseFloat(riderFee) -
      parseFloat(deductions) +
      parseFloat(diliveryFee) +
      ridertip;
    return a + tot;
  }, 0);
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.pageTopHeadingView}>
          <Text style={styles.pageTopHeadingViewHeading}>
Statement
          </Text>
        </View>
        <View style={styles.topDescripView}>
          <View style={styles.restaurantView}>
            <Text style={styles.restaurantHeading}>
              {orders[0]?.riderdetail?.name}
            </Text>
            <Text>
              {orders[0]?.riderdetail?.email}
            </Text>
            <Text>
              {orders[0]?.riderdetail?.location?.address}
            </Text>
          </View>
          <View style={styles.siteView}>
            <View>
              <Image src={LogoImg}
                style={styles.siteLogo}
                alt="logo" />
              <Text style={ { width: 130, textAlign: "left", marginTop: 10 } }>
                Nvigo Taxis
              </Text>
              <Text style={ { width: 130, marginBottom: 5, flexWrap: "wrap" } }>
                Exeter Business Park, Emperor Way, Exeter EX1 3QS
              </Text>
              <View>
                <View style={styles.siteDesView}>
                  <Text>
Statement#:
                  </Text>
                  <Text>
                    {invoiceNumber}
                  </Text>
                </View>
                <View style={styles.siteDesView}>
                  <Text>
Statement date:
                  </Text>
                  <Text>
                    {date}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.statmentBox}>
          <View style={styles.statmentTotalView}>
            <Text style={styles.statmentTotalViewlabel}>
Statement total
            </Text>
            <Text style={styles.statmentTotalViewText}>
              {ukCurrancy.format(total)}
            </Text>
          </View>
          <Text style={styles.paymentSummaryText}>
Payment summary
          </Text>
        </View>
        <View style={styles.statmentTable}>
          <View style={styles.TableLableRow}>
            <Text style={styles.TableLable}>
Date
            </Text>
            <Text style={styles.TableLable}>
Order #
            </Text>
            <Text style={styles.TableLable}>
Delivery fee
            </Text>
            <Text style={styles.TableLable}>
Tips
            </Text>
            <Text style={styles.TableLable}>
Deductions
            </Text>
            <Text style={styles.TableLable}>
Total
            </Text>
          </View>
          {completedOrders.map((order, index) => (
            <Ordertr order={order}
              key={index} />
          ))}
        </View>
        <View style={ { padding: 10, position: "absolute", bottom: 0 } }>
          <Text>
            Please check and reconcile your statement. If you have any queries
            please contact us on partners.support@navigotaxis.com
          </Text>
          <Text>
Thank you for using NavigoTaxis.
          </Text>
        </View>
      </Page>
      <Page>
        <View>
          <Text style={styles.itemSummmaryText}>
Items summary
          </Text>
        </View>
        <View style={styles.statmentTable}>
          <View style={styles.TableLableRow}>
            <Text style={styles.TableLable}>
Date
            </Text>
            <Text style={styles.TableLable}>
Order #
            </Text>
            <Text style={styles.TableLable}>
Delivery fee
            </Text>
            <Text style={styles.TableLable}>
Tips
            </Text>
            <Text style={styles.TableLable}>
Deductions
            </Text>
            <Text style={styles.TableLable}>
Reason
            </Text>
            <Text style={styles.TableLable}>
Total
            </Text>
          </View>
          {orders.map((order, index) => (
            <Ordertr showDed
              order={order}
              key={index} />
          ))}
        </View>
      </Page>
    </Document>
  );
}
