export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";
export const GET_ORDERS = "GET_ORDERS";
export const SET_TOKKEN = "SET_TOKKEN";
export const SITE_SETTING = "SITE_SETTING";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_CART = "REMOVE_CART";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_INVOICES = "GET_INVOICES";
export const UPDATE_SITE_SETTINGS = "UPDATE_SITE_SETTINGS";
