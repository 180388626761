/* eslint-disable func-names */
import * as yup from "yup";

import { RIDER_COLLECTION } from "../config";
import { firestore } from "../config/firebase";
import AdminApi from "../redux/admin/adminApi";

const adminApi = new AdminApi();
yup.addMethod(yup.string, "emailisAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value) return;

    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .where("email", "==", value);
    const doc = await userRef.get();
    if (!doc.empty) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});
yup.addMethod(yup.string, "onlyOneBusName", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;
    if (!value) return;
    const data = await adminApi.getRiderBySlug(
      value?.replace(" ", "-")?.toLowerCase()
    );
    if (data) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Enter name")
    .onlyOneBusName("Rider name must be unique"),

  email: yup
    .string()
    .required("Enter email")
    .email("Enter valid email address")
    .emailisAlready("email already registered"),
});

export default schema;
