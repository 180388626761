import React, { memo } from "react";

function Index({ number, item }) {
  const variantNames =
    item?.varients?.map((e) => `${e?.variant.optionName} ${e.option?.name}`) ||
    [];

  const optionsNames =
    item?.extras?.map(
      (e) => `${e?.extra?.map((a) => `${a?.name} ${e?.category?.name}`)}`
    ) || [];
  return (
    <tr className="font-weight-bold"
      style={ { fontSize: "1.2em" } }>
      <td className=" capitalize">
        {number}
      </td>
      <td className=" capitalize">
        {item?.qty}
      </td>
      <td className=" capitalize">
        {item?.category?.name}
      </td>
      <td className=" capitalize">
        {item?.name}
      </td>
      <td className=" ">
        {variantNames.join(", ") || "No Variant`s"}
      </td>
      <td className=" capitalize">
        {optionsNames.join(", ") || "No extras found"}
      </td>
    </tr>
  );
}

export default memo(Index);
