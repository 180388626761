import { DeleteOutlined } from "@ant-design/icons";
import { Edit } from "@mui/icons-material";
import { Input,Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import LiveItemsbox from "../../components/LiveItemsbox";
import AsCancel from "../../modals/CancelOrder";
import AsComplete from "../../modals/CompleteOrder";
import OrderItem from "../../modals/OrderItem";
import { deleteRide,getRides } from "../../redux/admin/action";

const { Search } = Input;

function Index() {
  const [
    modalShow, setModalShow
  ] = useState(false);
  const [items] = useState(null);
  const [
    modalCompShow, setmodalCompShow
  ] = useState(false);
  const [
    modalCancShow, setmodalCancShow
  ] = useState(false);
  const [order] = useState(null);
  const [
    searchedText, setSearchedText
  ] = useState("");

  const deleteRideRecord = (ride) => (cb) => {
    dispatch(deleteRide(ride.id, cb));
  };

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 150,
      render: (status) => {
        if (status === "completed") {
          return (
            <Tag color="green"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "cancelled") {
          return (
            <Tag color="red"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "pending") {
          return (
            <Tag color="yellow"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "driver assigned") {
          return (
            <Tag color="gold"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else {
          return <p>
            {status}
          </p>;
        }
      },
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Ride Date",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (_, record) => record.date,
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    {
      title: "Rider",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 220,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.vehicleType)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.driver).toLowerCase().includes(value.toLowerCase()) ||
          String(record.origin?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.destination?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.orderNumber)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id.toString().split("-")[0])
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (name) => {
        return `${name}`;
      },
    },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (destination) => destination?.address,
    },
    {
      title: "Phone No",
      align: "center",
      dataIndex: "phoneNo",
      key: "phoenNo",
      width: 220,
      render: (_, record) => {
        return record.phone;
      },
    },
    {
      title: "Driver",
      align: "center",
      dataIndex: "driver",
      key: "driver",
      width: 220,
      render: (driver) => {
        return Array.isArray(driver) ? driver[0] : driver;
      },
    },
    {
      title: "Type of vehicle",
      align: "center",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 120,
      className: "capitalize",
    },
    {
      title: "No of Bags",
      align: "center",
      dataIndex: "noOfBags",
      key: "noOfBags",
      width: 220,
      render: (noOfBags) => {
        return noOfBags;
      },
    },
    {
      title: "No of Luggages",
      align: "center",
      dataIndex: "noOfLuggage",
      key: "noOfLuggage",
      width: 220,
      render: (noOfLuggage) => {
        return noOfLuggage;
      },
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (email) => {
        return email;
      },
    },
    {
      title: "No of Passengers",
      align: "center",
      dataIndex: "noOfPass",
      key: "noOfPass",
      width: 220,
      render: (noOfPass) => {
        return noOfPass;
      },
    },
    {
      title: "Other charges",
      align: "center",
      dataIndex: "paid",
      key: "paid",
      width: 120,
      className: "capitalize",
      render: (e) => (e ? "Paid" : "Pending"),
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        <>
          {" "}
          &pound;
          {record.estimatedCost || record.cost
            ? record.estimatedCost
              ? record.estimatedCost
              : record.cost
            : 0}
        </>
      ),
    },
    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <div className="d-flex flex-col align-items-center justify-content-center">
            <Edit />
            <ActionBtn
              action={deleteRideRecord(record)}
              confirmMsg={"Do you want to delete this ride?"}
              
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>

          </div>
        );
      },
    },
  ];

  const [
    loader, setLoader
  ] = useState(false);
  const [
    finalRides, setFinalRides
  ] = useState([]);
  const dispatch = useDispatch();

  const stopLoader = () => {
    setLoader(false);
  };

  const { rides } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getRides(stopLoader));
  }, [dispatch]);

  useEffect(() => {
    if (rides) {
      const filteredRides = rides?.filter((ride) => {
        return ride.date > moment().format("DD MMM YYYY");
      });

      let finalSortedRides = filteredRides?.sort((a, b) => {
        if (
          moment(a.date, "DD MMM YYYY").isSame(moment(b.date, "DD MMM YYYY"))
        ) {
          return moment(a.time, "HH:mm") - moment(b.time, "HH:mm");
        } else if (
          moment(a.date, "DD MMM YYYY") > moment(b.date, "DD MMM YYYY")
        ) {
          return 1;
        } else {
          return -1;
        }
      });
      setFinalRides(finalSortedRides);
    }
  }, [rides]);
  return (
    <>
      <Container fluid
        className=" w-100  pb-5">
        <Row className="gap-3">
          <Col lg={12}
            className=" pl-0 ">
            <h2 className="fw-bold">
Pre Bookings
            </h2>
          </Col>
          <LiveItemsbox
            lg={4}
            headerClass="admin_mainColor"
            title={"Pre book rides"}
            renderData={(data) => {
              return <div>
                {data || "Empty"}
              </div>;
            }}
          />
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3">
            <Search
              placeholder="Search rides..."
              allowClear
              size="large"
              onSearch={(value) => setSearchedText(value)}
              onChange={(e) => setSearchedText(e.target.value)}
              style={ {
                width: 300,
                marginBottom: "10px",
                float: "right",
              } }
              className="searchBar"
            />
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={finalRides}
              pagination={false}
              loading={loader}
              scroll={ { x: 1500, y: 500 } }
            />
          </Col>
        </Row>
      </Container>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {modalCompShow && (
        <AsComplete
          order={order}
          show={modalCompShow}
          onHide={() => setmodalCompShow(false)}
        />
      )}
      {modalCancShow && (
        <AsCancel
          order={order}
          show={modalCancShow}
          onHide={() => setmodalCancShow(false)}
        />
      )}
    </>
  );
}

export default memo(Index);
