import "./index.css";

import { Layout, Menu } from "antd";
import React, { Fragment, memo } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

import LogoImg from "../../assets/images/logo.png";
import adminNavigation from "../../navigation/adminData";
import riderNavigation from "../../navigation/riderData";
import userNavigation from "../../navigation/userData";
import { profileNavigation } from "../../utils/viewPages";

const { Sider } = Layout;

function Index({ collapsed }) {
  const location = useLocation();

  const profile = location?.pathname?.split("/")[1];

  const { profileRider, profileUser, profileAdmin } = useSelector(
    (state) => state.admin
  );

  const finalRoute = profile
    ? profile === "user"
      ? profileNavigation("customerTabs", userNavigation)
      : profile === "admin"
        ? profileNavigation("adminTabs", adminNavigation)
        : profileNavigation("riderTabs", riderNavigation)
    : [];

  const id = profile
    ? profile === "user"
      ? profileUser?.id
      : profile === "admin"
        ? profileAdmin?.id
        : profileRider?.id
    : null;

  const menuLinks = finalRoute.map((value) => {
    const Icon = value?.icon || Fragment;
    if (value?.Children) {
      return (
        <Menu.SubMenu key={value?.url}
          icon={<Icon />}
          title={value.text}>
          {value.Children.map((item) => {
            const Icon = item?.icon || Fragment;
            const urls = profile + "/" + id + item?.url;
            return (
              <Menu.Item icon={<Icon />}
                key={`/${urls}`}>
                <NavLink to={`/${urls}`}>
                  {item?.text}
                </NavLink>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    }

    const url = profile + "/" + id + value?.url;

    return (
      <Menu.Item key={`/${url}`}
        icon={<Icon />}>
        <NavLink to={`/${url}`}>
          {value?.text}
        </NavLink>
      </Menu.Item>
    );
  });

  return (
    <Sider
      className="shadow  sidar-fixed"
      trigger={null}
      collapsible
      theme="dark"
      style={ { backgroundColor: "black" } }
      collapsed={collapsed}
    >
      <div className="py-3 px-2 w-full d-flex align-items-center justify-content-center">
        <Link to="/">
          <img
            src={LogoImg}
            alt="logo"
            style={ { width: "100%", height: "100%" } }
          />
        </Link>
      </div>
      <PerfectScrollBar>
        <Menu
          theme="dark"
          mode="inline"
          className="maindiv"
          defaultOpenKeys={[location?.pathname]}
          defaultSelectedKeys={[location?.pathname]}
        >
          {menuLinks}
        </Menu>
      </PerfectScrollBar>
    </Sider>
  );
}

export default memo(Index);
