export function getState(addressArray) {
  let state = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        state = addressArray[i].long_name;
        return state || null;
      }
    }
  }
}
export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export function getcountry(addressArray) {
  let state = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        state = addressArray[i].long_name;
        return state || null;
      }
    }
  }
}
export function getArea(addressArray) {
  let area = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j] ||
          "postal_town" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area || null;
        }
      }
    }
  }
}
export function getCity(addressArray) {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "postal_town" === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city || null;
    }
  }
}

export function getRoad(addressArray) {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
      city = addressArray[i].long_name;
      return city || null;
    }
  }
}
export function getPostCode(addressArray) {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "postal_code" === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city || null;
    }
  }
}
export function getAddress(data) {
  const address = data.formatted_address,
    addressArray = data.address_components,
    city = getCity(addressArray) || getArea(addressArray),
    area = getArea(addressArray),
    county = getState(addressArray),
    postCode = getPostCode(addressArray),
    country = getcountry(addressArray),
    road = getRoad(addressArray),
    latValue =
      typeof data.geometry.location.lat !== "function"
        ? data.geometry.location.lat
        : data.geometry.location.lat(),
    lngValue =
      typeof data.geometry.location.lng !== "function"
        ? data.geometry.location.lng
        : data.geometry.location.lng();

  return {
    address: address || null,
    city: city || null,
    area: area || null,
    county: county || null,
    country: country || null,
    latValue: latValue || null,
    lngValue: lngValue || null,
    postCode: postCode || null,
    road: road || null,
  };
}
