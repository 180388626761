import "./index.css";

import {
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Affix, Badge, Dropdown, Layout, Modal, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { FaEllipsisH, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Pulse from "react-reveal/Pulse";
import RubberBand from "react-reveal/RubberBand";

import ChatSupport from "../../components/chatSupport";
import Sidebar from "../../components/sidebar";
import { getAllChatSessions } from "../../redux/admin/action";
import { Logout } from "../../redux/auth/action";

const { Header, Content } = Layout;

function Index({ children }) {
  const [
    collapsed, setCollapsed
  ] = useState(false);
  const onToggle = () => {
    setCollapsed(!collapsed);
  };

  const { roles } = useSelector((state) => state.admin);
  const [
    showChat, setShowChat
  ] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { photoURL } = user;
  const [
    loader, setLoader
  ] = useState(false);
  const [
    sessions, setSessions
  ] = React.useState([]);

  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();
  const role = roles.find((e) => e.id === user.role);
  function confirm() {
    Modal.confirm({
      title: "Logout",
      icon: <LogoutOutlined />,
      content: "Want to Logout?",
      onOk: () => {
        dispatch(Logout(stopLoader));
      },
      okText: loader ? "Waiting..." : "Logout",
      cancelText: "Cancel",
    });
  }

  useEffect(() => {
    dispatch(
      getAllChatSessions((data) => {
        setSessions(data);
      })
    );
  }, [dispatch]);

  const menu = (
    <div className="bg-white shadow-md w-80 p-2">
      <p className="text-center text-sm font-bold border-b m-0">
        {user.name}
      </p>
      <div className="flex flex-col custom-menu border-b flex-wrap ">
        <div className="flex items-center justify-center custom-menu-item px-2 my-1">
          <MailOutlined className="text-md " />
          <button
            href="/#"
            className="text-sm text-start p-1 font-bold break-words "
          >
            {user.email}
          </button>
        </div>
        {/* <div className="flex items-center px-2 custom-menu-item my-1">
          <ContactsOutlined className="text-md " />
          <button href="/#" className=" text-sm text-start p-1 font-bold ">
            Contact us
          </button>
        </div> */}
      </div>
      <div className="flex items-center justify-center px-2 custom-menu-item my-1">
        <LogoutOutlined className="text-md " />
        <button
          onClick={confirm}
          href="/#"
          className=" text-sm text-start p-1 font-bold "
        >
          Logout
        </button>
      </div>
    </div>
  );

  return (
    <Layout
      className="site-layout-background shadow customlayout"
      style={{ minHeight: "100vh" }}
    >
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Header
          className=" shadow-md flex flex-row items-center justify-between px-4"
          style={{ padding: 0 }}
        >
          <div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "text-lg text-white",
                onClick: onToggle,
              }
            )}
          </div>
          <Dropdown
            overlay={menu}
            className="w-12 cursor-pointer  h-12  shadow-md blue-icon rounded-full flex items-center justify-center text-4xl font-bold text-white"
            trigger={["click"]}
          >
            <div>
              {photoURL ? (
                <img
                  src={photoURL}
                  style={{ width: "100%", height: "100%" }}
                  alt="profile"
                  className="rounded-full"
                />
              ) : (
                user?.name?.charAt(0)
              )}
            </div>
          </Dropdown>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: "24px 16px",
            overflow: "auto",
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
      {(role?.chatSupport || user?.role === "ROOT") && (
        <>
          <Affix
            offsetBottom={5}
            className=" flex justify-end  fixed bottom-0 right-10    "
          >
            <Badge count={sessions?.filter((e) => !e?.startBy)?.length || 0}>
              {showChat ? (
                <Tooltip title="Close"
                  placement="left">
                  <div className={" chatIconBottom shadow-md    "}>
                    <Pulse>
                      <FaTimes onClick={() => setShowChat(false)} />
                    </Pulse>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Live Chat Support"
                  placement="left">
                  <div className={" chatIconBottom shadow-md    "}>
                    <RubberBand>
                      <FaEllipsisH onClick={() => setShowChat(true)} />
                    </RubberBand>
                  </div>
                </Tooltip>
              )}
            </Badge>
          </Affix>
          {showChat && (
            <Pulse>
              <div
                className=" transition-all shadow-md "
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  bottom: 80,
                  right: 0,
                  margin: 30,
                  opacity: showChat ? 1 : 0,
                }}
              >
                <ChatSupport sessions={sessions} />
              </div>
            </Pulse>
          )}
        </>
      )}
    </Layout>
  );
}
export default memo(Index);
