import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLiveOrders,
  getOrderHistory,
  getRestaurants,
  getUnboundRiders,
} from "../../redux/admin/action";
import DashboardRoutes from "../../routers/DashboardRoutes";

function Index() {
  const { renderRoutes } = DashboardRoutes;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getUnboundRiders());
      dispatch(getOrderHistory(() => {}));
      dispatch(getRestaurants(() => {}));
      dispatch(getLiveOrders(() => {}));
    }
  }, [
    user, dispatch
  ]);

  return renderRoutes();
}
export default memo(Index);
