import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import { saveInvoice } from "../../../redux/admin/action";
import toTimestamp from "../../../utils/convertions";
import { makeId } from "../../../utils/gerenatePassword";
import Document from "./document";

function Index({ orders, to, from }) {
  const dispatch = useDispatch();
  const invoiceNumber = useMemo(() => makeId(6), []);
  const uploaddata = (e, blob) => {
    e.preventDefault();
    if (window.confirm("Want to save invoice for rider?")) {
      setLoader(true);
      const data = {
        to: toTimestamp(to),
        from: toTimestamp(from),
        userId: orders[0].riderdetail.id,
        invoiceNumber,
      };
      const completedOrders = orders?.filter((e) => e.paid === true);

      dispatch(
        saveInvoice(blob, data, completedOrders, "riderPaid", stopLoader)
      );
    }
  };
  const stopLoader = (isGerenated) => {
    setLoader(false);
    if (isGerenated) {
      swal("Dear admin!", "Invoice saved successfully", "success");
    } else {
      swal("Dear admin!", "Invoice already saved", "error");
    }
  };
  const [
    loader, setLoader
  ] = useState(false);
  return (
    <div>
      <div style={ { position: "relative " } }
        className="p-2 ">
        <PDFDownloadLink
          document={<Document orders={orders}
            invoiceNumber={invoiceNumber} />}
          fileName={`Rider(${from}/${to}).pdf`}
          className="btn btn-warning fw-bolder text-white"
        >
          {({ blob, loading }) => (
            <>
              {loading ? "loading.." : "Download"}
              <button
                disabled={loader}
                className="btn btn-warning fw-bolder text-white"
                style={ {
                  position: "absolute",
                  right: 5,
                  top: 5,
                  zIndex: 34573485734,
                } }
                onClick={(e) => uploaddata(e, blob)}
              >
                {loader ? "Saving..." : "Save"}
              </button>
            </>
          )}
        </PDFDownloadLink>
      </div>
      <PDFViewer style={ { height: "100vh", width: "100vw" } }>
        <Document orders={orders}
          invoiceNumber={invoiceNumber} />
      </PDFViewer>
    </div>
  );
}

export default Index;
