/* eslint-disable no-console */
import { v4 as uuidv4 } from "uuid";

import { showErrorToast } from "../../components/toaster";
import { addCreateHistory, addUpdateHistory } from "../../utils/convertions";
import AuthApi from "./authApi";
import * as constants from "./constants";

const authApi = new AuthApi();

export const login = (data, cb) => async (dispatch) => {
  try {
    const res = await authApi.loginWithemailAndPassword(data);

    const { token, claims } = await res?.user?.getIdTokenResult(true);
// console.log("token", token);
// console.log("claims", claims);
// console.log("uid", res.user?.uid);

    const user = await authApi.getAdminById(res.user?.uid);
    // console.log("user", user);
    if (!user) {
      // console.log("if running");
      await authApi.Logout();
      cb();
      return;
    }

    dispatch({
      type: constants.SET_USER,
      payload: {
        ...user,
        admin: claims?.admin,
        role: claims?.role,
      },
    });
    dispatch({
      type: constants.SET_TOKKEN,
      payload: token,
    });

    cb?.(true);
  } catch (error) {
    // showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};
export const getSiteSetting = (cb) => async (dispatch) => {
  try {
    const settings = await authApi.getSiteSetting();

    const site = {};

    settings?.forEach((e) => {
      site[e?.id] = { ...e };
    });

    dispatch({ type: constants.SITE_SETTING, payload: site });

    cb?.();
  } catch (error) {
    console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};

export const updateShift =
  (data, shiftId, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;
    try {
      const updatedShift = await authApi.updateShift(
        addUpdateHistory(data, user),
        shiftId,
        user.id
      );
      dispatch({ type: constants.UPDATE_TODAY_SHIFT, payload: updatedShift });
      cb?.();
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const updateShiftByAdmin =
  (data, shiftId, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;
    const id = getState().admin.profileAdmin.id;

    try {
      const updatedShift = await authApi.updateShift(
        addUpdateHistory(data, user),
        shiftId,
        id
      );

      cb?.(updatedShift);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };
export const AddShift = (data, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;
  const id = uuidv4();
  try {
    const updatedShift = await authApi.addShift(
      addCreateHistory(data, user),
      id,
      user.id
    );
    dispatch({ type: constants.ADD_TODAY_SHIFT, payload: updatedShift });
    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const AddShiftByAdmin = (data, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;
  const profileAdmin = getState().admin.profileAdmin;

  const id = uuidv4();
  try {
    const updatedShift = await authApi.addShift(
      addCreateHistory(data, user),
      id,
      profileAdmin.id
    );

    cb?.(updatedShift);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

export const getTodayAdminShifts =
  ({ start, end }, cb) =>
    async (dispatch, getState) => {
      const user = getState().auth.user;
      try {
        const updatedShift = await authApi.getTodayAdminShifts(
          start,
          end,
          user.id
        );
        dispatch({ type: constants.GET_TODAY_SHIFTS, payload: updatedShift });
        cb?.();
      } catch (error) {
        console.log(error);
        cb?.();
        showErrorToast(error.message || error?.response?.message);
      }
    };

export const updateSiteSettings =
  (doc, data, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;

    try {
      const isUpdated = await authApi.updateSiteSettings(
        doc,
        addUpdateHistory(data, user)
      );
      if (isUpdated) {
        dispatch({
          type: constants.UPDATE_SITE_SETTINGS,
          payload: { doc, data },
        });
      }
      cb?.();
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };
export const updateSiteSettingsSchedule =
  (doc, data, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;

    try {
      const isUpdated = await authApi.updateSiteSettingsSchedule(
        doc,
        addUpdateHistory(data, user)
      );
      if (isUpdated) {
        dispatch({
          type: constants.UPDATE_SITE_SETTINGS,
          payload: { doc, data },
        });
      }
      cb?.();
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const getCurrentUser = (cb) => async (dispatch) => {
  try {
    const auth = await authApi.getCurrentUser();
    auth.onAuthStateChanged(async (currentUser) => {
      try {
        if (!currentUser) {
          cb();
          return;
        }
        const { token, claims } = await currentUser.getIdTokenResult(true);
        const user = await authApi.getAdminById(currentUser?.uid);
        if (!claims?.admin || !user) {
          await authApi.Logout();
          cb();
          return;
        }

        dispatch({
          type: constants.SET_USER,
          payload: {
            ...user,
            admin: claims?.admin,
            role: claims?.role,
          },
        });

        dispatch({
          type: constants.SET_TOKKEN,
          payload: token,
        });

        cb?.(user);
      } catch (error) {
        cb?.();
      }
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const Logout = (cb) => async (dispatch) => {
  try {
    const isLogout = await authApi.Logout();
    if (isLogout) {
      dispatch({ type: constants.LOGOUT });
    }
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};

export const getInvoicesByRiderId = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;
  try {
    const invoices = await authApi.getInvoicesByUserId(id);

    dispatch({ type: constants.GET_INVOICES, payload: invoices });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const forgetPassword = (email, cb) => async () => {
  try {
    await authApi.forgetPassword(email);

    cb?.(true);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};

export const updateUserPassword =
  ({ oldPassword, newPassword, email }, cb) =>
    async () => {
      try {
        const isupdated = await authApi.updatePassword(
          email,
          oldPassword,
          newPassword
        );
        if (isupdated) {
          cb(isupdated);
        }
      } catch (error) {
      // showErrorToast(error.message || error?.response?.message);
        cb?.(false, error.code);
      }
    };

export const emailSender = (data, cb) => async (dispatch, getState) => {
  try {
    const { name, email } = getState().auth.user;
    const res = await authApi.sendSupportemail({ ...data, name, email });

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
