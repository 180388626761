import { UserOutlined } from "@ant-design/icons";
import { Avatar,Spin  } from "antd";
import React, { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  removeAdminProfileImage,
  removeRiderProfileImage,
  removeUserProfileImage,
  updateAdminProfileImage,
  updateRiderProfileImage,
  updateUserProfileImage,
} from "../../redux/admin/action";

function Index({ user }) {
  const [
    Loader, setLoader
  ] = useState(false);
  const dispatch = useDispatch();

  const updateImage = (e) => {
    setLoader(true);
    if (user?.admin) {
      dispatch(updateAdminProfileImage(e.target.files, stopLoader));
      return;
    }
    if (user?.role === "driver") {
      dispatch(updateRiderProfileImage(e.target.files, stopLoader));
      return;
    }
    dispatch(updateUserProfileImage(e.target.files, stopLoader));
  };
  const stopLoader = () => {
    setLoader(false);
  };

  const removeImage = () => {
    setLoader(true);
    if (user?.admin) {
      dispatch(removeAdminProfileImage(stopLoader));
      return;
    }
    if (user?.role === "driver") {
      dispatch(removeRiderProfileImage(stopLoader));
      return;
    }
    dispatch(removeUserProfileImage(stopLoader));
  };

  return (
    <Col lg={6}>
      <Row className=" flex justify-center">
        <Col lg={6}>
          <Row>
            <Col lg={3}
              className=" flex justify-center">
              <Spin spinning={Loader}>
                <Avatar
                  size={100}
                  src={user?.photoURL || user?.profileImage}
                  icon={<UserOutlined />}
                />
              </Spin>
            </Col>
            <Col className="d-flex flex-col items-center justify-center ">
              <div>
                <input
                  type="file"
                  id="profileimg"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => updateImage(e)}
                />
                <label
                  htmlFor="profileimg"
                  style={ { cursor: "pointer", fontWeight: "bold" } }
                >
                  Upload Image
                </label>
              </div>
              <button
                className=" p-0"
                style={ { cursor: "pointer", fontWeight: "bold" } }
                onClick={() => removeImage()}
              >
                Remove Image
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
export default memo(Index);
