import "./index.css";

import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FiArrowUp } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { IMAGE_SIZE } from "../../config";
import { uploadFiles } from "../../redux/admin/action";
import { formatBytes } from "../../utils/convertions";

function Index({ file, onSuccess, title, subtitle, changeAble }) {
  const [
    uploading, setuploading
  ] = useState(false);

  const dispatch = useDispatch();

  const stopLoader = (files) => {
    onSuccess?.(files);

    setuploading(false);
  };
  const [
    errors, setErrors
  ] = useState([]);

  var uploadimg = async (acceptedFiles) => {
    setErrors([]);
    if (
      acceptedFiles?.every((e) => {
        return e.size > IMAGE_SIZE ? true : false;
      })
    ) {
      setErrors([
        ...errors, { size: true }
      ]);
      return;
    }
    setuploading(true);

    dispatch(uploadFiles(acceptedFiles, stopLoader));
  };
  const Images = file?.map((e) => `url(${e})`);
  return (
    <>
      <Col lg={12}>
        <div className="imagebox-desc  w-100">
          {title && <h1 className="w-100 text-left">
            {title}
          </h1>}
          {subtitle && <p>
            {subtitle}
          </p>}
        </div>
      </Col>
      <Col lg={12}
        className="">
        <Dropzone onDrop={(acceptedFiles) => uploadimg(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div className="DropimageboxMain"
              {...getRootProps()}>
              {changeAble ? (
                <input
                  {...getInputProps()}
                  accept="image/x-png,image/gif,image/jpeg"
                  multiple={true}
                  disabled={false}
                />
              ) : (
                <>
                  {file.length > 0 && (
                    <input
                      {...getInputProps()}
                      accept="image/x-png,image/gif,image/jpeg"
                      multiple={true}
                      disabled={true}
                    />
                  )}
                  {!file.length > 0 && (
                    <input
                      {...getInputProps()}
                      accept="image/x-png,image/gif,image/jpeg"
                      multiple={true}
                      disabled={false}
                    />
                  )}
                </>
              )}
              {file.length > 0 && (
                <div
                  className="Dropimagebox"
                  style={ {
                    backgroundImage: Images?.join(","),
                    color: "black",
                    backgroundSize: "cover",

                    backgroundRepeat: "no-repeat",
                  } }
                >
                  <div className="Dropimagebox-icon d-flex justify-content-center align-items-center">
                    <FiArrowUp />
                  </div>
                  <div className="text-dark mt-3">
                    <p style={ { color: "white" } }>
                      Drag and drop or
                      {" "}
                      <span style={ { color: "#f6b414", cursor: "pointer" } }>
                        choose file
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {!file.length > 0 && (
                <div className="Dropimagebox">
                  <div className="Dropimagebox-icon d-flex justify-content-center align-items-center">
                    <FiArrowUp />
                  </div>
                  <div className="text-dark mt-3">
                    <p>
                      Drag and drop or
                      {" "}
                      <span style={ { color: "#f6b414", cursor: "pointer" } }>
                        choose file
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
        <>
          {errors?.map((error, index) => {
            return (
              <Col lg={12}
                className="w-full"
                key={index}>
                {error.size && (
                  <p className="text-danger w-full">
                    size should be less than 
                    {" "}
                    {formatBytes(IMAGE_SIZE, 2)}
                  </p>
                )}
              </Col>
            );
          })}
        </>
        {uploading && (
          <div className="bg-warning text-white w-auto d-inline-block p-1 mt-2 border-1 rounded-4">
            Upload...
          </div>
        )}
      </Col>
    </>
  );
}

export default memo(Index);
