import "./index.css";

import { ErrorMessage, Formik } from "formik";
import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { addRiderDetail, updateRiderDetail } from "../../redux/rider/action";
import vehicleRequirementsValidation from "../../validations/vehicleRequirements";

const mediumSuitcases = [
  {
    label: "1 Large Suitcase",
    value: "1-large",
  },
  {
    label: "2 Large Suitcases",
    value: "2-large",
  },
  {
    label: "3 Large Suitcases",
    value: "3-large",
  },
  {
    label: "4 Large Suitcases",
    value: "4-large",
  },
  {
    label: "1 Medium Suitcases",
    value: "1-medium",
  },
  {
    label: "2 Medium Suitcases",
    value: "2-medium",
  },
  {
    label: "3 Medium Suitcases",
    value: "3-medium",
  },
  {
    label: "4 Medium Suitcases",
    value: "4-medium",
  },
  {
    label: "5 Medium Suitcases",
    value: "5-medium",
  },
];
function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const formikRef = useRef(null);

  const stopLoader = (formikHelper) => (data) => {
    if (data) {
      if (as === "NEW") {
        onAdd(data);
      } else {
        onUpdate(data);
      }
      onHide?.();
    }
    formikHelper.setSubmitting(false);
  };
  const dispatch = useDispatch();
  const submitHandler = (values, formikHelper) => {
    if (as === "NEW") {
      dispatch(
        addRiderDetail("vehicle_Requirments", values, stopLoader(formikHelper))
      );
      return;
    }
    dispatch(updateRiderDetail(info.id, values, stopLoader(formikHelper)));
  };
  const [
    initialValues, setInitialValues
  ] = useState({
    carName: "",
    carModel: "",
    allowPassengers: 0,
    mediumSizeSuitcases: "",
    foldAbleSeat: false,
    suitcasesWithFoldableSeat: "",
    registrationNumber: "",
  });
  useEffect(() => {
    if (info) {
      setInitialValues((p) => {
        return {
          ...p,
          ...info,
        };
      });
    }
  }, [info]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Vehicle Requirements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={vehicleRequirementsValidation}
          onSubmit={submitHandler}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => (
            <Form
              className="d-flex flex-column justify-content-start align-items-start gap-3"
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="carName">
                <Form.Label>
1. What is the make of your car?
                </Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter car name"
                  style={ { width: 400 } }
                  name="carName"
                  value={values.carName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="carName"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              <Form.Group controlId="carModel">
                <Form.Label>
2. What is the model of your car?
                </Form.Label>
                <FormControl
                  type="text"
                  style={ { width: 400 } }
                  placeholder="Enter car model"
                  name="carModel"
                  value={values.carModel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="carModel"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              <Form.Group controlId="carModel">
                <Form.Label>
3. Vehicle registration number?
                </Form.Label>
                <FormControl
                  type="text"
                  style={ { width: 400 } }
                  placeholder="Enter registration number"
                  name="registrationNumber"
                  value={values.registrationNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="registrationNumber"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
4. How many passenger seats?
                </Form.Label>
                <Form.Select
                  placeholder="Select number of passangers"
                  name="allowPassengers"
                  value={values.allowPassengers}
                  style={ { width: 400 } }
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={0}>
Please select one option
                  </option>
                  {[
                    4, 5, 6, 7, 8, 9, 10
                  ].map((item) => (
                    <option key={item}
                      value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
                <ErrorMessage
                  name="allowPassengers"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
                  5. How many suitcases can you accommodate in you boot space?
                </Form.Label>
                <Form.Select
                  style={ { width: 400 } }
                  placeholder="Select number of medium sized suitcases"
                  name="mediumSizeSuitcases"
                  value={values.mediumSizeSuitcases}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={""}>
Please select one option
                  </option>
                  {mediumSuitcases.map((item) => (
                    <option key={item.value}
                      value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                <ErrorMessage
                  name="mediumSizeSuitcases"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
                  5. Can you fold the seats to accommodate luggage in your car?
                </Form.Label>
                <Form.Check
                  type="radio"
                  style={ { width: 400 } }
                  label="Yes"
                  name="foldAbleSeat"
                  checked={values.foldAbleSeat}
                  onChange={() => {
                    setFieldValue("foldAbleSeat", true);
                  }}
                  onBlur={() => {
                    setFieldValue("foldAbleSeat", true);
                  }}
                />
                <Form.Check
                  type="radio"
                  style={ { width: 400 } }
                  label="No"
                  name="foldAbleSeat"
                  checked={!values.foldAbleSeat}
                  onChange={() => {
                    setFieldValue("foldAbleSeat", false);
                  }}
                  onBlur={() => {
                    setFieldValue("foldAbleSeat", false);
                  }}
                />
                <ErrorMessage
                  name="foldAbleSeat"
                  component="div"
                  className="error-message"
                />
              </Form.Group>
              {values.foldAbleSeat && (
                <div>
                  <Form.Group controlId="suitcasesWithFoldableSeat">
                    <Form.Label>
                      6. How many maximum suitcases can you accommodate with
                      folded car seats?
                    </Form.Label>
                    <Form.Select
                      style={ { width: 400 } }
                      placeholder="Select number of suitcases"
                      name="suitcasesWithFoldableSeat"
                      value={values.suitcasesWithFoldableSeat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">
Please select one option
                      </option>
                      {mediumSuitcases.map((item) => (
                        <option key={item.value}
                          value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                    <ErrorMessage
                      name="suitcasesWithFoldableSeat"
                      component="div"
                      className="error-message"
                    />
                  </Form.Group>
                </div>
              )}
              <Button
                variant="primary"
                type="submit"
                name="submit"
                className=" text-white "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submiting..." : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
