import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import NavigoTable from "../../../components/NavigoTable";
import {
  addCityWeekDaySurcharge,
  getCityShifts,
  getCitySubCollection,
  getCityWeekDaysSurcharges,
  removeCityWeekDaySurchargeById,
} from "../../../redux/admin/action";
import DistanaceRow from "./distanaceRow";

function Index() {
  const finalColumns = [
    { label: "Shift", key: "shift" },
    { label: "Monday", key: "monday" },
    { label: "Tuesday", key: "tuesday" },
    { label: "Wednesday", key: "wednesday" },
    { label: "Thursday", key: "thursday" },
    { label: "Friday", key: "friday" },
    { label: "Saturday", key: "saturday" },
    { label: "Sunday", key: "sunday" },
    { label: "Actions", key: "action" },
  ];
  const dispatch = useDispatch();
  const { slug } = useParams();
  const city = useSelector((state) => state.admin.city?.[slug]);
  const [
    initialValue, setInitialValue
  ] = useState([]);
  const addNewShiftHandler = useCallback(() => {
    const payload = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };
    dispatch(
      addCityWeekDaySurcharge(payload, city?.id, (data) => {
        let newShifts = [
          ...initialValue, data
        ];
        setInitialValue(newShifts);
      })
    );
  }, [
    city, dispatch, initialValue
  ]);
  const onDeleteHandler = useCallback(
    (id) => {
      dispatch(
        removeCityWeekDaySurchargeById(city?.id, id, () => {
          setInitialValue((prev) => prev.filter((pr) => pr.id !== id));
        })
      );
    },
    [
      city, dispatch
    ]
  );
  const [
    shifts, setShifts
  ] = useState([]);
  useEffect(() => {
    if (city) {
      dispatch(
        getCityShifts(city?.id, (data) => {
          setShifts(data);
        })
      );
      dispatch(
        getCityWeekDaysSurcharges(city?.id, (data) => {
          setInitialValue(data);
        })
      );
    }
  }, [
    city, dispatch
  ]);
  const [
    loader, setLoader
  ] = useState(false);
  const saveTariffHandler = useCallback(() => {
    setLoader(true);
    dispatch(
      getCitySubCollection(
        initialValue,
        city?.id,
        "WeekDaysSurcharges",
        (data) => {
          setInitialValue(data);
          setLoader(false);
        }
      )
    );
  }, [
    city, dispatch, initialValue
  ]);
  return (
    <Container className=" w-100 ">
      <Row className="gap-2">
        <Col lg={12}>
          <h2 className="fw-bold">
Night / weekend surcharges
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            onClick={addNewShiftHandler}
            type="primary"
            className="  fw-bold  "
          >
            <PlusOutlined className="m-auto" />
            Add new weekday surcharges
          </Button>
        </Col>
        <Col lg={12}
          className=" p-0 ">
          <NavigoTable
            columns={finalColumns}
            data={initialValue}
            renderItem={(item) => {
              return (
                <DistanaceRow
                  shifts={shifts}
                  onDelete={onDeleteHandler}
                  item={item}
                  onChange={(e) => {
                    const newDistance = initialValue.map((a) => {
                      if (a.id === e.id) {
                        return {
                          ...a,
                          ...e,
                        };
                      }
                      return a;
                    });
                    setInitialValue(newDistance);
                  }}
                />
              );
            }}
          />
        </Col>
        <Col lg={12}>
          <Button
            loading={loader}
            onClick={saveTariffHandler}
            type="primary"
            className="  fw-bold  "
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
