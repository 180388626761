import { DeleteOutlined } from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import React, { Fragment, memo, useState } from "react";
import { Col } from "react-bootstrap";
import { FaArrowDown, FaArrowUp, FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";

import DrivingLicenceModal from "../../../modals/DrivingLicenceModal";
import IdentityRecordModal from "../../../modals/IdentityRecordModal";
import VehicalDocumentModal from "../../../modals/VehicalDocumentModal";
import VehicalRequirmentsModal from "../../../modals/VehicalRequirmentsModal";
import { deleteRiderDocument } from "../../../redux/admin/action";
import {
  DrivingLicense,
  IdentityRecord,
  VehicalDocuments,
  VehicleRequirments,
} from "./sections";

const { Panel } = Collapse;

function Document(props) {
  const { type } = props;
  switch (type.name) {
  case "identity":
    return <IdentityRecord info={type}
      {...props} />;
  case "driving_License":
    return <DrivingLicense info={type}
      {...props} />;
  case "vehicle_Document":
    return <VehicalDocuments info={type}
      {...props} />;
  case "vehicle_Requirments":
    return <VehicleRequirments info={type}
      {...props} />;

  default:
    return <Fragment />;
  }
}

function Modal(props) {
  const { type } = props;
  switch (type?.name) {
  case "identity":
    return <IdentityRecordModal {...props}
      info={type} />;
  case "driving_License":
    return <DrivingLicenceModal info={type}
      {...props} />;
  case "vehicle_Document":
    return <VehicalDocumentModal info={type}
      {...props} />;
  case "vehicle_Requirments":
    return <VehicalRequirmentsModal info={type}
      {...props} />;

  default:
    return <Fragment />;
  }
}

function Index({ info, onDelete, onUpdate }) {
  const dispatch = useDispatch();
  const deleteDocument = () => {
    if (window.confirm("Want to delete this document?")) {
      setLoader(true);
      dispatch(deleteRiderDocument(info.id, stopLoader));
    }
  };
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      onDelete?.(data);
    }
  };
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col lg={12}
      className="my-2">
      <Spin spinning={loader}>
        <Collapse
          expandIcon={(props) => {
            return props.isActive ? (
              <FaArrowUp style={ { color: "white" } } />
            ) : (
              <FaArrowDown style={ { color: "white" } } />
            );
          }}
          accordion
          expandIconPosition="right"
        >
          <Panel
            className="rider_mainColor rounded shadow"
            header={<h1 className="text-white text-base fw-bold capitalize">
              {info?.name?.split("_")?.join(" ")}
            </h1>}
            key="1"
            extra={<div className=" z-50 h-8 w-10 justify-between flex items-center">
              <FaEdit className="text-white"
                onClick={handleShow} />
              <DeleteOutlined
                onClick={deleteDocument}
                className=" fw-bold"
                style={ { color: "white", fontWeight: 900 } }
              />
            </div>}
          >
            <Document type={info} />
          </Panel>
        </Collapse>
      </Spin>
      {show && (
        <Modal
          onUpdate={onUpdate}
          show={show}
          type={info}
          onHide={handleClose}
        />
      )}
    </Col>
  );
}

export default memo(Index);
