import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      {info?.images?.map((e, i) => {
        return (
          <p key={i}>
            Document # 
            {" "}
            {i + 1}
:
            <span>
              <a target="_blank"
                rel="noreferrer"
                href={e}>
                View
              </a>
            </span>
          </p>
        );
      })}
    </div>
  );
}

export default memo(Index);
