import "./order.css";

import { Input, Table, Tag } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import OrderItem from "../../../modals/OrderItem";
import { getUserOrders } from "../../../redux/user/action";

const { Search } = Input;
function Index() {
  const { orders } = useSelector((state) => state.user);

  const [
    loader, setLoader
  ] = useState(false);
  const [
    searchedText, setSearchedText
  ] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getUserOrders(stopLoader));
  }, [dispatch]);
  const stopLoader = () => {
    setLoader(false);
  };
  const [
    modalShow, setModalShow
  ] = useState(false);
  const [items] = useState(null);

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 150,
      render: (status) => {
        if (status === "completed") {
          return (
            <Tag color="green"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "cancelled") {
          return (
            <Tag color="red"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "pending") {
          return (
            <Tag color="yellow"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else if (status === "driver assigned") {
          return (
            <Tag color="gold"
              className="rounded-pill capitalize">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          );
        } else {
          return <p>
            {status}
          </p>;
        }
      },
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Ride Date",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.date,
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    {
      title: "Rider",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 220,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.vehicleType)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.origin?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.destination?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.orderNumber)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id.toString().split("-")[0])
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (name) => {
        return `${name}`;
      },
    },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (destination) => destination?.address,
    },
    {
      title: "Driver",
      align: "center",
      dataIndex: "driver",
      key: "driver",
      width: 220,
      render: (driver) => {
        return Array.isArray(driver) ? driver[0] : driver;
      },
    },
    {
      title: "Type of vehicle",
      align: "center",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 120,
      className: "capitalize",
    },
    {
      title: "No of Bags",
      align: "center",
      dataIndex: "noOfBags",
      key: "noOfBags",
      width: 220,
      render: (noOfBags) => {
        return noOfBags;
      },
    },
    {
      title: "No of Luggages",
      align: "center",
      dataIndex: "noOfLuggage",
      key: "noOfLuggage",
      width: 220,
      render: (noOfLuggage) => {
        return noOfLuggage;
      },
    },
    {
      title: "No of Passengers",
      align: "center",
      dataIndex: "noOfPass",
      key: "noOfPass",
      width: 220,
      render: (noOfPass) => {
        return noOfPass;
      },
    },
    {
      title: "Other charges",
      align: "center",
      dataIndex: "paid",
      key: "paid",
      width: 120,
      className: "capitalize",
      render: (e) => (e ? "Paid" : "Pending"),
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        <>
          {" "}
          &pound;
          {" "}
          {record.estimatedCost || record.cost
            ? record.estimatedCost
              ? record.estimatedCost
              : record.cost
            : 0}
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h2 className="fw-bold">
Rides history
          </h2>
        </Col>
        <Col lg={12}
          className="gap-3">
          <Search
            placeholder="Search rides..."
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={ {
              width: 300,
              marginBottom: "10px",
              float: "right",
            } }
          />
          <Table
            bordered
            className="table-curved riderTable"
            columns={columns}
            dataSource={orders}
            pagination={false}
            loading={loader}
            scroll={ { x: 900, y: 400 } }
          />
        </Col>
      </Row>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
