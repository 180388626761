import { Autocomplete } from "@react-google-maps/api";
import { Formik } from "formik";
import { ColorPicker } from "material-ui-color";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { addCityZones, updateZoneOffice } from "../../redux/admin/action";
import { getAddress } from "../../utils/addressChanger";
import addZoneValidation from "../../validations/AddZone";
import ZoneMap from "./ZoneMap";

function Index({ show, onHide, base, cityId, onChange, onUpdate }) {
  const dispatch = useDispatch();
  const [
    initialValues,setInitialValues
  ]=useState({
    name:"",
    drawingMode:"edit",
    zoneType:"circle",
    value:null,
    position:{address:""},
    priority:"",
    color:""
  })
  const [
    autoComplete, setAutoComplete
  ] = useState("");
  const onLoad = (value) => {
    setAutoComplete(value);
  };
  const stopSaving = useCallback(
    (actions) => (data) => {
      actions.setSubmitting(false);
      onChange(data);
      onHide();
    },
    [
      onHide, onChange
    ]
  );
  const updateHandler = useCallback(
    (actions) => (data) => {
      actions.setSubmitting(false);
      onUpdate(data);
      onHide();
    },
    [
      onHide, onUpdate
    ]
  );
  const addUserNotes = useCallback(
    (values, actions) => {
      if (base) {
        dispatch(
          updateZoneOffice(values, base?.id, cityId, updateHandler(actions))
        );
      } else {
        dispatch(addCityZones(values, cityId, stopSaving(actions)));
      }
    },
    [
      base, cityId, dispatch, stopSaving, updateHandler
    ]
  );
  useEffect(()=>{
    if(base){
      setInitialValues((prev)=>{
        return {
          ...prev,
          ...base
        }
      })
    }
  },[base])
  return (
    <Modal show={show}
      size="xl"
      onHide={onHide}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add new zone
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={addZoneValidation}
          onSubmit={addUserNotes}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
        
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="flex gap-2">
                <Form.Group className="w-50"
                  controlId="formBasictitle">
                  <Form.Label>
                    Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.name}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasicdesc">
                  <Form.Label>
                  Position
                  </Form.Label>
                  <Autocomplete
                    className="w-full  "
                    onLoad={onLoad}
                    restrictions={ { country: "GB" } }
                    onPlaceChanged={async () => {
                      if (autoComplete !== null) {
                        const place = autoComplete.getPlace();
                        const address = await getAddress(place);
                        setFieldValue("position", address);
                      }
                    }}
                  >
                    <FormControl
                      placeholder="Enter address"
                      value={values?.position?.address}
                      name="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                  </Autocomplete>
                  {errors.position?.latValue&& (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors?.position.latValue}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="flex gap-2">
                <Form.Group className="w-50"
                  controlId="formBasictitle">
                  <Form.Label>
                    Zone type
                  </Form.Label>
                  <Form.Select
                    type="text"
                   
                    placeholder="Select zone type"
                    name="zoneType"
                    value={values.zoneType}
                    onChange={(e)=>{
                      setFieldValue("zoneType",e.target.value)
                      setFieldValue("value",null)
                    }}
                  >
                    <option value="circle">
                      Circle
                    </option>
                    <option value="polygon">
                      Polygon
                    </option>
                    <option value="rectangle">
                      Rectangle
                    </option>
                  </Form.Select>
                  {errors.zoneType && touched.zoneType && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.zoneType}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasictitle">
                  <Form.Label>
                    Drawing mode
                  </Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Select drawing mode"
                    name="drawingMode"
                    value={values.drawingMode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="edit">
                      Edit
                    </option>
                    <option value="draw">
                      Draw
                    </option>
                  </Form.Select>
                  {errors.drawingMode && touched.drawingMode && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.drawingMode}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="flex gap-2">
                <Form.Group className="w-50"
                  controlId="formBasictitle">
                  <Form.Label>
                    Priority
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter priority"
                    name="priority"
                    value={values.priority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.priority && touched.priority && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.priority}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasictitle">
                  <Form.Label>
                    Color
                  </Form.Label>
                  <ColorPicker
                    defaultValue={"black"}
                    onChange={(color)=>{
                      setFieldValue("color", color)
                    }} 
                    value={values.color}  />
                  {errors.color && touched.color && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.color}
                    </span>
                  )}
                </Form.Group>
              </div>
              <ZoneMap 
                drawingMode={values.drawingMode}
                zoneType={values.zoneType}
                position={values.position}
                zone={values.value}
                zoneColor={values.color}
                onPolygonComplete={(polygon)=>{
                  const paths = polygon.getPath().getArray().map((path)=>{
                    return path?.toJSON()
                  })
                  setFieldValue("value",paths)
                }}
                onCircleComplete={(circle)=>{
                 
                  let radius = circle?.getRadius()
                  let center= circle?.getCenter()?.toJSON()
                  setFieldValue("value",{radius,center})
                }}
                onRectangleComplete={(rectangle)=>{
                  let bounds = rectangle?.getBounds()?.toJSON()
                  setFieldValue("value", bounds)
                }}
                onZoneUpdate={(value)=>{
                  if(values.zoneType==="polygon"){
                    const paths = value.getPath().getArray().map((path) => {
                      return path?.toJSON()
                    })

                    setFieldValue("value", paths)
                    
                  }
                  if (values.zoneType === "circle"){
                    
                    let radius = value?.getRadius()
                    let center = value?.getCenter().toJSON()
                    if (radius!==values.value?.radius|| 
                       center?.lat !== values.value?.center?.lat
                      || center?.lng !== values.value?.center?.lng){
                      setFieldValue("value", { radius, center })
                    }

                  }
                  if (values.zoneType === "rectangle"){
                    let bounds = value?.getBounds()?.toJSON()
       
                    if(
                      values?.value?.north !== bounds.north 
                      || values?.value?.south !== bounds.south
                      || values?.value?.east !== bounds.east
                      || values?.value?.west !== bounds.west
                    ){
                      setFieldValue("value", bounds)

                    }
                  }
                 
                }}
              />
              <Button
                disabled={isSubmitting}
                variant="warning"
                className="mt-3 fw-bolder text-white"
                type="submit"
              >
                {isSubmitting ? "Adding.." : "Add"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          className="fw-bolder text-white"
          variant="warning"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
