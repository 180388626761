import "./index.css";

import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <div className="flex">
        <p>
          Bank Statement:
          <span className="mx-1">
            {info?.bankStatement?.bankStatement?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.bankStatement?.bankStatementExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Disclosure Barring Service:
          <span className="mx-1">
            {info?.disclosureBarringService?.disclosureBarringService?.map(
              (val) => {
                return (
                  <>
                    <a target="_blank"
                      rel="noreferrer"
                      href={val}>
                      View
                      <span>
,
                        {" "}
                      </span>
                    </a>
                  </>
                );
              }
            )}
          </span>
        </p>
        <p>
          | Expiry Date: 
          {" "}
          {info?.disclosureBarringService?.baringServiceExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Proof Of Identity:
          <span className="mx-1">
            {info?.proofOfIdentity?.proofOfIdentity?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.proofOfIdentity?.identityExpiry}
        </p>
      </div>
      <div className="flex">
        <p>
          Profile Photo:
          <span className="mx-1">
            {info?.profilePhoto?.profilePhoto?.map((val) => {
              return (
                <>
                  <a target="_blank"
                    rel="noreferrer"
                    href={val}>
                    View
                    <span>
,
                      {" "}
                    </span>
                  </a>
                </>
              );
            })}
          </span>
        </p>
        <p>
| Expiry Date:
          {info?.profilePhoto?.profileExpiry}
        </p>
      </div>
    </div>
  );
}

export default memo(Index);
