import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React, { memo , useCallback , useEffect , useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import NavigoTable from "../../../components/NavigoTable";
import {
  addCityNewShift,
  getCityShifts,
  getCitySubCollection,
  removeShiftById,
} from "../../../redux/admin/action";
import DistanaceRow from "./distanaceRow";

function Index() {
  const { slug } = useParams();
  const city = useSelector((state) => state.admin?.city?.[slug]);
  const finalColumns = [
    { label: "Name", key: "name" },
    { label: "Start time", key: "startTime" },
    { label: "End time", key: "endTime" },

    { label: "Actions", key: "action" },
  ];
  const dispatch = useDispatch();
  const [
    initialValue, setInitialValue
  ] = useState([]);
  const addNewShiftHandler = useCallback(() => {
    const payload = {
      startTime: moment().format("HH:mm"),
      endTime: moment().format("HH:mm"),
    };
    dispatch(
      addCityNewShift(payload, city?.id, (data) => {
        let newShifts = [
          ...initialValue, data
        ];
        setInitialValue(newShifts);
      })
    );
  }, [
    city, dispatch, initialValue
  ]);
  const onDeleteHandler = useCallback(
    (id) => {
      dispatch(
        removeShiftById(city?.id, id, () => {
          setInitialValue((prev) => prev.filter((pr) => pr.id !== id));
        })
      );
    },
    [
      city, dispatch
    ]
  );

  useEffect(() => {
    if (city) {
      dispatch(
        getCityShifts(city?.id, (data) => {
          setInitialValue(data);
        })
      );
    }
  }, [
    city, dispatch
  ]);
  const [
    loader, setLoader
  ] = useState(false);
  const saveBasesHandler = useCallback(() => {
    setLoader(true);
    dispatch(
      getCitySubCollection(initialValue, city?.id, "Shifts", (data) => {
        setInitialValue(data);
        setLoader(false);
      })
    );
  }, [
    city?.id, dispatch, initialValue
  ]);
  return (
    <Container className=" w-100 ">
      <Row className="gap-2">
        <Col lg={12}>
          <h2 className="fw-bold">
Shifts
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            onClick={addNewShiftHandler}
            type="primary"
            className="  fw-bold  "
          >
            <PlusOutlined className="m-auto" />
            Add new shift
          </Button>
        </Col>
        <Col lg={12}
          className=" bg-white  p-0 ">
          <NavigoTable
            columns={finalColumns}
            data={initialValue}
            renderItem={(item) => {
              return (
                <DistanaceRow
                  onDelete={onDeleteHandler}
                  item={item}
                  onChange={(e) => {
                    const newDistance = initialValue.map((a) => {
                      if (a.id === e.id) {
                        return {
                          ...a,
                          ...e,
                        };
                      }
                      return a;
                    });
                    setInitialValue(newDistance);
                  }}
                />
              );
            }}
          />
        </Col>
        <Col lg={12}>
          <Button
            loading={loader}
            onClick={saveBasesHandler}
            type="primary"
            className="  fw-bold  "
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
