import "./index.css";

import { Layout, Menu } from "antd";
import React, { Fragment, memo } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import LogoImg from "../../assets/images/logo.png";
import cityNavigation from "../../navigation/cityData";

const { Sider } = Layout;

function Index({ collapsed }) {
  const { slug } = useParams();
  const location = useLocation();
  const menuLinks = cityNavigation.map((value) => {
    const Icon = value?.icon || Fragment;
    if (value?.Children) {
      return (
        <Menu.SubMenu key={value?.url}
          icon={<Icon />}
          title={value.text}>
          {value.Children.map((item) => {
            const Icon = item?.icon || Fragment;
            const urls = "cities/" + slug + item?.url;
            return (
              <Menu.Item icon={<Icon />}
                key={`/${urls}`}>
                <NavLink to={`/${urls}`}>
                  {item?.text}
                </NavLink>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    }

    const url = "cities/" + slug + value?.url;

    return (
      <Menu.Item key={`/${url}`}
        icon={<Icon />}>
        <NavLink to={`/${url}`}>
          {value?.text}
        </NavLink>
      </Menu.Item>
    );
  });

  return (
    <Sider
      className="shadow  sidar-fixed"
      trigger={null}
      collapsible
      theme="dark"
      style={ { backgroundColor: "black" } }
      collapsed={collapsed}
    >
      <div className="py-3 px-2 w-full d-flex align-items-center justify-content-center">
        <Link to="/">
          <img
            src={LogoImg}
            alt="logo"
            style={ { width: "100%", height: "100%" } }
          />
        </Link>
      </div>
      <PerfectScrollBar>
        <Menu
          theme="dark"
          mode="inline"
          className="maindiv"
          defaultOpenKeys={[location?.pathname]}
          defaultSelectedKeys={[location?.pathname]}
        >
          {menuLinks}
        </Menu>
      </PerfectScrollBar>
    </Sider>
  );
}

export default memo(Index);
