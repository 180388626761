import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <p>
        Car Name: 
        {" "}
        <span>
          {info?.carName}
        </span>
      </p>
      <p>
        Car Model: 
        {" "}
        <span>
          {info?.carModel}
        </span>
      </p>
      <p>
        Registration Number: 
        {" "}
        <span>
          {info?.registrationNumber}
        </span>
      </p>
      <p>
        Allow Passengers: 
        {" "}
        <span>
          {info?.allowPassengers}
        </span>
      </p>
      <p>
        Medium Size Suitcases: 
        {" "}
        <span>
          {info?.mediumSizeSuitcases}
        </span>
      </p>
      <p>
        Fold Able Seat: 
        {" "}
        <span>
          {info?.foldAbleSeat ? "Yes" : "No"}
        </span>
      </p>
      <p>
        Suitcases With Foldable Seat:
        {" "}
        <span>
          {info?.suitcasesWithFoldableSeat}
        </span>
      </p>
    </div>
  );
}

export default memo(Index);
