import React, { memo } from "react";
import { Button, Modal, Table } from "react-bootstrap";

import SingleItem from "./singleItem";

function Index({ items, show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
Item List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped
          bordered
          hover>
          <thead>
            <tr>
              <th>
Item number
              </th>
              <th>
Item quantity
              </th>
              <th>
Item category
              </th>
              <th>
Item name
              </th>
              <th>
Variant name
              </th>
              <th>
Extras
              </th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => (
              <SingleItem key={index}
                number={index + 1}
                item={item} />
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          className="fw-bolder text-white"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
