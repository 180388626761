import "./index.css";

import { Autocomplete } from "@react-google-maps/api";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";

import SnackBarCustom from "../../components/SnackBarCustom";
import { addAdminDetail, updateAdminDetail } from "../../redux/admin/action";
import { getAddress } from "../../utils/addressChanger";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    errors, seterrors
  ] = useState([]);
  const [
    autocomplet, setautocomplet
  ] = useState("");
  const onLoad = (autocomplete) => {
    setautocomplet(autocomplete);
  };
  const dispatch = useDispatch();
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    rideremerPhone, setrideremerPhone
  ] = useState("");

  const [
    rideremerdata, setrideremerdata
  ] = useState({
    firstName: "",
    lastname: "",
    address: "",
    relationship: "",
    fullAddress: "",
  });

  const inputchange = (e) => {
    const { value, name } = e.target;
    setrideremerdata((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const saveemerinfo = async (e) => {
    e.preventDefault();
    let error = [];

    const format = /[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
    function hasNumber(myString) {
      return /\d/.test(myString);
    }
    if (rideremerdata.firstName === "") {
      error.push({ firstNameempty: true });
    }
    if (
      hasNumber(rideremerdata.firstName) ||
      format.test(rideremerdata.firstName)
    ) {
      error.push({ firstNameisnumber: true });
    }
    if (rideremerdata.lastname === "") {
      error.push({ lastnameempty: true });
    }
    if (
      hasNumber(rideremerdata.lastname) ||
      format.test(rideremerdata.lastname)
    ) {
      error.push({ lastnameisnumber: true });
    }
    if (rideremerdata.fullAddress.length < 6) {
      error.push({ fullAddressEmpty: true });
    }
    if (
      rideremerdata.address === "" ||
      typeof rideremerdata.address === "string"
    ) {
      error.push({ addressempty: true });
    }
    if (rideremerdata.relationship === "") {
      error.push({ relationshipempty: true });
    }
    if (
      hasNumber(rideremerdata.relationship) ||
      format.test(rideremerdata.relationship)
    ) {
      error.push({ relationshipisnumber: true });
    }
    if (rideremerPhone === "") {
      error.push({ rideremerPhoneempty: true });
    } else {
      if (rideremerPhone.length !== 13) {
        error.push({ numbernot10: true });
      }
    }

    if (error.length < 1) {
      setsubmitloading(true);
      if (as === "NEW") {
        dispatch(
          addAdminDetail(
            "emergency",
            {
              firstName: rideremerdata.firstName,
              lastName: rideremerdata.lastname,
              location: {
                ...rideremerdata.address,
                fullAddress: rideremerdata.fullAddress,
              },
              relationship: rideremerdata.relationship,
              phoneNumber: rideremerPhone,
            },
            stopLoader
          )
        );
        return;
      }
      dispatch(
        updateAdminDetail(
          "emergency",
          {
            firstName: rideremerdata.firstName,
            lastName: rideremerdata.lastname,
            location: {
              ...rideremerdata.address,
              fullAddress: rideremerdata.fullAddress,
            },
            relationship: rideremerdata.relationship,
            phoneNumber: rideremerPhone,
          },
          stopLoader
        )
      );
    } else {
      seterrors(error);
    }
  };
  const stopLoader = (data) => {
    setsubmitloading(false);
    if (data) {
      setAdder(true);

      if (as === "NEW") {
        onAdd(data);
      } else {
        onUpdate(data);
      }
      onHide?.();
    }
  };
  const [
    adder, setAdder
  ] = useState(false);
  useEffect(() => {
    if (info) {
      setrideremerPhone(info?.phoneNumber);
      setrideremerdata({
        firstName: info?.firstName,
        lastname: info?.lastName,
        address: info?.location,
        relationship: info?.relationship,
        fullAddress: info?.location?.fullAddress,
      });
    }
  }, [info]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Emergency Contact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            saveemerinfo(e);
          }}
          method="POST"
          className=" emerjency-form "
        >
          <Form.Control
            type="text"
            placeholder="First name"
            value={rideremerdata.firstName}
            name="firstName"
            onChange={(e) => {
              inputchange(e);
            }}
          />
          {errors &&
            errors.map(({ firstNameempty }, index) => {
              if (firstNameempty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s firstname?
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ firstNameisnumber }, index) => {
              if (firstNameisnumber) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    First name must be charactors
                  </span>
                );
              }
              return null;
            })}
          <Form.Control
            type="text"
            placeholder="Last name"
            value={rideremerdata.lastname}
            name="lastname"
            onChange={(e) => {
              inputchange(e);
            }}
          />
          {errors &&
            errors.map(({ lastnameempty }, index) => {
              if (lastnameempty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s firstname?
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ lastnameisnumber }, index) => {
              if (lastnameisnumber) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    Last name must be charactors
                  </span>
                );
              }
              return null;
            })}
          <PhoneInput
            defaultCountry="GB"
            name="number"
            value={rideremerPhone}
            onChange={setrideremerPhone}
            className="w-100"
          />
          {errors &&
            errors.map(({ rideremerPhoneempty }, index) => {
              if (rideremerPhoneempty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s phone number?
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ numbernot10 }, index) => {
              if (numbernot10) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    phone number must be 13 numbers
                  </span>
                );
              }
              return null;
            })}
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={async () => {
              if (autocomplet !== null) {
                const place = await autocomplet.getPlace();
                const address = await getAddress(place);

                setrideremerdata((p) => {
                  return {
                    ...p,
                    address,
                  };
                });
              }
            }}
          >
            <Form.Control
              type="text"
              placeholder="Adresss"
              value={rideremerdata.address.address}
              name="address"
              onChange={(e) => {
                inputchange(e);
              }}
            />
          </Autocomplete>
          {errors &&
            errors.map(({ addressempty }, index) => {
              if (addressempty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s address?
                  </span>
                );
              }
              return null;
            })}
          <Form.Control
            type="text"
            placeholder="Full address"
            value={rideremerdata.fullAddress}
            name="fullAddress"
            onChange={(e) => {
              inputchange(e);
            }}
          />
          {errors &&
            errors.map(({ fullAddressEmpty }, index) => {
              if (fullAddressEmpty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s full Addresss?
                  </span>
                );
              }
              return null;
            })}
          <Form.Control
            type="text"
            placeholder="Relationship"
            value={rideremerdata.relationship}
            name="relationship"
            onChange={(e) => {
              inputchange(e);
            }}
          />
          {errors &&
            errors.map(({ relationshipempty }, index) => {
              if (relationshipempty) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    What&apos;s relationship?
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ relationshipisnumber }, index) => {
              if (relationshipisnumber) {
                return (
                  <span key={index}
                    className="w-100 text-danger">
                    relationship must be charactors
                  </span>
                );
              }
              return null;
            })}
          <Col lg={12}>
            <Button
              variant="warning"
              className=" m-2 fw-bolder text-white"
              disabled={submitloading ? true : false}
              type="submit"
            >
              {submitloading ? "Saving.." : "Save"}
            </Button>
          </Col>
        </Form>
      </Modal.Body>
      <SnackBarCustom
        msg={"Item added"}
        open={adder}
        onClose={() => setAdder(false)}
      />
    </Modal>
  );
}

export default memo(Index);
