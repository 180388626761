import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";

import ChangeAddForm from "../../../components/userSettings/ChangeAddForm";
import ChangeContactForm from "../../../components/userSettings/ChangeContactForm";
import ChangePassForm from "../../../components/userSettings/ChangePassFrom";
import ChangePaymentForm from "../../../components/userSettings/ChangePaymentForm";
import UserDataForm from "../../../components/userSettings/UserDataForm";

function Index() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Settings
          </h1>
        </Col>
        <Col lg={12}
          className="bg-white rounded-lg shadow-md p-5">
          <Row className="d-flex justify-content-center align-items-center flex-column">
            <UserDataForm />
            <ChangePassForm />
            <ChangeAddForm />
            <ChangeContactForm />
            <ChangePaymentForm />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
