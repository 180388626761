import { DatePicker, notification } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Uploadwithtitle from "../../components/Uploadwithtitle";
import { addRiderDetail, updateRiderDetail } from "../../redux/rider/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    identityExpiry, setIdentityExpiry
  ] = useState("");
  const [
    baringServiceExpiry, setBaringServiceExpiry
  ] = useState("");
  const [
    bankStatementExpiry, setBankStatementExpiry
  ] = useState("");
  const [
    profileExpiry, setProfileExpiry
  ] = useState("");

  const [
    identity, setIdentity
  ] = useState({
    proofOfIdentity: null,
    disclosureBarringService: null,
    bankStatement: null,
    profilePhoto: null,
  });
  const stopLoader = (data) => {
    if (data) {
      if (as === "NEW") {
        onAdd(data);
        notification.open({
          message: "Documents Added Successfully!",
          type: "success",
          placement: "top",
        });
      } else {
        onUpdate(data);
        notification.open({
          message: "Documents Updated Successfully!",
          type: "success",
          placement: "top",
        });
      }
      onHide?.();
    }
    setsubmitloading(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (info) {
      setIdentity({
        proofOfIdentity: info.proofOfIdentity.proofOfIdentity,
        disclosureBarringService:
          info.disclosureBarringService.disclosureBarringService,

        bankStatement: info.bankStatement.bankStatement,
        profilePhoto: info.profilePhoto.profilePhoto,
      });
      setIdentityExpiry(info.proofOfIdentity.identityExpiry);
      setBankStatementExpiry(info.bankStatement.bankStatementExpiry);
      setBaringServiceExpiry(info.disclosureBarringService.baringServiceExpiry);
      setProfileExpiry(info.profilePhoto.profileExpiry);
    }
  }, [info]);
  const onUploadHandle = (e) => {
    setIdentity({
      ...identity,
      ...e,
    });
  };
  const saveInDatabase = (e) => {
    e.preventDefault();
    if (
      identityExpiry === "" ||
      baringServiceExpiry === "" ||
      profileExpiry === "" ||
      bankStatementExpiry === ""
    ) {
      if (identityExpiry === "") {
        document.getElementById("identityError").textContent =
          "Please enter expiry date!";
      }
      if (baringServiceExpiry === "") {
        document.getElementById("baringServiceError").textContent =
          "Please enter expiry date!";
      }
      if (profileExpiry === "") {
        document.getElementById("profileError").textContent =
          "Please enter expiry date!";
      }
      if (bankStatementExpiry === "") {
        document.getElementById("bankStatementError").textContent =
          "Please enter expiry date!";
      }
    } else {
      let proofOfIdentity = identity.proofOfIdentity;
      let disclosureBarringService = identity.disclosureBarringService;
      let bankStatement = identity.bankStatement;
      let profilePhoto = identity.profilePhoto;
      const finalData = {
        proofOfIdentity: {
          proofOfIdentity,
          identityExpiry,
        },
        disclosureBarringService: {
          disclosureBarringService,
          baringServiceExpiry,
        },
        bankStatement: {
          bankStatement,
          bankStatementExpiry,
        },
        profilePhoto: {
          profilePhoto,
          profileExpiry,
        },
      };
      setsubmitloading(true);
      if (as === "NEW") {
        dispatch(addRiderDetail("identity", finalData, stopLoader));
        return;
      }
      dispatch(updateRiderDetail(info.id, finalData, stopLoader));
    }
  };
  const onRemoveHandler = (name) => {
    setIdentity((p) => {
      return {
        ...p,
        [name]: null,
      };
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
Identity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                value={identity?.proofOfIdentity}
                onUpload={onUploadHandle}
                name="proofOfIdentity"
                title="1. Proof of Identity (Passport/Driver’s License)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={identityExpiry ? moment(identityExpiry, "DD MMM YYYY") : ""}
                onChange={(e) => {
                  setIdentityExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("identityError").textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="identityError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                value={identity?.disclosureBarringService}
                onUpload={onUploadHandle}
                name="disclosureBarringService"
                title="2. Disclosure and Barring Service (DBS)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={baringServiceExpiry
                  ? moment(baringServiceExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setBaringServiceExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("baringServiceError").textContent =
                    "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="baringServiceError"
                style={ { color: "red" } }></p>
            </Col>
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                value={identity?.bankStatement}
                name="bankStatement"
                title="3. Bank Statement (From the last month)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={bankStatementExpiry
                  ? moment(bankStatementExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setBankStatementExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("bankStatementError").textContent =
                    "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="bankStatementError"
                style={ { color: "red" } }></p>
            </Col>
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                value={identity?.profilePhoto}
                name="profilePhoto"
                title="4. Profile Photo (Recent)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={profileExpiry ? moment(profileExpiry, "DD MMM YYYY") : ""}
                onChange={(e) => {
                  setProfileExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("profileError").textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="profileError"
                style={ { color: "red" } }></p>
            </Col>
            <Col lg={12}>
              <Button onClick={saveInDatabase}>
                {submitloading ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
