/* eslint-disable func-names */
import * as yup from "yup";

import { store } from "../config/store";
import AdminApi from "../redux/admin/adminApi";
import { validateemail } from "../utils/gerenatePassword";

const adminApi = new AdminApi();
yup.addMethod(yup.string, "emailisAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value || !validateemail(value)) return;

    const { data } = await adminApi.checkUseremail({ email: value });
    if (
      data?.code === "auth/user-found" &&
      store?.getState()?.admin?.profileAdmin?.email !== value
    ) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});
const schema = yup.object().shape({
  firstName: yup.string().required("Enter first name"),
  lastName: yup.string().required("Enter last name"),
  phone: yup.string().required("Enter phone number"),
  role: yup.string().required("Select Role fee"),
  totalLeaves: yup.number().required("Enter total leaves"),
  totalPublicHolidays: yup.number().required("Enter total public holidays"),
  email: yup
    .string()
    .email()
    .required("Enter email address")
    .emailisAlready("email already registered"),

  location: yup.object().required("Enter address"),
  specificCities: yup.boolean(),
  citys: yup.array().when("specificCities", {
    is: true,
    then: yup.array().required().min(1, "Please select atleast one tab"),
  }),
  fullAddress: yup.string().required("Enter full address"),
});

export default schema;
