import { InteractionOutlined, LoginOutlined } from "@ant-design/icons";
import { Button, TextField } from "@mui/material";
import { Checkbox, Spin, Table, Tooltip } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaPenAlt, FaPlus, FaTrash, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { default as swal, default as swl } from "sweetalert";
import AdminModal from "../../modals/AdminModal";
import ActionBtn from "../../components/actionBtn";
import {
  activateAdminById,
  addAdminFirestore,
  deActivateAdminById,
  deleteAdminById,
  getAdmins,
  getCitys,
} from "../../redux/admin/action";
import addAdminValidation from "../../validations/AddAdmin";
import LoginStatus from "./LoginStatus";
import ShiftStatus from "./ShiftStatus";

function Index() {
  const { roles, admins } = useSelector((state) => state.admin);
  const [modalShow, setModalShow] = useState(false);
  const [adminData, setAdminData] = useState();

  const { user } = useSelector((state) => state.auth);
  const role = roles.find((e) => e.id === user.role);

  const changeStatusAdmin = (user) => (cb) => {
    if (!user.adminProved) {
      dispatch(activateAdminById(user, cb));

      return;
    }
    dispatch(deActivateAdminById(user, cb));
  };
  const deleteMember = (user) => (cb) => {
    dispatch(deleteAdminById(user, cb));
  };

  const columns = [
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 120,

      className: "capitalize",
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
      key: "email",
      width: 220,
    },

    // {
    //   title: "Login Status",
    //   align: "center",
    //   dataIndex: "isLogin",
    //   key: "isLogin",
    //   className: "capitalize",
    //   width: 120,

    //   render: (_, record) => <LoginStatus user={record} />,
    // },
    // {
    //   title: "Shift Status",
    //   align: "center",
    //   dataIndex: "isLogin",
    //   key: "isLogin",
    //   className: "capitalize",
    //   width: 120,

    //   render: (_, record) => <ShiftStatus user={record} />,
    // },

    // {
    //   title: "Cities",
    //   align: "center",
    //   dataIndex: "citys",
    //   width: 120,

    //   key: "citys",
    //   className: "capitalize",
    //   render: (value, record) => {
    //     if (record.specificCities) {
    //       return value.join(", ");
    //     }
    //     return "All Cities";
    //   },
    // },
    // {
    //   title: "Role",
    //   align: "center",
    //   width: 120,

    //   dataIndex: "role",
    //   key: "role",
    //   className: "capitalize",
    //   render: (value) => roles?.find((e) => e.id === value)?.name || value,
    // },
    {
      title: "Actions",
      key: "operation",
      width: 120,

      align: "center",
      render: (_, record) => {
        if (record?.role === "ROOT") {
          return null;
        }
        return (
          <div className="d-flex align-items-center justify-content-center">
            {/* {role && role.specificMain ? (
              <>
                {role?.adminTabs?.length > 0 && (
                  <Tooltip title="profile">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`/admin/${record.slug}`}
                    >
                      <LoginOutlined style={{ color: "blue", fontSize: 15 }} />
                    </a>
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip title="profile">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/admin/${record.id}`}
                >
                  <LoginOutlined style={{ color: "blue", fontSize: 15 }} />
                </a>
              </Tooltip>
            )} */}
            {/* <ActionBtn
              type={record.adminProved ? "De-activate" : "Activate"}
              confirmMsg={`Want to ${
                record.adminProved ? "de-activate" : "activate"
              } member?`}
              action={changeStatusAdmin(record)}
            >
              <InteractionOutlined
                style={{
                  color: record.adminProved ? "red" : "green",
                  fontSize: 15,
                  margin: 5,
                }}
              />
            </ActionBtn> */}
            <ActionBtn
              type={"Delete"}
              action={deleteMember(record)}
              confirmMsg={"Want to delete this member?"}
            >
              <FaTrash
                style={{
                  color: "red",
                  fontSize: 15,

                  cursor: "pointer",
                }}
              />
            </ActionBtn>
            <Button onClick={() => {
              setAdminData(record)
              setModalShow(true)
            }}><FaEdit style={{ color: "blue" }} /> </Button>
          </div>
        );
      },
    },
    {
      title: "Created at",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["sm"],
      width: 120,

      render: (e) => moment(Number(e)).format("DD-MMM-YY/hh:mm A"),
    },
  ];

  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const [cities, setCities] = useState([]);

  const [adder, setAdder] = useState(false);
  const stopAdder = (resetForm) => (data) => {
    setAdder(false);

    if (data?.code === "auth/user-found") {
      swal("email Already Exist");
    } else {
      resetForm?.();
    }

    resetForm?.();
  };

  const dispatch = useDispatch();

  const stopLoader = () => {
    setLoader(false);
  };
  const stopFlag = (data) => {
    setFlag(false);
    setCities(data);
  };

  useEffect(() => {
    setLoader(true);

    dispatch(getAdmins(stopLoader));
    dispatch(getCitys(stopFlag));
  }, [dispatch]);
  const addMember = (e, { resetForm }) => {
    if (admins.find((a) => a.email?.includes(e.email))) {
      swl("email already registered!");
      return;
    }

    setAdder(true);
    dispatch(addAdminFirestore(e, stopAdder(resetForm)));
  };

  return (
    <Container fluid className=" w-100  pb-5">
      <Row>
        <Col lg={12} className=" pl-0 ">
          <h2 className="fw-bold">Admins</h2>
        </Col>
        <Col lg={12} className="bg-white rounded-t-lg shadow-md">
          <Formik
            onSubmit={addMember}
            validationSchema={addAdminValidation}
            initialValues={{
              name: "",
              email: "",
              // role: "",
              // citys: [],
              // specificCities: false,
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Spin spinning={adder}>
                <form
                  onSubmit={handleSubmit}
                  className="flex justify-between gap-3 flex-wrap  p-3 items-center"
                >
                  <div className=" flex justify-center items-center">
                    <span className="fw-bold">Name:</span>
                    <div className="flex flex-col">
                      <TextField
                        required
                        error={errors.name}
                        id="filled-required"
                        variant="standard"
                        className="mx-3 "
                        placeholder="Enter name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="name"
                      />
                      {errors.name && touched.name && (
                        <span
                          className="ml-4 text-danger text-start"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" flex justify-center items-center">
                    <span className="fw-bold">Email:</span>
                    <div className="flex flex-col">
                      <TextField
                        required
                        error={errors.email}
                        id="filled-required"
                        variant="standard"
                        className="mx-3 "
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="email"
                      />
                      {errors.email && touched.email && (
                        <span
                          className="ml-4 text-danger text-start"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className=" flex justify-center items-center">
                    <span className="fw-bold">Role:</span>
                    <div className=" flex flex-col items-center ">
                      <Select
                        onChange={(e) => {
                          setFieldValue("role", e.value);
                        }}
                        name="colors"
                        value={roles?.find((a) => a.value === values.role)}
                        options={roles?.map((e) => {
                          return {
                            label: e.name,
                            value: e.id,
                          };
                        })}
                        className="basic-multi-select  mx-3 border-0"
                        classNamePrefix="select"
                      />
                      {errors.role && touched.role && (
                        <span
                          className="w-60 text-danger text-start"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.role}
                        </span>
                      )}
                    </div>
                  </div> */}
                  {/* <div className=" flex justify-center items-center">
                    <span className="fw-bold">Cities:</span>
                    <Checkbox
                      className="mx-2"
                      onChange={(e) => {
                        setFieldValue("specificCities", e.target.checked);
                      }}
                    >
                      Specific Cities
                    </Checkbox>
                    {values.specificCities && (
                      <div className=" flex flex-col items-center ">
                        <Select
                          onChange={(e) => {
                            let cities = e.map((e) => e.value);

                            setFieldValue("citys", cities);
                          }}
                          name="citys"
                          value={values.citys.map((e) => {
                            return {
                              label: cities.find((a) => a.name === e).name,
                              value: e,
                            };
                          })}
                          options={cities?.map((e) => {
                            return {
                              label: e.name,
                              value: e.name,
                            };
                          })}
                          isMulti
                          isLoading={flag}
                          className="basic-multi-select w-60 mx-3 border-0"
                          classNamePrefix="select"
                        />
                        {errors.citys && touched.citys && (
                          <span
                            className="w-60 text-danger text-start"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.citys}
                          </span>
                        )}
                      </div>
                    )}
                  </div> */}
                  <Button
                    type="submit"
                    disabled={adder}
                    size="small"
                    style={{ backgroundColor: "#ffbf2c" }}
                    className="text-white fw-bold"
                    startIcon={<FaPlus style={{ fontSize: "14px" }} />}
                    variant="contained"
                  >
                    Add admin
                  </Button>
                </form>
              </Spin>
            )}
          </Formik>
        </Col>
        <Col lg={12} className="bg-white p-2 shadow-md">
          <Table
            dataSource={
              admins?.length > 0 &&
              admins?.sort((a, b) => {
                return a.createdAt < b.createdAt ? -1 : 1;
              })
            }
            loading={loader}
            bordered
            className="table-curved adminTable"
            columns={columns}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </Col>
      </Row>
      {modalShow && (
        <AdminModal
          adminData={adminData}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}
export default memo(Index);
