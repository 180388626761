import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Enter name"),

  position: yup.object().shape({
    latValue: yup.number().required("Please pin your location"),
    lngValue: yup.number().required("Please pin your location"),
  }),
  drawingMode: yup.string().required("Select drawing mode"),
  zoneType: yup.string().required("Enter zone type"),
  value: yup.mixed().nullable(false),
  priority: yup.number().required("Enter priority"),
  color: yup.mixed().nullable(false),
});

export default schema;
