export function degrees_to_radians(degrees) {
  let pi = Math.PI;
  return degrees * (pi / 180);
}
export function radians_to_degrees(radians) {
  let pi = Math.PI;
  return radians * (180 / pi);
}
export function getDistanceBetweenPointsNew(
  latitude1,
  longitude1,
  latitude2,
  longitude2
) {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((latitude2 - latitude1) * p) / 2 +
    (c(latitude1 * p) *
      c(latitude2 * p) *
      (1 - c((longitude2 - longitude1) * p))) /
      2;

  var distance = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  // distance = distance * 1.609344

  return parseFloat(distance.toFixed(2));
  // switch (unit) {
  //   case "Mi":
  //     break;
  //   case "Km":
  //     return (distance = distance * 1.609344);

  //   default:
  //     break;
  // }

  // return parseFloat(distance.toFixed(2));
}
export function metersToMiles(meters) {
  return parseFloat((meters * 0.000621371).toFixed(2));
}

//
// export function getDistanceBetweenPointsNew(
//   latitude1,
//   longitude1,
//   latitude2,
//   longitude2,
//   unit = "Mi"
// ) {
//   const theta = longitude1 - longitude2;
//   let distance =
//     Math.sin(degrees_to_radians(latitude1)) *
//       Math.sin(degrees_to_radians(latitude2)) +
//     Math.cos(degrees_to_radians(latitude1)) *
//       Math.cos(degrees_to_radians(latitude2)) *
//       Math.cos(degrees_to_radians(theta));

//   distance = Math.acos(distance);
//   distance = radians_to_degrees(distance);
//   distance = distance * 60 * 1.1515;

//   switch (unit) {
//     case "Mi":
//       break;
//     case "Km":
//       return (distance = distance * 1.609344);

//     default:
//       break;
//   }

//   return parseFloat(distance.toFixed(2));
// }

export function getRandomInRange(from, to, fixed) {
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
}