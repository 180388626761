import { Avatar, Badge, List } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { FaCommentDots } from "react-icons/fa";
import { useSelector } from "react-redux";

import { ADMIN_SITE } from "../../../config";

function Index({ item, chatType }) {
  const { user } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.admin);
  const senderUser = useMemo(() => {
    let fetchUser = item?.users?.find((e) => e.id !== user.id);
    return fetchUser;
  }, [
    item, user
  ]);
  const reciverUser = useMemo(() => {
    let fetchUser = item?.users?.find((e) => e.id === item.startBy);
    return fetchUser;
  }, [item]);
  const unReadMsgs = useMemo(() => {
    let msgs = item?.chats?.filter(
      (e) => e.senderId !== user.id && e.status !== "SEEN"
    );
    return msgs || [];
  }, [
    item, user
  ]);

  return (
    <List.Item
      key={item.id}
      className="d-flex flex-col  shadow-md rounded-lg bg-white  my-2 "
    >
      <div className="w-full flex justify-between text-white bg-green-600 p-2 rounded-t-lg ">
        <p className="m-0 text-sm">
          Platform :
          {" "}
          <span className=" capitalize font-medium ">
            {item.platform}
          </span>
        </p>
        <p className="m-0 text-sm">
          Created At :
          {" "}
          <span className=" capitalize font-medium ">
            {moment(item?.createdAt).format("DD MMM YYYY/ HH:mm")}
          </span>
        </p>
      </div>
      <div className="w-full flex p-2    items-center">
        <List.Item.Meta
          avatar={<Avatar src={senderUser?.image} />}
          title={<span>
            {senderUser?.name}
            {" "}
(
            {senderUser.role}
)
          </span>}
          description={senderUser.email}
        />
        <a
          href="/#"
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            const win = window.open(
              `${ADMIN_SITE}/chat/${item.id}?sessionType=${chatType}`,
              "mywindow",
              "menubar=1,resizable=1,width=450,height=550"
            );
            localStorage.setItem("liveSession", item.id);
            var timer = setInterval(() =>{
              if (win.closed) {
                const active = localStorage.getItem("liveSession");
                if (active) {
                  localStorage.removeItem("liveSession");
                  clearInterval(timer);

                  return;
                }
                clearInterval(timer);
              }
            }, 1000);
          }}
          className=" w-20  text-left"
        >
          <Badge count={unReadMsgs?.length}
            size="small"
            color="#282932">
            <FaCommentDots className="text-lg text-yellow-500 " />
          </Badge>
        </a>
        {reciverUser && (
          <List.Item.Meta
            className="flex flex-wrap"
            avatar={<Avatar src={reciverUser?.image} />}
            title={<span>
              {reciverUser?.name}
              {" "}
(
              {roles?.find((e) => e.id === reciverUser.role)?.name}
)
            </span>}
            description={reciverUser.email}
          />
        )}
      </div>
    </List.Item>
  );
}

export default Index;
