import { Button, TextField } from "@mui/material";
import { Checkbox, Modal, Spin } from "antd";
import { Formik } from "formik";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import adminNavigation from "../../navigation/adminData";
import dashboardNavigation from "../../navigation/navigationData";
import riderNavigation from "../../navigation/riderData";
import userNavigation from "../../navigation/userData";
import { addAdminRole, updateAdminRole } from "../../redux/admin/action";
import addRoleValidation from "../../validations/addRole";

const TabsOptions = dashboardNavigation.map((e) => {
  return {
    label: e.text,
    value: e.name,
  };
});

const customerTabs = userNavigation.map((e) => {
  return {
    label: e.text,
    value: e.name,
  };
});

const riderTabs = riderNavigation.map((e) => {
  return {
    label: e.text,
    value: e.name,
  };
});

const adminTabs = adminNavigation.map((e) => {
  return {
    label: e.text,
    value: e.name,
  };
});
function Index({ show, onHide, role }) {
  const dispatch = useDispatch();

  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
    onHide?.();
  };
  const addRole = (e) => {
    setLoader(true);
    if (role) {
      dispatch(updateAdminRole(e, role.id, stopLoader));
      return;
    }
    dispatch(addAdminRole(e, stopLoader));
  };
  const formikRef = useRef();
  useEffect(() => {
    if (role) {
      formikRef.current.setFieldValue("specificRider", role?.specificRider);
      formikRef.current.setFieldValue(
        "specificCustomer",
        role?.specificCustomer
      );
      formikRef.current.setFieldValue("specificAdmin", role?.specificAdmin);
    } else {
      if (!formikRef?.current?.initialValues.specificMain) {
        formikRef.current.setFieldValue("specificRider", true);
        formikRef.current.setFieldValue("specificCustomer", true);
        formikRef.current.setFieldValue("specificAdmin", true);
      }

      formikRef.current.setFieldValue("specificMain", false);
    }
  }, [role]);
  return (
    <Modal
      title={role ? "Update Role" : "Add Role"}
      centered
      visible={show}
      footer={null}
      onCancel={onHide}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={addRoleValidation}
        onSubmit={addRole}
        initialValues={ {
          name: role?.name || "",
          specificMain: role?.specificMain,
          chatSupport: role?.chatSupport || false,
          accessTo: role?.accessTo || [],
          specificRider: role?.specificRider,

          specificCustomer: role?.specificCustomer,
          specificAdmin: role?.specificAdmin,
          riderTabs: role?.riderTabs || [],

          customerTabs: role?.customerTabs || [],
          adminTabs: role?.adminTabs || [],
        } }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Spin spinning={loader}>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col  items-center"
            >
              <div className=" flex justify-center items-center">
                <span className="fw-bold">
Name
                </span>
                <TextField
                  error={errors.name}
                  required
                  id="filled-required"
                  variant="standard"
                  className="m-3 w-96"
                  placeholder="Enter name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="mt-2 w-full">
                <Checkbox
                  checked={values.chatSupport}
                  name="chatSupport"
                  onChange={(a) => {
                    const { checked } = a.target;

                    setFieldValue("chatSupport", checked);
                  }}
                >
                  Chat Support
                </Checkbox>
              </div>
              <div className="mt-2 w-full">
                <Checkbox
                  checked={values.specificMain}
                  name="specificMain"
                  onChange={(a) => {
                    const { checked } = a.target;
                    setFieldValue("specificMain", checked);
                    setFieldValue("specificRider", checked ? false : true);

                    setFieldValue("specificCustomer", checked ? false : true);
                    setFieldValue("specificAdmin", checked ? false : true);
                  }}
                >
                  Specific main tabs
                </Checkbox>
              </div>
              {values.specificMain && (
                <div className=" flex justify-center items-center">
                  <span className="fw-bold">
Tabs:
                  </span>
                  <div className=" flex flex-col items-center ">
                    <Select
                      isMulti
                      onChange={(e) => {
                        let roles = e.map((e) => e.value);

                        setFieldValue("accessTo", roles);
                      }}
                      name="colors"
                      value={values.accessTo.map((e) => {
                        return {
                          label: TabsOptions.find((a) => a.value === e)?.label,
                          value: e,
                        };
                      })}
                      options={TabsOptions}
                      className="basic-multi-select w-96 m-3 border-0"
                      classNamePrefix="select"
                    />
                    {errors.accessTo && touched.accessTo && (
                      <span
                        className="w-60 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.accessTo}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {(values.accessTo.includes("riders") || !values.specificMain) && (
                <div className="mt-2 w-full">
                  <Checkbox
                    checked={values.specificRider}
                    name="specificRider"
                    onChange={(a) => {
                      const { checked } = a.target;

                      setFieldValue("specificRider", checked);
                    }}
                  >
                    Specific driver tabs
                  </Checkbox>
                </div>
              )}
              {values.specificRider && (
                <div className=" flex justify-center items-center">
                  <span className="fw-bold">
Driver tabs
                  </span>
                  <div className=" flex flex-col items-center ">
                    <Select
                      isMulti
                      onChange={(e) => {
                        let roles = e.map((e) => e.value);

                        setFieldValue("riderTabs", roles);
                      }}
                      name="colors"
                      value={values.riderTabs.map((e) => {
                        return {
                          label: riderTabs.find((a) => a.value === e)?.label,
                          value: e,
                        };
                      })}
                      options={riderTabs}
                      className="basic-multi-select w-96 m-3 border-0"
                      classNamePrefix="select"
                    />
                    {errors.riderTabs && touched.riderTabs && (
                      <span
                        className="w-60 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.riderTabs}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {(values.accessTo.includes("clients") ||
                !values.specificMain) && (
                <div className="mt-2 w-full">
                  <Checkbox
                    checked={values.specificCustomer}
                    name="specificCustomer"
                    onChange={(a) => {
                      const { checked } = a.target;

                      setFieldValue("specificCustomer", checked);
                    }}
                  >
                    Specific driver tabs
                  </Checkbox>
                </div>
              )}
              {values.specificCustomer && (
                <div className=" flex justify-center items-center">
                  <span className="fw-bold">
Rider tabs
                  </span>
                  <div className=" flex flex-col items-center ">
                    <Select
                      isMulti
                      onChange={(e) => {
                        let roles = e.map((e) => e.value);

                        setFieldValue("customerTabs", roles);
                      }}
                      name="colors"
                      value={values.customerTabs.map((e) => {
                        return {
                          label: customerTabs.find((a) => a.value === e)?.label,
                          value: e,
                        };
                      })}
                      options={customerTabs}
                      className="basic-multi-select w-96 m-3 border-0"
                      classNamePrefix="select"
                    />
                    {errors.customerTabs && touched.customerTabs && (
                      <span
                        className="w-60 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.customerTabs}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {(values.accessTo.includes("admins") || !values.specificMain) && (
                <div className="mt-2 w-full">
                  <Checkbox
                    checked={values.specificAdmin}
                    name="specificAdmin"
                    onChange={(a) => {
                      const { checked } = a.target;

                      setFieldValue("specificAdmin", checked);
                    }}
                  >
                    Specific Admin Tabs
                  </Checkbox>
                </div>
              )}
              {values.specificAdmin && (
                <div className=" flex justify-center items-center">
                  <span className="fw-bold">
Admin Tabs
                  </span>
                  <div className=" flex flex-col items-center ">
                    <Select
                      isMulti
                      onChange={(e) => {
                        let roles = e.map((e) => e.value);

                        setFieldValue("adminTabs", roles);
                      }}
                      name="colors"
                      value={values.adminTabs.map((e) => {
                        return {
                          label: adminTabs.find((a) => a.value === e)?.label,
                          value: e,
                        };
                      })}
                      options={adminTabs}
                      className="basic-multi-select w-96 m-3 border-0"
                      classNamePrefix="select"
                    />
                    {errors.adminTabs && touched.adminTabs && (
                      <span
                        className="w-60 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.adminTabs}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <Button
                disabled={loader}
                type="submit"
                size="small"
                className=" fw-bold text-white"
                variant="contained"
              >
                {role ? "Update" : "Add"}
              </Button>
            </form>
          </Spin>
        )}
      </Formik>
    </Modal>
  );
}
export default memo(Index);
