import { DatePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { deletePricings } from "../../../redux/admin/action";

const { RangePicker } = DatePicker;

function Index({ item, onChange, onDelete, shifts }) {
  const { row, columns } = item;

  const dispatch = useDispatch();
  const [
    initialRow, setInitialRow
  ] = useState(null);
  const onDeleteHandler = () => {
    dispatch(
      deletePricings(row.id, () => {
        onDelete(row.id);
      })
    );
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInitial = {
      ...initialRow,
      [name]: value,
    };

    setInitialRow(newInitial);
    onChange?.(newInitial);
  };
  useEffect(() => {
    if (row) {
      setInitialRow(row);
    }
  }, [row]);

  return (
    <tr>
      {columns?.map((e) => {
        if (e?.key === "message") {
          return (
            <td key={e?.key}>
              <Form.Control
                onChange={onChangeHandler}
                value={initialRow?.[e.key]}
                as="textarea"
                name={e.key}
                placeholder="Christmas..."
                rows={1}
              />
            </td>
          );
        }
        if (e.key === "action") {
          return (
            <td key={e?.key}>
              <select
                onChange={onChangeHandler}
                name={e.key}
                value={initialRow?.[e.key]}
              >
                <option value="multiply">
Multiply
                </option>
                <option value="add">
Add
                </option>
              </select>
            </td>
          );
        }
        if (e.key === "factorValue") {
          return (
            <td key={e?.key}>
              <Form.Control
                name={e.key}
                onChange={onChangeHandler}
                value={initialRow?.[e.key]}
                type="number"
                placeholder="Factor value"
              />
            </td>
          );
        }
        if (e.key === "fromTo") {
          return (
            <td key={e?.key}>
              <RangePicker
                format="DD MMM YYYY"
                value={[
                  moment(initialRow?.fromTo[0], "DD MMM YYYY"),
                  moment(initialRow?.fromTo[1], "DD MMM YYYY"),
                ]}
                onChange={(date) => {
                  let newInitial = {
                    ...initialRow,
                    fromTo: [
                      moment(date[0]).format("DD MMM YYYY"),
                      moment(date[1]).format("DD MMM YYYY"),
                    ],
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
                className="w-full"
              />
            </td>
          );
        }
        if (e.key === "shift") {
          return (
            <td key={e?.key}>
              <Form.Select
                name="shift"
                className="border"
                type="text"
                value={initialRow?.shift}
                onChange={(c) => {
                  const { name, value } = c.target;
                  let newInitial = {
                    ...initialRow,
                    [name]: value,
                  };

                  setInitialRow(newInitial);
                  onChange(newInitial);
                }}
              >
                <option value="">
Choose shift
                </option>
                {shifts?.map((shift) => {
                  return <option key={shift.id}
                    value={shift.id}>
                    {shift?.name}
                  </option>;
                })}
              </Form.Select>
            </td>
          );
        }
        return (
          <td key={e?.key}>
            <button type="button"
              onClick={onDeleteHandler}>
              <FaTrashAlt />
            </button>
          </td>
        );
      })}
    </tr>
  );
}

export default memo(Index);
