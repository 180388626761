import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <p>
        Driver licence(back):
        {" "}
        <span>
          <a target="_blank"
            rel="noreferrer"
            href={info?.DriverLicenceback}>
            view
          </a>
        </span>
      </p>
      <p>
        Driver licence(front):
        {" "}
        <span>
          <a target="_blank"
            rel="noreferrer"
            href={info?.DriverLicencefront}>
            view
          </a>
        </span>
      </p>
      <p>
        Type of licence: 
        {" "}
        <span>
          {info?.typeoflisence}
        </span>
      </p>
    </div>
  );
}

export default memo(Index);
