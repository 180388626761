import { DeleteOutlined } from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import { FaArrowDown, FaArrowUp, FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";

import AdminOtherDocuments from "../../../modals/adminOtherDocuments";
import PersonalInfoModal from "../../../modals/PersonalInfoModal";
import { deleteAdminDocument } from "../../../redux/admin/action";
import {
  Documents,
  EmergencyContact,
  LicenceInfo,
  Others,
  PersonalInfo,
} from "./sections";

const { Panel } = Collapse;

function Document(props) {
  const { type } = props;
  switch (type.id) {
  case "documents":
    return <Documents info={type}
      {...props} />;
  case "emergency":
    return <EmergencyContact info={type}
      {...props} />;

  case "licence":
    return <LicenceInfo info={type}
      {...props} />;

  case "personal":
    return <PersonalInfo info={type}
      {...props} />;
  default:
    return <Others info={type}
      {...props} />;
  }
}

function Modal(props) {
  const { type } = props;
  switch (type?.id) {
  // case "documents":
  //   return <RiderDocumentModal info={type} {...props} />;
  // case "emergency":
  //   return <EmergencyModal info={type} {...props} />;

  case "personal":
    return <PersonalInfoModal info={type}
      {...props} />;
  default:
    return <AdminOtherDocuments info={type}
      {...props} />;
  }
}

function Index({ info, onDelete, onUpdate }) {
  const dispatch = useDispatch();
  const deleteDocument = () => {
    if (window.confirm("Want to delete this document?")) {
      setLoader(true);
      dispatch(deleteAdminDocument(info.id, stopLoader));
    }
  };
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      onDelete?.(data);
    }
  };
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col lg={12}
      className="my-2">
      <Spin spinning={loader}>
        <Collapse
          expandIcon={(props) => {
            return props.isActive ? (
              <FaArrowUp style={ { color: "white" } } />
            ) : (
              <FaArrowDown style={ { color: "white" } } />
            );
          }}
          accordion
          expandIconPosition="right"
        >
          <Panel
            className="rider_mainColor rounded shadow"
            header={<h1 className="text-white text-base fw-bold capitalize">
              {info?.id}
            </h1>}
            key="1"
            extra={<div className=" z-50 h-8 w-10 justify-between flex items-center">
              {(info.id === "documents" ||
                  info.id === "emergency" ||
                  info.id === "personal") && (
                <FaEdit className="text-white"
                  onClick={handleShow} />
              )}
              <DeleteOutlined
                onClick={deleteDocument}
                className=" fw-bold"
                style={ { color: "white", fontWeight: 900 } }
              />
            </div>}
          >
            <Document type={info} />
          </Panel>
        </Collapse>
      </Spin>
      {show && (
        <Modal
          onUpdate={onUpdate}
          show={show}
          type={info}
          onHide={handleClose}
        />
      )}
    </Col>
  );
}

export default memo(Index);
