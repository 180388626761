import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Space } from "antd";
import DOMPurify from "dompurify";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";

import Note from "../../../components/Note";
import SnackBarCustom from "../../../components/SnackBarCustom";
import { addNoteForAdmin, getAdminNotes } from "../../../redux/admin/action";
import addRestaurantNotesValidation from "../../../validations/AddRestaurantNotes";

function Index() {
  const dispatch = useDispatch();
  const [
    added, setAdded
  ] = useState(false);
  const [
    adder, setAdder
  ] = useState(false);

  const addUserNotes = (values, { resetForm }) => {
    setAdder(true);
    const html = `<div>${draftToHtml(
      convertToRaw(values?.desc?.getCurrentContent())
    )}</div>`;
    values = {
      ...values,
      desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
    };
    dispatch(addNoteForAdmin(values, stopAdder(resetForm)));
  };
  const stopAdder = (resetForm) => (data) => {
    setAdder(false);
    setAdded(true);
    const newNotes = notes.map((e) => e);
    newNotes.push(data);
    setNotes(newNotes);
    resetForm?.();
  };
  const onClose = () => {
    setAdded(false);
    setDeleted(false);
  };
  const [
    notes, setNotes
  ] = useState([]);

  const [
    loader, setLoader
  ] = useState(false);

  const [
    deleted, setDeleted
  ] = useState(false);

  const stopLoader = (data) => {
    setLoader(false);
    setNotes(data);
  };
  useEffect(() => {
    setLoader(false);
    dispatch(getAdminNotes(stopLoader));
  }, [dispatch]);
  const deleteNote = (note) => {
    const newNotes = notes.filter((e) => e.id !== note.id);
    setNotes(newNotes);
    setDeleted(true);
  };
  const [
    filterNotes, setFilterNotes
  ] = useState([]);
  const searchtable = (e) => {
    if (e?.target?.value) {
      let filterbyorder = notes.filter((order) =>
        order?.title.includes(e.target.value)
      );
      setFilterNotes(filterbyorder);
    } else {
      setFilterNotes([]);
    }
  };
  const finalNotes = filterNotes.length > 0 ? filterNotes : notes;
  if (loader) {
    return <div>
loading...
    </div>;
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Notes
          </h1>
        </Col>
        <Col lg={12}
          className="shadow rounded-3 bg-white p-3">
          <Formik
            initialValues={ {
              title: "",
              ordernumber: "",
              desc: EditorState.createEmpty(),
            } }
            validationSchema={addRestaurantNotesValidation}
            onSubmit={addUserNotes}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form className="mx-auto"
                onSubmit={handleSubmit}>
                <div className="flex flex-wrap gap-2">
                  <Form.Group controlId="formBasictitle">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.title && touched.title && (
                      <span
                        className="w-100 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.title}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicnumber">
                    <Form.Control
                      type="text"
                      placeholder="Order number"
                      name="ordernumber"
                      value={values.ordernumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ordernumber && touched.ordernumber && (
                      <span
                        className="w-100 text-danger text-start"
                        style={ { fontSize: "12px" } }
                      >
                        {errors.ordernumber}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <Editor
                  editorState={values.desc}
                  name="desc"
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName mt-3 border"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setFieldValue("desc", e);
                  }}
                />
                {errors.desc && touched.desc && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.desc}
                  </span>
                )}
                <Button
                  disabled={adder}
                  variant="warning"
                  className="mt-3 fw-bolder text-white"
                  type="submit"
                >
                  {adder ? "Adding.." : "Add"}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
        <Col lg={12}
          className="flex justify-end mt-4">
          <Space direction="horizontal">
            <Input
              onChange={searchtable}
              placeholder="Title"
              prefix={<SearchOutlined />}
            />
          </Space>
        </Col>
        <Col className="shadow rounded-lg bg-white mt-2"
          lg={12}>
          <Row>
            <Col
              lg={12}
              className="d-flex justify-content-between rounded-t-lg align-items-center resrmang-heading p-3 border-bottom rider_mainColor"
            >
              <h1 className="text-white fw-bold">
Notes
              </h1>
            </Col>
            <Col className="p-3">
              {finalNotes.length > 0 ? (
                <Row>
                  {finalNotes
                    ?.sort((a, b) => b.createdAt - a.createdAt)
                    ?.map((note, index) => (
                      <Note
                        as="admin"
                        key={index}
                        onSuccess={deleteNote}
                        note={note}
                      />
                    ))}
                </Row>
              ) : (
                <Empty description={<p>
Notes not found
                </p>} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {added && (
        <SnackBarCustom msg={"Note added"}
          open={added}
          onClose={onClose} />
      )}
      {deleted && (
        <SnackBarCustom msg={"Note deleted"}
          open={deleted}
          onClose={onClose} />
      )}
    </Container>
  );
}

export default memo(Index);
