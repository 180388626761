import { Popconfirm , Spin } from "antd";
import React, { memo, useState } from "react";

function Index({ action, children, confirmMsg, onSuccess }) {
  const [
    loader, setLoader
  ] = useState(false);

  const deleteUser = () => {
    setLoader(true);
    action(stopLoader);
  };
  const stopLoader = (data) => {
    if (data) {
      onSuccess?.(data);
    }
    setLoader(false);
  };
  return (
    <Spin size="small"
      spinning={loader} style={{width: "fit-content"}}>
      <Popconfirm title={confirmMsg}
        onConfirm={deleteUser}>
        {children}
      </Popconfirm>
    </Spin>
  );
}
export default memo(Index);
